import React from 'react'
import Flatpickr from "react-flatpickr";
export default function FacturaListar() {


  return (
    <>
      <div class="page-content">

        <div class="container-fluid">

          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">Listado de Facturas</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="javascript: void(0);">Facturas</a></li>
                    <li class="breadcrumb-item active">Listado</li>
                  </ol>
                </div>

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">

              <div class="row">

                <div class="form-group col-md-2" >
                  <label for="inputEmail4">Clinicas</label>
                  <select class="form-select " id="fc_idclinica" name="fc_idclinica" >
                    <option value="0">Juan</option>
                    <option value="1">Betty</option>
                    <option value="2">David</option>
                  </select>
                </div>

                <div class="form-group col-md-3" >
                  <label for="inputEmail4">Fecha</label>
                  {/* <input id="fechas-facturas" name="fechas-facturas" style={{maxWidth:'100%'}} value="<?= date('Y-m-d') ?> to <?= date('Y-m-d') ?>"
                    class="form-control" type="text" /> */}
                     <Flatpickr style={{maxWidth:'100%'}} className='form-control' options={{ minDate: "2017-01-01" }} />
                </div>

                <div class="form-group col-md-4 " >
                  <label for="inputEmail4">Paciente</label>

                  <div class="row">
                    <div class="col-10">
                      <select class="form-select" id="fc-paciente" name="fc-paciente" onchange="showEmptyBoxPaciente()"></select>
                    </div>
                    <div class="col-2">
                      <button id="btn_emptyPaciente" type="button" onclick="emptyBoxPaciente()" style={{display:'none'}}
                        class="btn btn-soft-light waves-effect waves-light" ><i class="fas fa-trash"></i></button>
                    </div>
                  </div>
                </div>



                <div class="form-group col-md-3" >
                  <label for="inputEmail4">Opciones</label><br/>
                    <button type="button" class="btn btn-soft-success waves-effect waves-light btnSaveInfo" onclick="consultarFacturas()">
                      <i class="bx bx-loader bx-spin font-size-16 align-middle me-2 spin_loading" style={{display: 'none'}} ></i>
                      Consultar</button>
                    <a href="facturasCrear" class="btn btn-soft-secondary waves-effect btn-label waves-light">
                      <i class="bx bx-plus label-icon"></i> Factura</a>
                </div>

              </div>
            </div>
          </div>


          <div class="mt-4 mb-4">
            <div class="card">
              <div class="card-body">
                <table id="tb_facturas_lts" class="table align-middle datatable dt-responsive table-check nowrap table-hover"
                  style={{borderCollapse: 'collapse', borderSpacing: '0 8px', width: '100%', fontSize:'14px'}}>
                  <thead>
                    <tr class="table-light">
                      <th>#</th>
                      <th>Fecha</th>
                      <th>Clinica</th>
                      <th>Paciente</th>
                      <th>Cliente</th>
                      <th class='text-center'>Doc</th>
                      <th class='text-center'>Num. Doc</th>
                      <th class='text-center'>Subtotal</th>
                      <th class='text-center'>Impuesto</th>
                      <th class='text-center'>Total</th>
                      <th class='text-center'>Estado</th>
                      <th class="text-center"></th>
                    </tr>
                  </thead>

                  <tbody>
                  </tbody>

                </table>
              </div>
            </div>
          </div>

        </div>


        <div class="modal fade" id="modalFacturaDetalle" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalScrollableTitle">Detalle Factura</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">

                @include('facturas.factura-detalle')

              </div>
              <div class="modal-footer">
                <a href="javascript:window.print()" class="btn btn-success waves-effect waves-light me-1"><i class="fa fa-print"></i></a>
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>



      </div >


    </>
  )
}
