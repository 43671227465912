import React from 'react'

export default function Odontograma() {
  return (
    <>
    
    <div class="" id="tabodontograma">
        <div class="panel br-mn">
            <div class="panel-body" style={{backgroundColor: "#fff"}}>
                <h4 class="semibold upper mvs" style={{textAlign: "center"}}>
                
                    <div class="form-inline well-sm" role="form" style={{display: "inline-block"}}>
                        
                        <div class="form-group">

                        </div>
                    </div>

                </h4>

                    <div class="collapse show" id="collapseExample">

                        <div class="card border shadow-none card-body text-muted mb-0">

                            <div class="row mb-4">
                                <div class="col-xl-9">
                                    <h6 class="card-title">INGRESO DE TRATAMIENTOS</h6>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xl-4 col-lg-8">
            
                                    <div class="form-group col-md-6 mt-3">
                                        <label for="inputEmail4">Clinicas</label>
                                        <select class="form-select " id="p_idclinica_odont" name="p_doctor_odont" >
                                        </select>
                                    </div>
            
                                    <div class="form-group col-md-5 mt-3" >
                                        <label for="inputEmail4">Fecha</label>
                                        <input id="p_fecha_ingreso" name="p_fecha_ingreso" style={{maxWidth:"100%"}} value="<?= date('Y-m-d') ?>"
                                            class="form-control" type="text" />
                                    </div>
                                    
                                    <div class="form-group col-md-11 mt-3">
                                        <label for="inputEmail4">Medico</label>
                                        <select class="form-select" id="p_doctor_ingreso">
                                            <option value="">Seleccione un Medico</option>
                                        </select>
                                    </div>
                                    
                                    <div class="form-group col-md-11 mt-3">
                                        <label for="inputEmail4">Procedimientos</label>
                                        {/* <!--<input id="tratamientos_bqd" style="max-width:100%; " class="form-control" type="text" onchange="getInfoProc(this);" onkeydown="searchTratamientos()">--> */}
                                        <select class="form-control" id="p_tratamientos_busq" name="p_tratamientos_busq"  onchange="getInfoProc(this);" style={{width: "100%", height:"50px"}}>
            
                                        </select>
                                    </div>
                                    
                                                                
                                    <div class="form-group col-md-11 mt-2">
                                        <label for="inputPassword4">Piezas</label>
                                        <select  class="form-control" name="piezas_select" id="piezas_select"  multiple>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                
                                            <option value="31">31</option>
                                            <option value="32">32</option>
                                            <option value="33">33</option>
                                            <option value="34">34</option>
                                            <option value="35">35</option>
                                            <option value="36">36</option>
                                            <option value="37">37</option>
                                            <option value="38">38</option>
                
                                            <option value="41">41</option>
                                            <option value="42">42</option>
                                            <option value="43">43</option>
                                            <option value="44">44</option>
                                            <option value="45">45</option>
                                            <option value="46">46</option>
                                            <option value="47">47</option>
                                            <option value="48">48</option>
                
                                            <option value="51">51</option>
                                            <option value="52">52</option>
                                            <option value="53">53</option>
                                            <option value="54">54</option>
                                            <option value="55">55</option>
                
                                            <option value="61">61</option>
                                            <option value="62">62</option>
                                            <option value="63">63</option>
                                            <option value="64">64</option>
                                            <option value="65">65</option>
                
                                            <option value="71">71</option>
                                            <option value="72">72</option>
                                            <option value="73">73</option>
                                            <option value="74">74</option>
                                            <option value="75">75</option>
                
                                            <option value="81">81</option>
                                            <option value="82">82</option>
                                            <option value="83">83</option>
                                            <option value="84">84</option>
                                            <option value="85">85</option>
                
                                        </select>
                                    </div>
                
                
                                    <div class="form-group col-md-11 mt-2">
                                        <label for="inputPassword4">Caras</label>
                                        <select id="caras_select" name="caras_select" class="form-control" multiple>
                                            <optgroup label="Caras">
                                                <option value="Palatino">Palatino</option>
                                                <option value="Distal">Distal</option>
                                                <option value="Oclusal">Oclusal</option>
                                                <option value="Lingual">Lingual</option>
                                                <option value="Mesial">Mesial</option>
                                                <option value="Vestibular">Vestibular</option>
                                            </optgroup>
                
                                            <optgroup label="Caras">
                                                <option value="Abrasiones">Abrasiones</option>
                                                <option value="Vestibular">Vestibular</option>
                                                <option value="Incisal">Incisal</option>
                                                <option value="Bucal">Bucal</option>
                                                <option value="Oclusal">Oclusal</option>
                                            </optgroup>
                                        </select>
                                    </div>
                                    

                                    <div class="form-group col-md-5 mt-3">
                                        <label for="inputPassword4">Estado</label>
                                        <select class="form-control" id="p_estado" name="p_estado">                                            
                                            <option value="Pendiente">Por Realizar</option>
                                            <option value="Previo">Previo</option>
                                        </select>
                                    </div>
            
                                    <div class="form-group col-md-6 mt-3">
                                        <label for="inputPassword4">Valor</label>
                                        <input type="text" class="form-control " id="valorProc" placeholder="00.00"/>
                                    </div>
            

                                    <div class="mt-3" style={{textAlign: "center"}}>
                                        <button style={{width: "100px", maxWidth:"250px" }}
                                                class="form-control btn btn-success mt-3 waves-effect waves-light w-100 btnSaveInfo"                                             
                                                onclick="insertProcedimientoOdont()">
                                                <i class="bx bx-loader bx-spin font-size-16 align-middle me-2 btn_loading" style={{display: "nonde"}}></i>
                                                AGREGAR</button>
                                    </div>
                                
                                    
                                    
                                </div>
                                <div class="col-xl-8 col-lg-8">
                                    
                                    <div class="row">
                                        <div class="form-check form-switch form-switch-md mb-1" dir="ltr">
                                            <input type="radio" class="form-check-input" name="odontogramas" id="adulto_odo" value="Adulto" checked/>
                                            <label class="form-check-label" for="customSwitchsizemd">Adulto</label>                            
                                        </div>
                
                                        <div class="form-check form-switch form-switch-md mb-1" dir="ltr">
                                            <input type="radio" class="form-check-input" name="odontogramas" id="infantil_odo" value="Infantil"/>
                                            <label class="form-check-label" for="customSwitchsizemd">Infantil</label>
                                        </div>
                                    </div>
            
                                    <div class="row" id="renderodontograma" style={{overflowX: "hidden"}}>
                                        <div class="row" style={{backgroundColor: "#fff"}}>
                                            <div id="tr" class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{textAlign: "right", paddingRight: "90px"}}>
                                            </div>
                                            <div id="tl" class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{textAlign: "left", paddingLeft: "0px"}}>
                                            </div>
                                            <div id="tlr" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 " style={{textAlign: "right", paddingRight: "0px"}}>
                                            </div>
                                            <div id="tll" class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{textAlign: "left", paddingLeft: "0px"}}>
                                            </div>
                                        </div>
                                        <div class="row" style={{backgroundColor: "#fff"}}>
                                            <div id="blr" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 " style={{textAlign: "right", paddingRight: "0px"}}>
                                            </div>
                                            <div id="bll" class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{textAlign: "left", paddingLeft: "0px"}}>
                                            </div>
                                            <div id="br" class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{textAlign: "right", paddingRight: "90px"}}>
                                            </div>
                                            <div id="bl" class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{textAlign: "left", paddingLeft: "0px"}}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        
                        </div>
                    </div>
                    
                
            


            </div>
        </div>
        </div>

        <div class="mt-3">


        <div class="card">
            
            <div class="card-body">

                <div class="row mb-4">
                    <div class="col-xl-9">
                        <h6 class="card-title">DESCRIPCION DE TRATAMIENTOS</h6>            
                    </div>
                    <div class="col-xl-3">
                    <select class="form-select" id="cb_opciones_tratamientos" style={{width: "50%", display: "initial"}} 
                                onchange="listTratamientosEstado()">
                        <option value="">Todos</option>
                        <option value="Pendiente">Pendientes</option>
                        <option value="Previo">Previo</option>
                        <option value="Realizado">Realizados</option>
                    </select>
                    <button class="btn btn-primary waves-effect waves-light btnSaveInfo" onclick="listTratamientosEstado()">
                        <i class="bx bx-loader bx-spin font-size-16 align-middle me-2 btn_loading" style={{display: "none"}}></i>
                        Consultar</button>
                    </div>
                </div>

                <table id="tb_procedimientos_pendientes" style={{fontSize:"14px"}} class="table">

                    <thead class="upper br-sm table-light">
                        <tr class="f12 upper bg-grey-l">

                            <th scope="col">#</th>
                            <th scope="col">Clinica</th>
                            <th scope="col">Tratamiento</th>
                            <th scope="col">Pieza</th>
                            <th scope="col" style={{maxWidth: "42px", width: "60px"}}>Zona</th>
                            <th scope="col"><div align='center'>Precio</div></th>
                            
                            <th scope="col">Desc</th>
                            <th scope="col">Total</th>
                            
                            <th scope="col">Doctor</th>
                            <th scope="col">Estado / Fecha</th>
                            <th scope="col">Estado Cobro</th>
                                    
                            <th scope="col"><a href="#;" onclick="selectAll('deleteTrata')"><i class="fi-trash" style={{fontSize:"20px"}}></i></a>
                                <input type="hidden" id="vl_deleteTrata" value="0"/>
                            </th>
                        </tr>
                    </thead>

                    <tbody class="bg-white" id="bodyanamnesis">

                    </tbody>
                </table>
            </div>
        </div>

    </div>
    </>
  )
}
