import React, { useEffect, useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Swal from "sweetalert2";
import { ControllerEliminarDoctor, ListarDoctores } from "../../Service/Service.doctor";
import DoctorCreate from "./DoctorCreate";

export default function Doctor({tabs}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ping, setPing] = useState(false);
  const [doctores, setDoctores] = useState([]);
  const [editDoctor, setEditDoctor] = useState(null);

  const EditarDoctor = async (items) => {
    setEditDoctor(items);
    handleShow();
  }

  const OpenCreateDoctor = () => {
    // Limpiar los campos
    setEditDoctor(null);
    handleShow();
  }

  const eliminarDoctor = (id) => {
    Swal.fire({
      title: '¿Estas seguro?',
      text: "No podras revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await ControllerEliminarDoctor(id);
        if (response) {
          setPing(!ping);
          Swal.fire(
            'Eliminado!',
            'El registro ha sido eliminado.',
            'success'
          )
        }
      }
    })
    
  }

  useEffect(() => {
    (async () => {
      const doct = await ListarDoctores();
      if (doct) {
        setDoctores(doct.data);
      }
    })();
  }, [ping, tabs.doctores]);

  return (
    <section>
      <div class="row">
        <div class="col-12">

          <div class="page-title-box d-sm-flex align-items-center justify-content-between ">
              <button type="button" class="btn btn-light waves-effect waves-light" onClick={OpenCreateDoctor}>
              <i class="bx bx-plus me-1"></i> Crear Doctor</button>
          </div>


          <table id="tb_doctores" class="table dt-responsive table-striped nowrap w-100" style={{ fontSize: '14px' }}>
            <thead>
              <tr>
                <th width="15">#</th>
                <th width="200">Nombre</th>
                <th>Titulo</th>
                <th>Email</th>
                <th>Telefono</th>
                <th>Genero</th>
                <th width="50">Opciones</th>
              </tr>
            </thead>
            <tbody>
              {
                doctores.map((doctors, index) => (
                    <tr>
                      <td align="center">3</td>
                      <td>{doctors.nombre} <div class="text-muted">{doctors.especialidades.especialidad}</div>
                      </td>
                      <td>{doctors.titulo}</td>
                      <td>{doctors.email}</td>
                      <td>{doctors.celular}</td>
                      <td>{doctors.genero}</td>
                      <td align="center">
                        <div class="btn-group"><button type="button"
                          class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                          aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                          <div class="dropdown-menu dropdownmenu-secondary">
                            <a class="dropdown-item" href="#;" onClick={()=>EditarDoctor(doctors)}>Editar</a>
                            <a class="dropdown-item" href="#;" onClick={()=>eliminarDoctor(doctors.iddoctor)}>Eliminar</a>
                            {/* <div class="dropdown-divider"></div> */}
                          </div>
                        </div>
                      </td>
                    </tr>
                ))
              }
            </tbody>
          </table>

        </div>
      </div>


      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Doctor
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DoctorCreate 
            handleClose={handleClose}
            setPing={setPing}
            ping={ping}
            editDoctor={editDoctor}
          />
        </Offcanvas.Body>
      </Offcanvas>


    </section>
  );
}
