import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Flatpickr from "react-flatpickr";
import DoctorScheduleTable from '../Components/Configuracion/DoctorSchaduleTable/DoctorScheduleTable';
import PacienteCreacion from '../Components/Configuracion/ModalCreacion/PacienteCreacion';
import SelectSearchble from '../Components/SelectSeachble/SelectSearchble';
import { createCitaPaciente } from '../Service/Service.citas';
import { ControllerListarCitasDoctor } from '../Service/Service.doctor';
import { ListarDoctoresEspecialidadClinica, ListarEspecialidadClinicaActivas } from '../Service/Service.especialidad';
import { ListarPacientes } from '../Service/Service.paciente';
import { ListarEstadoCitas, ListarTipoCitas } from '../Service/Service.util';
import { GetTokenDecoded } from '../utils/storeUsuario';
moment.locale('es');

export default function GestionCitas () {
    const [show, setShow] = useState(false);
    const [showPaciente, setShowPaciente] = useState(false);
    const [idclinica, setIdClinica] = useState(GetTokenDecoded().idclinica);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const handleClosePaciente = async() => {
      setShowPaciente(false)
      const pacint = await ListarPacientes(idclinica)
      if(pacint.success){
          setPaciente(pacint.data.map(p => ({value: p.idpaciente, label: p.nombres_completos, ...p})) )
      }
    }
    const handleShowPaciente = () => setShowPaciente(true);

    const [estadosCitas, setEstadosCitas] = useState([]);
    const [tipoCitas, setTipoCitas] = useState([]);


    const [especialidadesActivas, setEspecialidadesActivas] = useState([]);
    const [doctoresEspecialidades, setDoctoresEspecialidades] = useState([]);
    const [citasDoctotes, setCitasDoctotes] = useState([]); // [iddoctor, fecha, citas]
    const [iddoct, setIdDoctor] = useState([]);
    const [ctactivas, setCtActivas] = useState({
      idespecialidad: 0,
      iddoctor: [],
      idclinica: 0,
    });
    
    const [fecha, setFecha] = useState(moment().format('YYYY-MM-DD'));
    const [doctor, setDoctor] = useState({
        iddoctor: 0,
        nombre: "",
        idespecialidad: 0,
        idclinica: 0,
        horario: "",
        horario_fin: "",
        horario_inicio: "",
        activo: null,
        vacaciones: null,
        foto: "",
        especialidades: {
          idespecialidad: 0,
          codigo: "",
          especialidad: "",
          clinica_especialidades: [
            {
              intervalo_tiempo: 0
            }
          ]
        },
        doctors_dias_laborables: [
          {
            lunes: null,
            martes: null,
            miercoles: null,
            jueves: null,
            viernes: null,
            sabado: null,
            domingo: null
          }
        ],
    });

    const [hora, setHora] = useState({
        hora_inicio: "",
        hora_fin: ""
    });

    const [paciente, setPaciente] = useState([]);


    const [citapaciente, setCitaPaciente] = useState({
      motivo: "",
      idtipo_cita: 0,
      fecha_inicio: "",
      hora_inicio: "",
      hora_fin: "",
      idpaciente: 0,
      iddoctor: 0,
      id_estado_cita: 0,
      idclinica: idclinica,
    })

    const ListarDoctoresEspecialidad = async (idespecialidad, idclinica, fecha) => {
      try {
        const response = await ListarDoctoresEspecialidadClinica(idespecialidad, idclinica)
        window.history.pushState({}, null, `?idespecialidad=${idespecialidad}&idclinica=${idclinica}`)
        if(response.success){
          let iddoct = response.data.map(d => d.iddoctor)
          setIdDoctor(iddoct)
          const citasActuales = await ControllerListarCitasDoctor(iddoct,fecha)
          if(citasActuales.success){
            setCitasDoctotes(citasActuales.data)
            setDoctoresEspecialidades(response.data)
          }
        }else{
          setDoctoresEspecialidades([])
        }
      } catch (error) {
        console.error(error);
      }
    }

    const ReeListarConsulta = async () => {
        const citasActuales = await ControllerListarCitasDoctor(iddoct,fecha)
        if(citasActuales.success){
          if(citasActuales.data.length > 0){
            setCitasDoctotes(citasActuales.data)
          }
        }
    }

    const handleChangefecha = async (date) => {
      setFecha(moment(date[0]).format('YYYY-MM-DD'))
      // tomar los parametros de la url y hacer la consulta
      await ReeListarConsulta()
    }

    const handleChange = selectedOption => {
      setSelectedOption(selectedOption);
      setCitaPaciente({...citapaciente, idpaciente: selectedOption.value})
    };

    const GuardarCita = async () => {
      try {
        console.log(citapaciente);
        const response = await createCitaPaciente(citapaciente)
        console.log(response);
        if(response.success){
          alert('Cita creada correctamente')
          handleClose()
        }else{
          alert('Error al crear la cita')
        }
      } catch (error) {
        console.error(error);
      }
    
    }

    useEffect(() => {
        (async () => {
          setIdClinica(GetTokenDecoded().idclinica)
          // console.log(GetTokenDecoded())
            const espActiv = await ListarEspecialidadClinicaActivas(GetTokenDecoded().idclinica)
            if(espActiv.success){
              setEspecialidadesActivas(espActiv.data)
            }
            const pacint = await ListarPacientes(GetTokenDecoded().idclinica)
            if(pacint.success){
              setPaciente(pacint.data.map(p => ({value: p.idpaciente, label: p.nombres_completos, ...p})) )
            }
            const estd = await ListarEstadoCitas()
            if(estd.success){
              setEstadosCitas(estd.data)
            }
            const tip = await ListarTipoCitas()
            if(tip.success){
              setTipoCitas(tip.data)
            }

           
        })()
    }, [])

    const IconoRandom = () => {
      const iconos = ['fas fa-tooth', 'fas fa-stethoscope', 'fas fa-heart', 'fas fa-head-side-cough', 'fas fa-head-side-mask', 'fas fa-head-side-virus', 'fas fa-head-side-medical', 'fas fa-head-side-virus-slash', 'fas fa-head-side-cough-slash', 'fas fa-head-side-mask-slash', 'fas fa-head-side-medical']
      return iconos[Math.floor(Math.random() * iconos.length)]
    }
  
    return (
        <>
          <div className="page-content">
    
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Citas</h4>
    
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item"><a href="#;">Apps</a></li>
                      <li className="breadcrumb-item active">Gestion/Citas</li>
                    </ol>
                  </div>
    
                </div>
              </div>

              {
                especialidadesActivas.map((especialidad, index) => (
                    <div className="col-lg-2 col-md-2 col-sm-6 col-12 mb-1 cursor-pointer"
                        key={index}
                        style={{
                            cursor: 'pointer',
                            alignContent: 'center',
                            textAlign: 'center',
                            height: '100px',
                            maxHeight: '100px',
                        }}
                        onClick={() => ListarDoctoresEspecialidad(especialidad.idespecialidad, especialidad.idclinica, fecha)}
                    >
                        <div className="card cardHover"
                            style={{
                                borderRadius: '10px',
                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                                height: '100%',
                                maxHeight: '100%',
                                // transition: 'box-shadow 0.9s ease-in-out',
                            }}
                        >
                            <div className="card-body"
                              style={{
                                padding: '15px',
                              }}
                            >
                                <div className="row">
                                <div className="col-12">
                                    <h5 className={`font-size-14 mb-${especialidad.icono==null ? "3": "1"}`}>{especialidad.especialidades.especialidad}</h5>
                                    <div className="apex-charts" id="column-chart">
                                        <div className="text-center">
                                          {
                                            especialidad.icono === "" ? <i className={IconoRandom()}></i> : 
                                            <img src={especialidad.icono} alt="icono" className="img-fluid text-primary display-4" style={{ width: '40px' }} />
                                          }
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
              }
            </div>
    
            <div className="row">
              <div className="col-12">
    
                <div className="row">
                  <div className="col-xl-3 col-lg-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="">
    
                          <div className="row">
                            <div className="col-3">
                              <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                  <Modal.Title>Crear Nueva Cita
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <form className="needs-validation" name="event-form" id="form-event" novalidate="">
                                    <div className="row">
                                      <div className="col-12" id="dv_search_paciente">
                                        <div className="row mb-3">
                                            <label for="horizontal-email-input" className="col-sm-3 col-form-label">Paciente</label>
                                            <div className="col-sm-9 d-flex">
                                              <SelectSearchble 
                                                listCompany={paciente} 
                                                handleChange={handleChange} 
                                                selectedOption={selectedOption}
                                                placeholder="Buscar Paciente"
                                                styles={{width: '100%'}}
                                            
                                              />
                                              <button type="button" className="btn btn-primary mx-1" id="btn-add-paciente" onClick={handleShowPaciente}>
                                                <i className="fas fa-plus"></i>
                                              </button>
                                            </div>
                                        </div>
                                      </div>
    
                                      <div className="col-12">
                                        <div className="row mb-3">
                                          <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Doctor</label>
                                          <div className="col-sm-9">
                                            <input type="text" id="ag-id-doctor" className="form-control" disabled value={doctor.nombre} />
                                          </div>
                                        </div>
                                      </div>
    
    
                                      <div className="col-12">
                                        <div className="row mb-3">
                                          <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Fecha</label>
                                          <div className="col-sm-9">
                                            <input className="form-control" type="date" value={citapaciente.fecha_inicio}
                                            id="ag-fecha-cita" />
                                          </div>
                                        </div>
                                      </div>
    
    
                                      <div className="col-12">
                                        <div className="row mb-3">
                                          <label for="example-time-input" className="col-sm-3 col-form-label">Hora</label>
                                          <div className="col-sm-9">
                                            <div className="row">
                                              <div className="col-sm-6">
                                                <input className="form-control" type="time"
                                                  value={citapaciente.hora_inicio}
                                                  disabled
                                                  id="ag-hora-inicio" />
                                              </div>
                                              <div className="col-sm-6">
                                                <input className="form-control" type="time"
                                                  value={citapaciente.hora_fin}
                                                  disabled
                                                  id="ag-hora-fin" />
                                              </div>
                                            </div>
    
                                          </div>
                                        </div>
                                      </div>
    
    
                                      <div className="col-12">
                                        <div className="row mb-3">
                                          <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Tipo Cita</label>
                                          <div className="col-sm-9">
                                            <select className="form-control" id="ag-tipo-cosulta"
                                              value={citapaciente.idtipo_cita}
                                              onChange={(e) => setCitaPaciente({...citapaciente, idtipo_cita: e.target.value})}
                                            >
                                              <option value="" selected=""> --Select-- </option>
                                              {
                                                tipoCitas.map((tipo, index) => (
                                                    <option key={index} value={tipo.idtipo_cita}>{tipo.tipo}</option>
                                                ))
                                              }
                                            </select>
                                          </div>
                                        </div>
                                      </div>
    
                                      <div className="col-12">
                                        <div className="row mb-3">
                                          <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Motivo</label>
                                          <div className="col-sm-9">
                                            
                                            <textarea className="form-control" rows="2" id="ag-motivo-consulta" 
                                              value={citapaciente.motivo}
                                              onChange={(e) => setCitaPaciente({...citapaciente, motivo: e.target.value})}
                                            ></textarea>

                                          </div>
                                        </div>
                                      </div>
    
                                      <div className="col-12">
                                        <div className="row mb-3">
                                          <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Estado</label>
                                          <div className="col-sm-9">

                                            <select className="form-control form-select" name="category" id="ag-estado-cita"
                                              value={citapaciente.id_estado_cita}
                                              onChange={(e) => setCitaPaciente({...citapaciente, id_estado_cita: e.target.value})}
                                            >
                                              <option value="" selected=""> --Select-- </option>
                                              {
                                                estadosCitas.map((estado, index) => (
                                                    <option key={index} value={estado.idestado_cita}>{estado.estado}</option>
                                                ))
                                              }
                                            </select>

                                          </div>
                                        </div>
                                      </div>
    
    
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col-4">
    
                                      </div>
                                      <div className="col-8 text-end">
                                        <button type="button" className="btn btn-light me-1" onClick={handleClose}>Cerrar</button>
                                        <button type="button" className="btn btn-primary" id="btn-save-event"
                                          onClick={GuardarCita}>Guardar</button>
                                      </div>
                                    </div>
                                  </form>
                                </Modal.Body>
    
                              </Modal>
                            </div>
    
                            <div className="col-12 mt-2">
                                <Flatpickr 
                                    value={citapaciente.fecha_inicio}
                                    onChange={(date) => handleChangefecha(date)}
                                    options={{ 
                                    inline: 'true', 
                                    minDate: new Date().fp_incr(-1),
                                    buttonText: {
                                        today: 'Hoy',
                                        month: 'Mes',
                                        week: 'Semana',
                                        day: 'Dia',
                                        list: 'Lista'
                                    },
                                    locale: {
                                        weekdays: {
                                            shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                                            longhand: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],         
                                        },
                                        months: {
                                            shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                                            longhand: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],         
                                        },
                                    },
                                    time_24hr: true,
                                    altInput: true,
                                }} />
    
                            </div>
    
                          </div>
                        </div>
    
                        <div className="row justify-content-center mt-5">
                          <div className="col-lg-12 col-sm-6">
                            <img src="assets/images/undraw-calendar.svg" alt="" className="img-fluid d-block" />
                          </div>
                        </div>
    
                      </div>
                    </div>
                  </div>
    
                  <div className="col-xl-9 col-lg-8">
                    <div className="card">
                      <div className="card-body">
                        <DoctorScheduleTable 
                          doctoresEspecialidades={doctoresEspecialidades}
                          fecha={fecha}
                          show={show}
                          setShow={setShow}
                          setDoctor={setDoctor}
                          setHora={setHora}
                          setCitaPaciente={setCitaPaciente}
                          citasDoctotes={citasDoctotes}
                        />
                      </div>
                    </div>
                  </div>
    
                </div>
    
              </div>
            </div>
    
          </div>
      <Offcanvas style={{ 
          width: '50%',
          zIndex: 9999,
        }} 
        placement="end" 
        show={showPaciente} onHide={handleClosePaciente}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Crear Nuevo Paciente
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <PacienteCreacion 
            handleClosePaciente={handleClosePaciente}
            showPaciente={showPaciente}
            idclinica={idclinica}
          />
        </Offcanvas.Body>
      </Offcanvas>
    
        </>
      )
}
