import React from 'react'

export default function Personal({descripcion, setDescripcion, asignarAntecedente}) {

  const handleClicked = async (id_antecedente, observacion, tiene, cp, sp, id_categoria_antecedente) => {
    // obtener la query de la url
    const urlParams = new URLSearchParams(window.location.search)
    let id_paciente = urlParams.get('idpaciente')
    if(!id_paciente) return console.log('No se encontro el id del paciente')
    await asignarAntecedente(id_antecedente, observacion, Number(id_paciente), tiene.target.checked, cp, sp, id_categoria_antecedente)
  }

  const handleObservacion = async (id_antecedente, id_categoria_antecedente, e) => {
    const urlParams = new URLSearchParams(window.location.search)
    let id_paciente = urlParams.get('idpaciente')
    if(!id_paciente) return console.log('No se encontro el id del paciente')

    let filtro = descripcion.filter((desc) => desc.id_antecedente === id_antecedente)
    if(filtro.length === 0) return console.log('No se encontro el antecedente')

    filtro[0].antecedentes_paciente[0].observacion = e.target.value
    const {observacion, tiene, cp, sp} = filtro[0].antecedentes_paciente[0]
    await asignarAntecedente(id_antecedente, observacion, Number(id_paciente), tiene, cp, sp, id_categoria_antecedente)
  }

  const handleObservacionChange = (id_antecedente, id_categoria_antecedente, e) => {
    // actualizar el estado de la observacion
    descripcion.map((desc) => {
      if(desc.id_antecedente === id_antecedente){
        desc.antecedentes_paciente[0].observacion = e.target.value
      }
    })
    setDescripcion([...descripcion])
  }

  return (
    <>
      <h5>Antecende Personales</h5>
      <div className="row">
        {
          descripcion.map((desc, index) => {
            return <div className="col-6 d-flex justify-content-between align-items-center mb-3"
            key={index+1} >
              <div className="form-check form-switch form-switch-md ">
                <input className="form-check-input" type="checkbox" name={String(desc.descripcion).replace(/ /g, "")
                .replace(/á/g, "a").replace(/é/g, "e").replace(/í/g, "i").replace(/ó/g, "o")
                .replace(/ú/g, "u").replace(/ñ/g, "n").toLowerCase()}
                id={String(desc.descripcion).replace(/ /g, "")
                .replace(/á/g, "a").replace(/é/g, "e").replace(/í/g, "i").replace(/ó/g, "o")
                .replace(/ú/g, "u").replace(/ñ/g, "n").toLowerCase()}
                checked={desc.antecedentes_paciente && desc.antecedentes_paciente.length > 0 ? desc.antecedentes_paciente[0].tiene : false}
                onClick={(e) => handleClicked(desc.id_antecedente, null, e, false, false, desc.id_categoria_antecedente)}
                
                />
              </div>
                <label className="form-check-label w-50" htmlFor="flexSwitchCheckChecked">{desc.descripcion}</label>
                <input type="text" className="form-control" placeholder="Observacion" 
                  disabled={desc.antecedentes_paciente && desc.antecedentes_paciente.length > 0 ? !desc.antecedentes_paciente[0].tiene : true}
                  value={[desc.antecedentes_paciente && desc.antecedentes_paciente.length > 0 ? desc.antecedentes_paciente[0].observacion : ''] || ''}
                  onChange={(e) => handleObservacionChange(desc.id_antecedente, desc.id_categoria_antecedente, e)}
                  onKeyDown={(e) => {
                    if(e.key === 'Enter'){
                      handleObservacion(desc.id_antecedente, desc.id_categoria_antecedente, e)
                    }
                  }}

                />
            </div>

          })
        }
      </div>
    </>
  )
}
