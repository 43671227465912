import { BmHttp } from "../utils/Global";

export const CreacionCompany = async (company) => {
    try {
        const url = `api/company`;
        const { data } = await BmHttp().post(url, company);
        if (data.success) {
            return data
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

export const ObtenerCompanies = async () => {
    try {
        const url = `api/company`;
        const { data } = await BmHttp().get(url);
        if (data.success) {
            return data.data
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

export const ObtenerCompany = async (id) => {
    try {
        const url = `api/company/${id}`;
        const { data, status } = await BmHttp().get(url);
        if (status === 200) {
            return data;
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

export const ActualizarCompany = async (company, id) => {
    try {
        const url = `api/company/${id}`;
        const { data } = await BmHttp().put(url, company);
        if (data.success) {
            return data;
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

export const EliminarCompany = async (id) => {
    try {
        const url = `api/company/${id}`;
        const { data } = await BmHttp().delete(url);
        if (data.success) {
            return data;
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}