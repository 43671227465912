import React from 'react'

export default function Paquetes() {
  return (
    <div>
        <div class="mt-3">

<div class="card">
    
    <div class="card-body">
        <div class="row mb-4">
            <div class="col-xl-10">
                <h6 class="card-title">LISTADO DE PAQUETES</h6>            
            </div>      
            <div class="col-xl-2">
                <button class="btn btn-light waves-effect waves-light"
                    onclick="openCanvasInsertPaqueteOdon()"> <i class="bx bx-plus me-1"></i> PAQUETE </button>    
            </div>         
        </div>

        <table id="tb_planes_paquetes" style={{fontSize:"14px"}} class="table table-hover-border  border-in-bottom br-mn table-bordered-content dotted">

            <thead class="upper br-sm table-light">
                <tr class="f12 upper bg-grey-l">                                                                       
                    <th scope="col">Fecha Inicia</th>
                    <th scope="col">Fecha Fin</th>
                    <th scope="col">Tipo Ortodoncia</th>
                    <th scope="col">Tipo Pago</th>
                    <th scope="col">Valor Tratamiento</th>
                    <th scope="col">Valor Entrada</th>
                    <th scope="col">Cuotas</th>
                    <th scope="col">Valor Cuotas</th>
                    <th scope="col">Total</th>
                    <th scope="col">Estado</th>
                    <th scope="col" ></th>                                                
                </tr>
            </thead>

            <tbody class="bg-white" id="bodyanamnesis">

            </tbody>
        </table>
    </div>
</div>
</div>

<hr/>
    </div>
  )
}
