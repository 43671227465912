import { BmHttp as Http } from "../utils/Global";

export const ListCategoriaExamen = async () => {
  try {
    const { data } = await Http().get(`get_categoria_examen`);
    return data;
  } catch (error) {
    console.log("error", error);
  }
};

export const ListExamenCategoriaID = async (categoriaID) => {
  try {
    const { data } = await Http().post(`examen_categoria_id`, { categoria_id: categoriaID });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export const ListTipoEstudio = async (categoriaID) => {
  try {
    const { data } = await Http().post(`tipo_estudio`, { categoria_id: categoriaID });
    return data;
  } catch (error) {
    console.log(error);
  }
}


