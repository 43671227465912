import React from "react";

function TableImagenExamen(props) {
  let { items, currentPage, totalPage, nextHandler, prexHandler, setItemsExamenesFilter, setSelectExamen, setForm, form } = props;

  if (!items || items.length === 0) {
    return;
  }

  const resetCheck = () => {
    let reset = [...items];
    
    reset.map(item => {
      item.disabled = false;
      item.checked = false;
      return item;
    });

    return reset;
  };

  const handlerCheckout = (e, examen) => {
    items = items.map(examenItem => {
      if (examenItem.IdExamenTipo === examen.IdExamenTipo) {
        examenItem.checked = e.target.checked;
      }

      return examenItem;
    });

    let examenesChecked = [...items];
    examenesChecked = examenesChecked.filter(
      (examen) => examen.checked === true
    );

    let idExamenTipoArray = [...examenesChecked];
    idExamenTipoArray = idExamenTipoArray.map(item => {
      return item.IdExamenTipo;
    });

    setSelectExamen(examen);
    setForm({...form, IdExamenTipo: [...idExamenTipoArray]});
    setItemsExamenesFilter(items);
  };

  const itemsHtml = items.map((item, index) => {
    return (
      <div
        key={index}
        className="col-md-4 d-flex justify-content-start align-items-center pb-2"
      >
        <div className="form-check form-switch form-switch-md">
          <input
            type="checkbox"
            className="form-check-input"
            value={item.IdExamenTipo}
            checked={item.checked}
            onChange={(e) => handlerCheckout(e, item)}
          />
        </div>
        <label className="form-check-label ml-2 text-uppercase text-12">
          {item.descripcion}
        </label>
      </div>
    );
  });

  return (
    <>
      <div className="w-100 row">{itemsHtml}</div>

      <div className="w-100 row d-flex justify-content-end mt-3">
        <div className="col-auto d-flex align-items-center gap-10">
          <span>
            Página: {currentPage + 1} de {totalPage}
          </span>
          {currentPage > 0 ? (
            <button
              className="btn btn-sm btn-soft-primary"
              onClick={prexHandler}
            >
              Anterior
            </button>
          ) : (
            ""
          )}
          {currentPage + 1 !== totalPage ? (
            <button
              className="btn btn-sm btn-soft-primary"
              onClick={nextHandler}
            >
              Siguiente
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default TableImagenExamen;
