import React from "react";
import { mensajes } from "../../utils/Global";

export default function Alert({ tipo_alert, msj, type }) {
  return (
    <>
      {(mensajes[type] || msj) && (
        <div className={"alert alert-" + tipo_alert + " p-1"}>
          {mensajes[type] ? mensajes[type] : msj}
        </div>
      )}
    </>
  );
}
