import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from '../Components/Footer/Footer';
import AdminNavbar from '../Components/Navbars/AdminNavbar';
import Sidebar from '../Components/Sidebar/Sidebar';
import Agenda from '../Page/Agenda';
import Configuracion from '../Page/Configuracion';
import Dashboard from '../Page/Dashboard';
import FacturaCrear from '../Page/FacturaCrear';
import FacturaListar from '../Page/FacturaListar';
import GestionCitas from '../Page/GestionCitas';
import HistorialClinico from '../Page/HistorialClinico';
import Pacientes from '../Page/Pacientes';
import SigVital from '../Page/SigVital';

export default function Admin() {

    const [collaseSidebar, setCollapseSidebar] = useState(true);

    const toggleMenu = () => {
        // quitar esta clase navbar-brand-box y agregar widthSidebar
        const navbarBrandBox = document.querySelector('.navbar-brand-box');
        const widthSidebar = document.querySelector('.vertical-menu');
        navbarBrandBox.classList.toggle('widthSidebar');
        widthSidebar.classList.toggle('widthSidebar');
    }

  return (
    <div id="layout-wrapper">
        <AdminNavbar
            collaseSidebar={collaseSidebar}
            setCollapseSidebar={setCollapseSidebar}
            toggleMenu={toggleMenu}
        />
        <Sidebar
            collaseSidebar={collaseSidebar}
        />
        <div class="main-content">
            <Routes>
                <Route path="/" element={<Dashboard/>} />
                <Route path="/pacientes" element={<Pacientes/>} />
                <Route path="/paciente_historial" element={<HistorialClinico/>} />
                <Route path="/gestion_citas" element={<GestionCitas/>} />
                <Route path="/sigvital" element={<SigVital/>} />
                <Route path="/agenda" element={<Agenda/>} />
                <Route path="/factura-listar" element={<FacturaListar/>} />
                <Route path="/factura-crear" element={<FacturaCrear/>} />
                <Route path="/configuracion" element={<Configuracion/>} />

                {/* <Route path="/users" element={<Users />} /> */}
                {/* <Route path="/products" element={<Products />} /> */}
                {/* <Route path="/orders" element={<Orders />} /> */}
            </Routes>
            <Footer/>
        </div>
    </div>
  )
}
