import { fechaFailureToast, ListaExamenFailureToast, salaFailureToast } from "../ModalExamenToast";

export const validationFormLaboratorio = (form) => {
  const { sala, fecha, IdExamenTipo } = form;

  if (typeof sala === 'string' && sala.length === 0) {
    salaFailureToast();
    return false;
  } else 
  if (typeof fecha === 'string' && fecha.length === 0) {
    fechaFailureToast();
    return false;
  } else 
  if (Array.isArray(IdExamenTipo) && IdExamenTipo.length === 0) {
    ListaExamenFailureToast();
    return false;
  } 

  return true;
}