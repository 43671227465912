import { BmHttp } from "../utils/Global"


export const ListarPacientes = async (idclinica) => {
    try {
        const { data } = await BmHttp().get(`listar_paciente?idclinica=${idclinica}`)
        return data
    } catch (error) {
        console.log(error)
    }
}

export const RegistrarPaciente = async (datos) => {
    try {
        if(datos.idpaciente > 0){
            return ActualizarPaciente(datos)
        }else{
            const { data } = await BmHttp().post(`crear_paciente`, datos)
            return data
        }
    } catch (error) {
        console.log(error)
    }
}

export const ActualizarPaciente = async (datos) => {
    try {
        const { data } = await BmHttp().put(`actualizar_paciente`, datos)
        return data
    } catch (error) {
        console.log(error)
    }
}

export const EliminarPaciente = async (idpaciente) => {
    try {
        const { data } = await BmHttp().delete(`eliminar_paciente?idpaciente=${idpaciente}`)
        return data
    } catch (error) {
        console.log(error)
    }
}

export const ControllerListarPacientesCitas = async (idpaciente) => {
    try {
        const { data } = await BmHttp().get(`listar_paciente_ultimas_citas?idpaciente=${idpaciente}`)
        return data
    } catch (error) {
        console.log(error);
    }
}


export const ControllerListarPacientesCitasTake = async (idpaciente) => {
    try {
        const { data } = await BmHttp().get(`listar_paciente_ultimas_citas?idpaciente=${idpaciente}&take=1`)
        return data
    } catch (error) {
        console.log(error);
    }
}