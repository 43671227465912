import { BmHttp } from "../utils/Global";

export const ListarEstadoCitas = async () => {
  const { data } = await BmHttp().get("listar_estado_cita");
  return data;
};

export const ListarTipoCitas = async () => {
  const { data } = await BmHttp().get("listar_tipo_cita");
  return data;
};
