import React, { useState, useEffect } from "react";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Modal, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Swal from "sweetalert2";
import {
  ListarPacientes,EliminarPaciente,ControllerListarPacientesCitasTake,} from "../Service/Service.paciente";
import { BmHttp } from "../utils/Global";

import PacienteCreacion from "../Components/Configuracion/ModalCreacion/PacienteCreacion";
import { ObtenerClinica } from "../Service/Serive.clinica";
import { GetTokenDecoded } from "../utils/storeUsuario";
import axios from "axios";

export default function FacturaCrear() {
  const [pacientes, setPacientes] = useState([]);
  const [pacienteEdit, setPacienteEdit] = useState({});
  const [paciente, setPaciente] = useState([]);
  const [procedimientos, setProcedimientos] = useState([]);
  const [pacienteSeleccionado, setPacienteSeleccionado] = useState(null);
  const [clinica, setClinica] = useState(null);
  const [totalFactura, setTotalFactura] = useState(0);
  const [ultimaCita, setUltimaCita] = useState(null);
  const [cantPagar, setCantPagar] = useState("0.00");
  const [pagos, setPagos] = useState([]);
  const [metodoPago, setMetodoPago] = useState("Efectivo");
  const [valorPendiente, setValorPendiente] = useState(0);
  const [showPaciente, setShowPaciente] = useState(false);

  const [showProcedimientoModal, setShowProcedimientoModal] = useState(false);
  const [procedimientosDisponibles, setProcedimientosDisponibles] = useState(
    []
  );
  const [procedimientoSeleccionado, setProcedimientoSeleccionado] =
    useState(null);
  const [nuevoProcedimiento, setNuevoProcedimiento] = useState({
    idExamenTipo: "",
    precio: "",
    comentarios: "",
  });

  useEffect(() => {
    const obtenerPacientes = async () => {
      const tokenDecoded = GetTokenDecoded();
      if (tokenDecoded && tokenDecoded.idclinica) {
        const response = await ListarPacientes(tokenDecoded.idclinica);
        if (response && response.success) {
          // Transformar los datos para react-select
          const pacientesOptions = response.data.map((p) => ({
            value: p.idpaciente,
            label: p.nombres_completos,
            ...p,
          }));
          setPacientes(pacientesOptions);
        }
      }
    };

    const total = calcularTotalFactura(procedimientos);
    setTotalFactura(total);

    obtenerPacientes();
    obtenerInfoClinica();
  }, []);

  useEffect(() => {
    const nuevoPendiente = Math.max(
      0,
      parseFloat(totalFactura) - calcularTotalPagado()
    );
    setValorPendiente(nuevoPendiente);
    setCantPagar(nuevoPendiente.toFixed(2));
  }, [totalFactura, pagos]);

  const handleClosePaciente = async () => {
    setShowPaciente(false);
    const pacint = await ListarPacientes(GetTokenDecoded().idclinica);
    if (pacint.success) {
      setPaciente(
        pacint.data.map((p) => ({
          value: p.idpaciente,
          label: p.nombres_completos,
          ...p,
        }))
      );
    }
  };

  const handleEditarPaciente = (paciente) => {
    setPacienteEdit(paciente);
    setShowPaciente(true);
  };

  const calcularTotalPagado = () => {
    return pagos.reduce((sum, pago) => sum + parseFloat(pago.valor), 0);
  };

  const agregarPago = () => {
    const valorPago = parseFloat(cantPagar);

    if (isNaN(valorPago) || valorPago <= 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "El valor a pagar debe ser un número mayor que cero",
      });
      return;
    }

    const nuevoPago = {
      metodo: metodoPago,
      valor: valorPago,
      // Añade aquí otros campos necesarios como banco, tarjeta, número, etc.
    };

    const nuevosPagos = [...pagos, nuevoPago];
    setPagos(nuevosPagos);

    // Recalcular el valor pendiente
    const nuevoPendiente = Math.max(
      0,
      parseFloat(totalFactura) - calcularTotalPagado() - valorPago
    );
    setValorPendiente(nuevoPendiente);

    // Resetear el valor de cantPagar
    setCantPagar(nuevoPendiente.toFixed(2));
  };

  const eliminarPago = (index) => {
    const nuevosPagos = pagos.filter((_, i) => i !== index);
    setPagos(nuevosPagos);

    // Recalcular el valor pendiente
    const totalPagado = nuevosPagos.reduce((sum, pago) => sum + pago.valor, 0);
    const nuevoPendiente = parseFloat(totalFactura) - totalPagado;
    setValorPendiente(nuevoPendiente);
  };

  const handleCantPagarChange = (e) => {
    setCantPagar(e.target.value);
  };

  const isFacturaButtonEnabled = () => {
    const pendiente = parseFloat(totalFactura) - calcularTotalPagado();
    return Math.abs(pendiente) < 0.01; // Usamos una pequeña tolerancia para evitar problemas con números flotantes
  };

  const obtenerProcedimientosDisponibles = async () => {
    try {
      const response = await BmHttp().get("/procedimiento_precios");
      if (response.data.success) {
        setProcedimientosDisponibles(response.data.data);
      }
    } catch (error) {
      console.error("Error al obtener procedimientos:", error);
    }
  };

  const handleProcedimientoChange = (e) => {
    const selectedProcedimiento = procedimientosDisponibles.find(
      (proc) => proc.idExamenTipo.toString() === e.target.value
    );
    setProcedimientoSeleccionado(selectedProcedimiento);
    setNuevoProcedimiento({
      ...nuevoProcedimiento,
      idExamenTipo: selectedProcedimiento.idExamenTipo,
      precio: selectedProcedimiento.precio,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoProcedimiento({
      ...nuevoProcedimiento,
      [name]: value,
    });
  };

  const guardarNuevoProcedimiento = async () => {
    if (!ultimaCita) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No hay una cita seleccionada",
      });
      return;
    }

    try {
      const response = await BmHttp().post("/cita_procedimiento", {
        idcita: ultimaCita.idcita, // Asegúrate de tener acceso a ultimaCita
        idExamenTipo: nuevoProcedimiento.idExamenTipo,
        precio: parseFloat(nuevoProcedimiento.precio),
        observaciones: nuevoProcedimiento.comentarios,
      });

      if (response.data.success) {
        // Actualizar la lista de procedimientos

        await actualizarDatosPaciente();

        setShowProcedimientoModal(false);

        Swal.fire({
          icon: "success",
          title: "Éxito",
          text: "Procedimiento guardado correctamente",
        });

        // Limpiar el formulario
        setNuevoProcedimiento({
          idExamenTipo: "",
          precio: "",
          comentarios: "",
        });
      } else {
        // La API devolvió un error
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            response.data.message ||
            "Hubo un error al guardar el procedimiento",
        });
      }
    } catch (error) {
      console.error("Error al guardar el procedimiento:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.response?.data?.message ||
          "Hubo un error al guardar el procedimiento",
      });
    }
  };

  const actualizarDatosPaciente = async () => {
    try {
      const response = await ControllerListarPacientesCitasTake(
        pacienteSeleccionado.value
      );
      if (response) {
        const ultimaCitaData = response.data;
        setUltimaCita(ultimaCitaData);
        const procsCita = ultimaCitaData.procedimiento_cita_paciente.map(
          (proc) => ({
            ...proc,
            valorPagar: (
              parseFloat(proc.precio || 0) * parseFloat(proc.cantidad || 0)
            ).toFixed(2),
          })
        );
        setProcedimientos(procsCita);
        const total = calcularTotalFactura(procsCita);
        setTotalFactura(total);
      } else {
        console.error("No se encontraron datos actualizados");
        Swal.fire({
          icon: "warning",
          title: "Advertencia",
          text: "No se pudieron obtener los datos actualizados",
        });
      }
    } catch (error) {
      console.error("Error al actualizar los datos del paciente:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un error al actualizar los datos del paciente",
      });
    }
  };

  const handlePacienteChange = (selectedOption) => {
    if (selectedOption) {
      // Simular un evento para mantener la compatibilidad con pacienteInfo
      const event = {
        target: {
          value: selectedOption.value.toString(),
        },
      };
      pacienteInfo(event);
    } else {
      // Limpiar la selección
      const event = {
        target: {
          value: "",
        },
      };
      pacienteInfo(event);
    }
  };

  const calcularTotalFactura = (procs) => {
    const total = procs.reduce((acc, proc) => {
      const valor =
        parseFloat(proc.valorPagar) ||
        parseFloat(proc.precio || 0) * parseFloat(proc.cantidad || 0);
      return acc + valor;
    }, 0);
    return total.toFixed(2);
  };

  const pacienteInfo = async (event) => {
    const pacienteId = event.target.value;
    const pacienteSeleccionado = pacientes.find(
      (p) => p.idpaciente.toString() === pacienteId
    );

    if (pacienteSeleccionado) {
      setPacienteSeleccionado(pacienteSeleccionado);

      // Actualizar la información en la UI
      document.getElementById("sp_c_nombre").textContent =
        pacienteSeleccionado.nombres_completos;
      document.getElementById("sp_c_doc").textContent =
        pacienteSeleccionado.cedula;
      document.getElementById("sp_c_telefono").textContent =
        pacienteSeleccionado.celular;
      document.getElementById("sp_c_correo").textContent =
        pacienteSeleccionado.email;

      // Mostrar la tarjeta de información del paciente
      document.getElementById("card-paciente-info").style.display = "block";

      const response = await ControllerListarPacientesCitasTake(
        pacienteSeleccionado.value
      );

      if (response) {
        const ultimaCita = response.data;
        setUltimaCita(ultimaCita);
        const procsCita = ultimaCita.procedimiento_cita_paciente.map(
          (proc) => ({
            ...proc,
            valorPagar: (
              parseFloat(proc.precio || 0) * parseFloat(proc.cantidad || 0)
            ).toFixed(2),
          })
        );

        setProcedimientos(procsCita);

        const total = calcularTotalFactura(procsCita);
        setTotalFactura(total);
      } else {
        console.log("No se encontraron citas o procedimientos");
        setProcedimientos([]);
        setTotalFactura(0);
      }
    } else {
      setPacienteSeleccionado(null);
      setTotalFactura(0);
      setProcedimientos([]);
      // Ocultar la tarjeta de información del paciente si no hay selección
      document.getElementById("card-paciente-info").style.display = "none";
    }
  };

  const handleValorPagarChange = (index, newValue) => {
    const updatedProcedimientos = procedimientos.map((proc, i) =>
      i === index ? { ...proc, valorPagar: newValue } : proc
    );
    setProcedimientos(updatedProcedimientos);

    const newTotal = calcularTotalFactura(updatedProcedimientos);
    setTotalFactura(newTotal);
  };

  const obtenerInfoClinica = async () => {
    const tokenDecoded = GetTokenDecoded();
    if (tokenDecoded && tokenDecoded.idclinica) {
      const response = await ObtenerClinica(tokenDecoded.idclinica);
      if (response && response.success && response.data.length > 0) {
        setClinica(response.data[0]);
      }
    }
  };

  return (
    <>
      <div class="page-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">Crear Facturas</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item">
                      <a href="#">Facturas</a>
                    </li>
                    <li class="breadcrumb-item active">Crear</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-8">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-3">
                    <label for="inputEmail4">Clinicas</label>
                    <select
                      className="form-select"
                      id="fc_idclinica"
                      name="fc_idclinica"
                    >
                      {clinica ? (
                        <option value={clinica.idclinica}>
                          {clinica.nombre}
                        </option>
                      ) : (
                        <option value="">Cargando...</option>
                      )}
                    </select>
                  </div>

                  <div class="form-group col-md-6 ">
                    <label for="inputEmail4">Paciente</label>

                    <div class="row">
                      <div class="col-10">
                        <Select
                          id="fc-paciente"
                          name="fc-paciente"
                          options={pacientes}
                          onChange={handlePacienteChange}
                          placeholder="Buscar paciente..."
                          isClearable
                        />
                      </div>
                      <div class="col-2">
                        <button
                          id="btn_emptyPaciente"
                          type="button"
                          onclick="validateEmptyInfoPaciente()"
                          style={{ display: "none" }}
                          class="btn btn-soft-light waves-effect waves-light"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-9 mt-2">
                    <label for="inputEmail4">Facturar otros Datos:</label>
                    <div class="row">
                      <div class="col-8">
                        <select
                          class="form-select"
                          id="fc-cliente"
                          name="fc-cliente"
                          onchange="clienteInfo()"
                        ></select>
                      </div>
                      <div class="col-4">
                        <button
                          type="button"
                          class="btn btn-soft-light waves-effect waves-light"
                          onClick={() => setShowPaciente(!showPaciente)}
                        >
                          <i class="fas fa-user-plus"></i>
                        </button>
                        <button
                          id="bn_emptyCliente"
                          type="button"
                          class="btn btn-soft-light waves-effect waves-light"
                          
                          style={{ display: "none" }}
                        >
                          
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="row">
              <div
                id="card-paciente-info"
                class="col-12"
                style={{ display: "none" }}
              >
                <div
                  class="card border border-primary"
                  style={{ marginBottom: "10px" }}
                >
                  <div class="card-body" style={{ padding: "0.7rem 1.05rem" }}>
                    <span class="card-subtitle text-muted">
                      <strong>PACIENTE:</strong>{" "}
                    </span>{" "}
                    <span
                      id="sp_c_nombre"
                      style={{ fontSize: "17px", fontWeight: "lighter" }}
                    ></span>
                    <br />
                    <small class="text-muted">
                      <strong>
                        <i class="far fa-address-card"></i>
                      </strong>{" "}
                      <span id="sp_c_doc"></span>
                    </small>{" "}
                    &nbsp;&nbsp;
                    <small class="text-muted">
                      <strong>
                        <i class="fas fa-mobile-alt"></i>
                      </strong>{" "}
                      <span id="sp_c_telefono"></span>
                    </small>
                    <br />
                    <small class="text-muted">
                      <i class="fas fa-envelope"></i>{" "}
                      <span id="sp_c_correo"></span>
                    </small>
                    <br />
                  </div>
                </div>
              </div>
              <div
                id="card-cliente-info"
                class="col-12"
                style={{ display: "none" }}
              >
                <div
                  class="card border border-success"
                  style={{ marginBottom: "10px" }}
                >
                  <div class="card-body" style={{ padding: "0.7rem 1.05rem" }}>
                    <span class="card-subtitle text-muted">
                      <strong>CLIENTE:</strong>{" "}
                    </span>{" "}
                    <span
                      id="sp_cl_nombre"
                      style={{ fontSize: "17px", fontWeight: "lighter" }}
                    ></span>
                    <br />
                    <small class="text-muted">
                      <strong>
                        <i class="far fa-address-card"></i>
                      </strong>{" "}
                      <span id="sp_cl_doc"></span>
                    </small>{" "}
                    &nbsp;&nbsp;
                    <small class="text-muted">
                      <strong>
                        <i class="fas fa-mobile-alt"></i>
                      </strong>{" "}
                      <span id="sp_cl_telefono"></span>
                    </small>
                    <br />
                    <small class="text-muted">
                      <i class="fas fa-envelope"></i>{" "}
                      <span id="sp_cl_correo"></span>
                    </small>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="mb-2">
            <div class="row">
              <div class="col-5">
                <button
                  type="button"
                  class="btn btn-soft-secondary waves-effect btn-label waves-light"
                  onClick={() => {
                    if (ultimaCita) {
                      obtenerProcedimientosDisponibles();
                      setShowProcedimientoModal(true);
                    } else {
                      Swal.fire({
                        icon: "warning",
                        title: "Atención",
                        text: "Por favor, seleccione un paciente primero",
                      });
                    }
                  }}
                >
                  <i class="bx bx-plus label-icon"></i> Procedimientos
                  Pendientes
                </button>
              </div>

              <div class="col-1">
                <div
                  class="spinner-border spin_loading text-primary m-1"
                  role="status"
                  style={{ display: "none" }}
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-9">
            <div class="card">
              <div class="card-body">
                <table id="tb_factura" class="table">
                  <thead class="upper br-sm br-white">
                    <tr class="bg-grey-l">
                      <th>
                        <div align="center">#</div>
                      </th>
                      <th style={{ textAlign: "left" }}>Procedimiento</th>
                      <th style={{ textAlign: "left" }}>Tipo</th>
                      <th>
                        <div align="center">Cant</div>
                      </th>
                      <th>
                        <div align="center">PVP</div>
                      </th>
                      <th>
                        <div align="center">
                          <div align="center">Valor Pagar</div>
                        </div>
                      </th>
                      <th>
                        <div align="center">Impuesto</div>
                      </th>
                      <th>
                        <div align="center">Total Pagar</div>
                      </th>
                      <th style={{ display: "none" }}> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {procedimientos && procedimientos.length > 0 ? (
                      procedimientos.map((proc, index) => (
                        <tr key={proc.idprocedimiento_cita_paciente || index}>
                          <td align="center">{index + 1}</td>
                          <td>{proc.ExamenTipo?.descripcion || "N/A"}</td>
                          <td>{proc.ExamenTipo?.tipo || "N/A"}</td>
                          <td align="center">{proc.cantidad || "0"}</td>
                          <td align="center">{proc.precio || "0.00"}</td>
                          <td align="center">
                            <input
                              type="number"
                              className="form-control"
                              value={proc.valorPagar}
                              onChange={(e) =>
                                handleValorPagarChange(index, e.target.value)
                              }
                              style={{ width: "80px", textAlign: "right" }}
                            />
                          </td>
                          <td align="center">0.00</td>
                          <td align="center">
                            {(
                              parseFloat(proc.precio || 0) *
                              parseFloat(proc.cantidad || 0)
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" align="center">
                          No hay procedimientos para mostrar
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-3">
            <div class="card">
              <div class="card-body">
                <table class="table table-sm">
                  <tr>
                    <td align="right">
                      <strong>SUBTOTAL</strong>
                    </td>
                    <td align="right">
                      <span
                        id="sp_subtotal_factura"
                        style={{ color: "green", fontSize: "17px" }}
                      >
                        {totalFactura}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td align="right">
                      <strong>IMPUESTO</strong>
                    </td>
                    <td align="right">
                      <span
                        id="sp_iva_fact"
                        style={{ color: "blue", fontSize: "17px" }}
                      >
                        00.00
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td align="right">
                      <strong>TOTAL</strong>
                    </td>
                    <td align="right">
                      <strong>
                        <span
                          id="sp_total_factura"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          {totalFactura}
                        </span>
                      </strong>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Metodos de Pago</h4>

                <div class="row">
                  <div class="form-group col-md-12 mb-2">
                    <select
                      id="metodopago"
                      class="form-select"
                      data-placeholder="seleccione forma de pago"
                      value={metodoPago}
                      onChange={(e) => setMetodoPago(e.target.value)}
                    >
                      <option value="">Agregar forma de Pago</option>
                      <option value="Efectivo" selected>
                        Efectivo
                      </option>
                      <option value="Deposito Bancario">
                        Depósito Bancario
                      </option>
                      <option value="Transferencia Bancaria">
                        Transferencia Bancaria
                      </option>
                      <option value="Débito Bancario">Débito Bancario</option>
                      <option value="Tarjeta Crédito">Tarjeta Crédito</option>
                      <option value="Tarjeta Débito">Tarjeta Débito</option>
                      <option value="Cheque">Cheque</option>
                    </select>
                  </div>

                  <div id="dv_efectivo" class="form-row mb-3">
                    <div class="form-group col-md-12">
                      <label class="control-label" for="cantidad">
                        Valor a Agregar ($)
                      </label>
                      <input
                        id="cant_pagar"
                        class="form-control"
                        type="text"
                        value={cantPagar}
                        onChange={handleCantPagarChange}
                      />
                    </div>
                    {!isFacturaButtonEnabled() && (
                      <div className="text-danger mt-2">
                        El total pagado debe ser igual al total de la factura
                        para poder ingresarla.
                      </div>
                    )}
                  </div>

                  <div align="right">
                    <button
                      onClick={agregarPago}
                      type="button"
                      className="btn btn-primary constant"
                    >
                      Agregar Pago
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">
                  Pendiente:
                  <span
                    id="valor_pendiente"
                    style={{
                      fontSize: "20px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                  >
                    {(parseFloat(totalFactura) - calcularTotalPagado()).toFixed(
                      2
                    )}
                  </span>
                </h4>

                <table
                  id="tb_formapago"
                  width="30%"
                  style={{ fontSize: "13px" }}
                  class="table _table-condensed table-bordered-content table-hover table-hover-border"
                >
                  <thead class="upper br-sm br-white">
                    <tr class="bg-grey-l">
                      <th>#</th>
                      <th>Metodo</th>
                      <th>Banco</th>
                      <th>Tarjeta</th>
                      <th>Numero </th>
                      <th align="center">Valor</th>
                      <th align="center"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {pagos.map((pago, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{pago.metodo}</td>
                        <td>{pago.banco || "-"}</td>
                        <td>{pago.tarjeta || "-"}</td>
                        <td>{pago.numero || "-"}</td>
                        <td align="center">{pago.valor.toFixed(2)}</td>
                        <td align="center">
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => eliminarPago(index)}
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  <tfoot>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      <div align="right">
                        <strong>Total Pagado:</strong>
                      </div>
                    </th>
                    <th style={{ color: "red" }}>
                      <div align="center">
                        <span id="valor_pagado" style={{ color: "green" }}>
                          {calcularTotalPagado().toFixed(2)}
                        </span>
                      </div>
                    </th>
                  </tfoot>

                  <tbody></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div align="center" class="mt-4">
          <button
            onclick="insertFactura()"
            class="btn btn-success w-lg waves-effect waves-light btnSaveInfo"
            style={{ width: "24%" }}
            disabled={!isFacturaButtonEnabled()}
          >
            Ingresar Factura
          </button>
        </div>

        <Offcanvas
          style={{
            width: "50%",
            zIndex: 9999,
          }}
          placement="end"
          show={showPaciente}
          onHide={handleClosePaciente}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Crear Nuevo Paciente</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <PacienteCreacion
              handleClosePaciente={handleClosePaciente}
              showPaciente={showPaciente}
              idclinica={GetTokenDecoded().idclinica}
              pacienteEdit={pacienteEdit}
            />
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          show={showProcedimientoModal}
          onHide={() => setShowProcedimientoModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Agregar Procedimiento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group col-md-12 mb-3">
                <label htmlFor="i_c_procedimientos" className="text-uppercase">
                  Procedimiento
                </label>
                <select
                  className="form-control"
                  id="i_c_procedimientos"
                  name="idExamenTipo"
                  onChange={handleProcedimientoChange}
                  value={nuevoProcedimiento.idExamenTipo}
                >
                  <option value="">Seleccione un procedimiento</option>
                  {procedimientosDisponibles.map((proc) => (
                    <option key={proc.idExamenTipo} value={proc.idExamenTipo}>
                      {proc.ExamenTipo.descripcion}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-md-12 mb-3">
                <label htmlFor="i_c_proc_precio" className="text-uppercase">
                  Precio
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="i_c_proc_precio"
                  name="precio"
                  value={nuevoProcedimiento.precio}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group col-md-12 mb-3">
                <label htmlFor="i_c_coment_proce" className="text-uppercase">
                  Comentarios
                </label>
                <textarea
                  className="form-control"
                  rows="2"
                  id="i_c_coment_proce"
                  name="comentarios"
                  value={nuevoProcedimiento.comentarios}
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowProcedimientoModal(false)}
            >
              Cerrar
            </Button>
            <Button variant="primary" onClick={guardarNuevoProcedimiento}>
              Guardar Procedimiento
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
