import { BmHttp } from "../utils/Global";


export const CreaDoctor = async (doctor) => {
    try {
        if(doctor.iddoctor){
            return await EditarDoctor(doctor);
        }else{
            const { data } = await BmHttp().post('doctors', doctor);
            return data.success;
        }
    } catch (error) {
        console.error(error);
    }
}

export const ListarDoctores = async () => {
    try {
        const { data } = await BmHttp().get('doctors_all');
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const ListarDoctorClinica = async (idclinica) => {
    try {
        const { data } = await BmHttp().get('doctors_clinica/'+idclinica);
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const EditarDoctor = async (doctor) => {
    try {
        const { data } = await BmHttp().put('doctors/'+doctor.iddoctor, doctor);
        return data.success;
    } catch (error) {
        console.error(error);
    }
}

export const ControllerListarCitasDoctor = async (iddoctor, fecha) => {
    try {
        const { data } = await BmHttp().post('doctores_cita', {iddoctor, fecha_inicio:fecha});
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const ControllerEliminarDoctor = async (iddoctor) => {
    try {
        console.log(iddoctor);
        const { data } = await BmHttp().delete('doctors_del/'+iddoctor);
        return data.success;
    } catch (error) {
        console.error(error);
    }
}