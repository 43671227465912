import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { BmHttp } from "../../../utils/Global";
import {
  ListCategoriaExamen,
  ListExamenCategoriaID,
  ListTipoEstudio,
} from "../../../Service/Service.categoriaExamen";
import { ControllerCitaPaciente } from "../../../Service/Service.citas";
import {
  ControllerEliminaReceta,
  Controllerenfermendad,
  ControllerReceta,
} from "../../../Service/Service.consulta";
import { ControllerEliminarDiagnosticoPaciente } from "../../../Service/Service.diagnostico";
import ModalPaciente from "../../Modals/Paciente/ModalPaciente";
import ModalReceta from "../../Modals/Receta/ModalReceta";
import Modalimagenes from "../../ModeGeneral/ModalImagenes/Modalimagenes";
import ModalLaboratorio from "../../ModeGeneral/ModalLaboratorio/ModalLaboratorio";

import ModalProcedimiento from "../../Modals/Procedimiento/ModalProcedimiento";

import TableExamen from "../../Table/TableExamen";

export default function IngresoConsulta(props) {
  const [examenes, setExamenes] = useState([]);
  const [examenPorCategoria, setExamenPorCategoria] = useState([]);
  const [tipoEstudios, setTipoEstudios] = useState([]);

  const historial = useSelector(
    (state) => state.historialPacienteRedux.historial
  );

  const [procedimientos, setProcedimientos] = useState([]);

  const [ingresoConsulta, setIngresoConsulta] = useState({
    idcita: 0,
    motivo: "",
    fecha_inicio: "",
    hora_fin: "",
    hora_inicio: "",
    pacientes: {
      idpaciente: 0,
      nombres_completos: "",
      clinicas: {
        idclinica: 0,
        nombre: "",
      },
    },
    estado_cita: {
      idestado_cita: 0,
      estado: "",
    },
    tipo_cita: {
      idtipo_cita: 0,
      tipo: "",
    },
    doctores: {
      iddoctor: 0,
      prefijo: "",
      nombre: "",
    },
    signosvitales: [
      {
        temperatura: "",
        pulso: "",
        frecuencia_respiratoria: "",
        saturacion_oxigeno: "",
        peso: "",
        talla: "",
        imc: "",
      },
    ],
    enfermedad_actual_cita: [
      {
        idenfermedad_actual_cita: 0,
        enfermedad_actual: "",
      },
    ],
    receta_cita: [
      {
        idreceta: 0,
        medicamentos: "",
        indicaciones: "",
        posologia: "",
        cantidad: "",
        dosis: "",
      },
    ],
    diagnostico_cita_paciente: [
      {
        iddiagnostico_cita_paciente: 0,
        comentario: "",
        diagnostico: {
          id: 0,
          codigo: "",
          nombre: "",
        },
        typo_diagnostico: "",
      },
    ],
  });

  const [openModales, setOpenModales] = useState({
    imagenes: false,
    laboratorio: false,
    histopatologia: false,
    interconsulta: false,
    derivacion: false,
    modalPaciente: false,
    modalReceta: false,
    modalProcedimiento: false,
  });

  const fetchCitaPaciente = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const idpaciente = urlParams.get("idpaciente");
      const idcita = urlParams.get("idcita");

      if (!idpaciente || !idcita) {
        console.error("No se encontró idpaciente o idcita en la URL");
        return;
      }

      const response = await BmHttp().get(
        `/get_citas_paciente?idpaciente=${idpaciente}&idcita=${idcita}`
      );
      if (response.data.success) {
        const procedimientosCita =
          response.data.data.procedimiento_cita_paciente || [];
        setProcedimientos(procedimientosCita);
        // Aquí puedes actualizar otros estados si es necesario
      } else {
        console.error(
          "Error al obtener los datos de la cita:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error al obtener los datos de la cita:", error);
    }
  };

  const [IdCategoriaExamen, setIdCategoriaExamen] = useState();

  const CloseModelAll = () => {
    setOpenModales({
      imagenes: false,
      laboratorio: false,
      histopatologia: false,
      interconsulta: false,
      derivacion: false,
      modalPaciente: false,
    });
  };

  const closeModalBefore = async () => {
    CloseModelAll();
    await ListaCitaPaciente();
  };

  const ListaCitaPaciente = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    let id_paciente = urlParams.get("idpaciente");
    let id_cita = urlParams.get("idcita");
    if (id_paciente === null)
      return console.log("No se encontro el id del paciente");
    if (id_cita === null) return console.log("No se encontro el id de la cita");
    const ps = await ControllerCitaPaciente(id_paciente, id_cita);
    if (ps && ps.success) {
      setIngresoConsulta(ps.data);
    }
  };

  const ingEnfermedad = async () => {
    const res = await Controllerenfermendad({
      idenfermedad_actual_cita:
        ingresoConsulta.enfermedad_actual_cita[0].idenfermedad_actual_cita,
      enfermedad_actual:
        ingresoConsulta.enfermedad_actual_cita[0].enfermedad_actual,
      idcita: ingresoConsulta.idcita,
      idpaciente: ingresoConsulta.pacientes.idpaciente,
      idclinica: ingresoConsulta.pacientes.clinicas.idclinica,
    });
    if (res && res.success) {
      console.log(res);
      await ListaCitaPaciente();
    }
  };

  const ingReceta = async () => {
    const res = await ControllerReceta({
      idreceta: ingresoConsulta.receta_cita[0].idreceta,
      medicamentos: ingresoConsulta.receta_cita[0].medicamentos,
      indicaciones: ingresoConsulta.receta_cita[0].indicaciones,
      idcita: ingresoConsulta.idcita,
      idpaciente: ingresoConsulta.pacientes.idpaciente,
      idclinica: ingresoConsulta.pacientes.clinicas.idclinica,
      iddoctor: ingresoConsulta.doctores.iddoctor,
    });
    if (res && res.success) {
      console.log(res);
      await ListaCitaPaciente();
    }
  };

  const deletReceta = async (idreceta) => {
    const result = await ControllerEliminaReceta(idreceta);
    if (result && result.success) {
      Swal.fire({
        icon: "success",
        title: "Receta eliminada",
        showConfirmButton: false,
        timer: 1500,
      });
      await ListaCitaPaciente();
    }
  };

  const ingDiagnosticoPacienteCitaEliminar = async (id) => {
    const result = await ControllerEliminarDiagnosticoPaciente(id);
    if (result && result.success) {
      console.log(result);
      await ListaCitaPaciente();
    }
  };

  const OpenSwichtFormulario = async (form) => {
    const responseExamenes = await ListExamenCategoriaID(
      form.IdCategoriaExamen
    );

    setIdCategoriaExamen(form.IdCategoriaExamen);

    const responseTypeStudy = await ListTipoEstudio(form.IdCategoriaExamen);

    if (responseExamenes.success) {
      setExamenPorCategoria(responseExamenes.data);
    }

    if (responseTypeStudy.success) {
      let tiposEstudio = responseTypeStudy.data;
      tiposEstudio = tiposEstudio.map((item) => {
        item.disabled = false;
        item.checked = false;
        return item;
      });

      setTipoEstudios(tiposEstudio);
    }

    if (form.Tipo === "LABORATORIO") {
      CloseModelAll();
      setOpenModales({ ...openModales, laboratorio: true });
    } else if (form.Tipo === "IMAGENES") {
      CloseModelAll();
      setOpenModales({ ...openModales, imagenes: true });
    }
  };

  const modalAux = (modal) => {
    CloseModelAll();

    if (modal === "paciente") {
      setOpenModales({ ...openModales, modalPaciente: true });
    } else if (modal === "receta") {
      setOpenModales({ ...openModales, modalReceta: true });
    } else if (modal === "procedimiento") {
      setOpenModales({ ...openModales, modalProcedimiento: true });
    }
  };

  const fetchProcedimientos = async () => {
    try {
      const response = await BmHttp().get("/procedimiento_precios");
      if (response.data.success) {
        setProcedimientos(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching procedimientos:", error);
    }
  };

  useEffect(() => {
    (async () => {
      const path = window.location.pathname;
      console.log("Path: ", path);
      console.log("Historial: ", historial);

      if (path === "/paciente_historial") {
        const urlParams = new URLSearchParams(window.location.search);
        let id_paciente = urlParams.get("idpaciente");
        let id_cita = urlParams.get("idcita");
        if (id_paciente === null)
          return console.log("No se encontro el id del paciente");
        if (id_cita === null)
          return console.log("No se encontro el id de la cita");
        await ListaCitaPaciente();

        const forms = await ListCategoriaExamen();
        if (forms.data) {
          setExamenes(forms.data);
        }
      }
    })();
    fetchProcedimientos();
    fetchCitaPaciente();
  }, [historial]);

  const handleProcedimientoAdded = () => {
    // fetchProcedimientos();
    fetchCitaPaciente();
  };

  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <div
            className="nav flex-column nav-pills"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <a
              className="nav-link mb-2 active"
              id="v-pills-profile-tab"
              data-bs-toggle="pill"
              href="#v-pills-profile"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
              style={{ fontSize: "12px" }}
            >
              CONSULTA
            </a>
            <a
              className="nav-link mb-2"
              id="v-pills-home-tab"
              data-bs-toggle="pill"
              href="#v-pills-home"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
              style={{ fontSize: "12px" }}
            >
              SIGNOS VITALES
            </a>
            <a
              className="nav-link mb-2"
              id="v-pills-messages-tab"
              data-bs-toggle="pill"
              href="#v-pills-messages"
              role="tab"
              aria-controls="v-pills-messages"
              aria-selected="false"
              style={{ fontSize: "12px" }}
            >
              ENFERMEDAD ACTUAL
            </a>
            <a
              className="nav-link"
              id="v-pills-settings-tab"
              data-bs-toggle="pill"
              href="#v-pills-settings"
              role="tab"
              aria-controls="v-pills-settings"
              aria-selected="false"
              style={{ fontSize: "12px" }}
            >
              DIAGNOSTICOS
            </a>
            <a
              className="nav-link"
              id="v-pills-procedimientos-tab"
              data-bs-toggle="pill"
              href="#v-pills-procedimientos"
              role="tab"
              aria-controls="v-pills-procedimientos"
              aria-selected="false"
              style={{ fontSize: "12px" }}
            >
              PROCEDIMIENTOS
            </a>
            <a
              className="nav-link"
              id="v-pills-medicamento-tab"
              data-bs-toggle="pill"
              href="#v-pills-medicamento"
              role="tab"
              aria-controls="v-pills-medicamento"
              aria-selected="false"
              style={{ fontSize: "12px" }}
            >
              RECETAS
            </a>
            <a
              className="nav-link"
              id="v-pills-examenes-tab"
              data-bs-toggle="pill"
              href="#v-pills-examenes"
              role="tab"
              aria-controls="v-pills-examenes"
              aria-selected="false"
              style={{ fontSize: "12px" }}
            >
              EXAMENES
            </a>
          </div>
        </div>
        <div className="col-md-10">
          <div
            className="tab-content text-muted mt-4 mt-md-0"
            id="v-pills-tabContent"
          >
            <div
              className="tab-pane fade show active"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              <div className="form-group col-md-12 mb-3">
                <label for="inputEmail4" className="text-uppercase">
                  Clinica
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="i_c_clinica"
                  name="i_c_clinica"
                  value={ingresoConsulta.pacientes.clinicas.nombre}
                />
              </div>

              <div className="form-group col-md-12 mb-3">
                <label for="inputEmail4" className="text-uppercase">
                  Fecha Consulta
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="i_c_fecha"
                  name="i_c_fecha"
                  value={ingresoConsulta.fecha_inicio}
                />
              </div>

              <div className="form-group col-md-12 mb-3">
                <label for="inputEmail4" className="text-uppercase">
                  Doctor
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="i_c_doctor"
                  name="i_c_doctor"
                  value={
                    ingresoConsulta.doctores.prefijo +
                    " " +
                    ingresoConsulta.doctores.nombre
                  }
                />
              </div>

              <div className="form-group col-md-12 mb-3">
                <label for="inputEmail4" className="text-uppercase">
                  Motivo Consulta
                </label>
                <textarea
                  className="form-control"
                  rows="3"
                  id="i_c_motivo"
                  name="i_c_motivo"
                  value={ingresoConsulta.motivo}
                >
                  {" "}
                </textarea>
              </div>

              <div className="text-center  mt-3">
                <button
                  className="btn btn-primary waves-effect waves-light text-uppercase btnSaveInfo"
                  style={{ width: "30%" }}
                  type="button"
                  onclick="ingresoConsulta()"
                >
                  Actualizar Cita
                </button>
              </div>
            </div>

            <div
              className="tab-pane fade "
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <div className="row">
                <p>
                  <div className="text-star card-subtitle text-muted">
                    INGRESO DE SIGNOS VITALES
                  </div>
                </p>
                <div className="col-lg-6">
                  <div>
                    <div className="mb-3">
                      <label for="example-search-input" className="form-label">
                        Temperatura C°
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="i_c_temperatura"
                        name="i_c_temperatura"
                        value={
                          ingresoConsulta.signosvitales.length > 0
                            ? ingresoConsulta.signosvitales[0].temperatura
                            : ""
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label for="example-search-input" className="form-label">
                        Pulso/min:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="i_c_pulso"
                        name="i_c_pulso"
                        value={
                          ingresoConsulta.signosvitales.length > 0
                            ? ingresoConsulta.signosvitales[0].pulso
                            : ""
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label for="example-email-input" className="form-label">
                        Peso/kg
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="i_c_peso"
                        name="i_c_peso"
                        value={
                          ingresoConsulta.signosvitales.length > 0
                            ? ingresoConsulta.signosvitales[0].peso
                            : ""
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label for="example-url-input" className="form-label">
                        Sat. Oxigeno{" "}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="i_c_oxigeno"
                        name="i_c_oxigeno"
                        value={
                          ingresoConsulta.signosvitales.length > 0
                            ? ingresoConsulta.signosvitales[0]
                                .saturacion_oxigeno
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="mt-3 mt-lg-0">
                    <div className="mb-3">
                      <label for="example-month-input" className="form-label">
                        Presión Arterial
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="i_c_presion"
                        name="i_c_presion"
                        value={
                          ingresoConsulta.signosvitales.length > 0
                            ? ingresoConsulta.signosvitales[0]
                                .perimetro_cefalico
                            : ""
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label for="example-month-input" className="form-label">
                        Fr. Respiratoria{" "}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="i_c_respiratoria"
                        name="i_c_respiratoria"
                        value={
                          ingresoConsulta.signosvitales.length > 0
                            ? ingresoConsulta.signosvitales[0]
                                .frecuencia_respiratoria
                            : ""
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label for="example-week-input" className="form-label">
                        Talla/cm:
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="i_c_talla"
                        name="i_c_talla"
                        value={
                          ingresoConsulta.signosvitales.length > 0
                            ? ingresoConsulta.signosvitales[0].talla
                            : ""
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label for="example-time-input" className="form-label">
                        IMC
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="i_c_imc"
                        name="i_c_imc"
                        value={
                          ingresoConsulta.signosvitales.length > 0
                            ? ingresoConsulta.signosvitales[0].imc
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center  mt-3">
                <button
                  className="btn btn-primary waves-effect waves-light text-uppercase btnSaveInfo"
                  style={{ width: "30%" }}
                  type="button"
                  onclick="ingresoConsulta()"
                >
                  Actualizar Signos Vitales
                </button>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="v-pills-messages"
              role="tabpanel"
              aria-labelledby="v-pills-messages-tab"
            >
              <p>
                <div className="text-star card-subtitle text-muted">
                  INGRESO DE ENFERMEDAD ACTUAL
                </div>
              </p>
              <textarea
                className="form-control"
                rows="3"
                id="i_c_enfermedad"
                name="i_c_enfermedad"
                value={
                  ingresoConsulta.enfermedad_actual_cita.length > 0
                    ? ingresoConsulta.enfermedad_actual_cita[0]
                        .enfermedad_actual
                    : ""
                }
                onChange={(e) =>
                  setIngresoConsulta({
                    ...ingresoConsulta,
                    enfermedad_actual_cita: [
                      {
                        ...ingresoConsulta.enfermedad_actual_cita[0],
                        enfermedad_actual: e.target.value,
                      },
                    ],
                  })
                }
              >
                {" "}
              </textarea>
              <div className="text-center  mt-3">
                <button
                  className="btn btn-primary waves-effect waves-light text-uppercase btnSaveInfo"
                  style={{ width: "30%" }}
                  onClick={() => ingEnfermedad()}
                  type="button"
                >
                  Actualizar Enfermedad
                </button>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="v-pills-settings"
              role="tabpanel"
              aria-labelledby="v-pills-settings-tab"
            >
              <div className="row gap-10">
                <div className="col-12 mb-2 text-end">
                  <button
                    className="btn btn-primary waves-effect waves-light text-uppercase btnSaveInfo"
                    style={{ width: "30%" }}
                    type="button"
                    onClick={() => modalAux("paciente")}
                  >
                    <i class="bx bx-plus me-1 mt-1"></i>
                    Agregar diagnóstico
                  </button>
                </div>

                <div className="col-12">
                  <table id="tb_i_diagnostico" className="table mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Codigo Cie</th>
                        <th>Tipo diagnóstico</th>
                        <th>Observaciones</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ingresoConsulta.diagnostico_cita_paciente.map(
                        (d, index) => (
                          <tr key={index}>
                            <td>{d.diagnostico.codigo}</td>
                            <td>
                              <span className="d-blokc text-capitalize">
                                {d.typo_diagnostico}
                              </span>
                            </td>
                            <td>{d.comentario}</td>
                            <td>
                              <button
                                className="btn btn-sm btn-danger px-2"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Eliminar diagnóstico"
                                onClick={() =>
                                  ingDiagnosticoPacienteCitaEliminar(
                                    d.iddiagnostico_cita_paciente
                                  )
                                }
                              >
                                <i class="bx bxs-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Procedimientos */}
            <div
              className="tab-pane fade"
              id="v-pills-procedimientos"
              role="tabpanel"
              aria-labelledby="v-pills-procedimientos-tab"
            >
              <div className="row">
                <div className="col-12 mb-2 text-end">
                  <button
                    className="btn btn-primary waves-effect waves-light text-uppercase btnSaveInfo"
                    style={{ width: "30%" }}
                    type="button"
                    onClick={() => modalAux("procedimiento")}
                  >
                    <i className="bx bx-plus me-1 mt-1"></i>
                    Agregar procedimiento
                  </button>
                </div>
              </div>

              <div className="d-flex justify-content-between mb-3">
                <div className="">
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeigh: "bold",
                      color: "red",
                    }}
                  >
                    LOS PROCEDIMIENTOS INGRESADOS SERIAN ENVIADOS A FACTURACION
                  </span>
                </div>
              </div>

              <table id="tb_i_procedimientos" className="table mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Procedimiento</th>
                    <th>Precio</th>
                    <th>Cantidad</th>
                    <th>Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  {procedimientos.map((proc) => (
                    <tr key={proc.idprocedimiento_cita_paciente}>
                      <td>
                        {proc.ExamenTipo.codigo} - {proc.ExamenTipo.descripcion}
                      </td>
                      <td>{proc.precio}</td>
                      <td>{proc.cantidad}</td>
                      <td>{proc.observaciones}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Receta medica */}
            <div
              className="tab-pane fade"
              id="v-pills-medicamento"
              role="tabpanel"
              aria-labelledby="v-pills-medicamento-tab"
            >
              <div className="row">
                <div className="col-12 mb-2 text-end">
                  <button
                    className="btn btn-primary waves-effect waves-light text-uppercase btnSaveInfo"
                    style={{ width: "30%" }}
                    type="button"
                    onClick={() => modalAux("receta")}
                  >
                    <i class="bx bx-plus me-1 mt-1"></i>
                    Agregar receta
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <table className="table mt-4">
                    <thead className="table-light">
                      <tr>
                        <th>Codigo</th>
                        <th>Medicacion</th>
                        <th>Posologia</th>
                        <th>Indicaciones</th>
                        <th>Cantidad Solicitada</th>
                        <th>Dosis</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ingresoConsulta.receta_cita.map((receta, index) => (
                        <tr key={index}>
                          <td>{receta.idreceta}</td>
                          <td>{receta.medicamentos}</td>
                          <td>{receta.posologia}</td>
                          <td>{receta.indicaciones}</td>
                          <td>{receta.cantidad}</td>
                          <td>{receta.dosis}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-danger px-2"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Eliminar receta"
                              onClick={() => deletReceta(receta.idreceta)}
                            >
                              <i class="bx bxs-trash-alt"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* EXAMENES */}
            <div
              className="tab-pane fade"
              id="v-pills-examenes"
              role="tabpanel"
              aria-labelledby="v-pills-examenes-tab"
            >
              <p>
                <div className="text-star card-subtitle text-muted">
                  INGRESO DE EXAMENES
                </div>
              </p>

              <div className="row">
                {examenes.map((f, index) => (
                  <div className="col-sm-4 col-lg-2" key={index}>
                    <div
                      className="card cardHover"
                      style={{
                        borderRadius: "5px",
                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                        height: "100%",
                        maxHeight: "100%",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => OpenSwichtFormulario(f)}
                      >
                        <h5 className="card-title">{f.Descripcion}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <Tabs
                defaultActiveKey="laboratorio"
                id="uncontrolled-tab-example"
                className="mb-3 mt-4"
              >
                <Tab eventKey="laboratorio" title="Laboratorio">
                  <TableExamen
                    examenes={ingresoConsulta.examen_cita}
                    type={"laboratorio"}
                  />
                </Tab>

                <Tab eventKey="imagen" title="Imagenes">
                  <TableExamen
                    examenes={ingresoConsulta.examen_imagen_orden}
                    type={"imagenes"}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modalimagenes
        show={openModales.imagenes}
        onHide={CloseModelAll}
        tiposEstudio={tipoEstudios}
        setTipoEstudios={setTipoEstudios}
        examenes={examenPorCategoria}
        cita={ingresoConsulta}
        ListaCitaPaciente={ListaCitaPaciente}
        IdCategoriaExamen={IdCategoriaExamen}
      />

      {/* Modal para exámen laboratorio */}
      <ModalLaboratorio
        show={openModales.laboratorio}
        onHide={CloseModelAll}
        tiposEstudio={tipoEstudios}
        setTipoEstudios={setTipoEstudios}
        examenes={examenPorCategoria}
        cita={ingresoConsulta}
      />

      {/* Modal para el ingreso de diganóstico del paciente */}
      <ModalPaciente
        show={openModales.modalPaciente}
        onHide={closeModalBefore}
        cita={ingresoConsulta}
      />

      {/* Modal para el ingreso de recetas de un paciente */}
      <ModalReceta
        show={openModales.modalReceta}
        onHide={closeModalBefore}
        cita={ingresoConsulta}
      />

      <ModalProcedimiento
        show={openModales.modalProcedimiento}
        onHide={closeModalBefore}
        cita={ingresoConsulta}
        onProcedimientoAdded={handleProcedimientoAdded}
      />
    </>
  );
}
