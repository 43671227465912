import moment from 'moment/moment';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ListarDirectorios } from '../../../Service/Service.directorios';
import { TipoGenero } from '../../../Service/Service.genero';
import { RegistrarPaciente } from '../../../Service/Service.paciente';
import { ListarTipoDocumento } from '../../../Service/Service.tipoDocumento';
import { ListarTiposSeguros } from '../../../Service/Service.tipoSerguro';
import { SubirMedia } from '../../../Service/ServiceExternal';
import { GetTokenDecoded } from '../../../utils/storeUsuario';
import Alert from '../../Alert/Alert';
import SelectSearchble from '../../SelectSeachble/SelectSearchble';

export default function PacienteCreacion({handleClosePaciente, showPaciente, idclinica, pacienteEdit}) {
    const refdiv = useRef(null);
    const [statusCedula, setStatusCedula] = useState(true)
    const [tipoDocumentos, setTipoDocumentos] = useState([])
    const [tipoSeguros, setTipoSeguros] = useState([])
    const [tipogenero, setTipoGenero] = useState([])
    const [send, setSend] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)

    const {register, handleSubmit, formState:{errors}, setValue} = useForm();
    const [alertType, setAlertType] = useState('')
    const [directorios, setDirectorios] = useState([])

    const handleChange = selectedOption => {
      setSelectedOption(selectedOption);
    }

    const onSubmit = handleSubmit(async(data) => {
      let multimedia = []
      setSend(true)
      const processItem = async (item) => {
        if (data[item.ruta] && data[item.ruta][0]) {
          const url = await SubirMedia(data[item.ruta][0], null, null, GetTokenDecoded().idclinica, item.ruta);
          return { label: item.label, ruta: item.ruta, url: url };
        } else {
          return { label: item.label, ruta: item.ruta, url: item.url ? item.url : '' };
        }
      };
      if (pacienteEdit.multimedia && pacienteEdit.multimedia.length > 0) {
        const promises = pacienteEdit.multimedia.map(async (item) => {
          console.log("Editar: ", data[item.ruta]);
          return await processItem(item);
        });
        multimedia = await Promise.all(promises);
        console.log("1multimedia: ", multimedia);
      } else {
        const promises = directorios.map(async (item) => {
          console.log("Nuevo: ", data[item.ruta]);
          return await processItem(item);
        });
        multimedia = await Promise.all(promises);
        console.log("2multimedia: ", multimedia);
      }

      data['edad'] = document.getElementById('edad').value
      data['multimedia'] = multimedia
      console.log("data['multimedia']: ", data['multimedia'])
      data['idclinica'] = GetTokenDecoded().idclinica
      const resul = await RegistrarPaciente(data)
      if(resul.success){
        setAlertType('success')
      }else{
        setAlertType('error')
      }
      setSend(false)
      refdiv.current.scrollIntoView({behavior: 'smooth'})
      setTimeout(()=>{
        setAlertType('')
        handleClosePaciente()
      },2500)
    })

    const ChangeDocumento = (e) => {
      if(e.target.name === 'tipo_documento'){
        setStatusCedula(false)
      }
    }

    useEffect(() => {
      if(showPaciente){
        (async () => {
          const data = await ListarTipoDocumento()
          if(data.success){
            setTipoDocumentos(data.data)
          }
          const dataSeguro = await ListarTiposSeguros(idclinica)
          if(dataSeguro.success){
            setTipoSeguros(dataSeguro.data)
          }
          const dataGenero = await TipoGenero()
          if(dataGenero.success){
            setTipoGenero(dataGenero.data)
          }
          const dataDirectorios = await ListarDirectorios(idclinica, null)
          if(dataDirectorios.success){
            setDirectorios(dataDirectorios.data)
          }
          // validar si es edicion
        })()
      }
      
    }, [])

    // setiar los patores para editar
    useEffect(() => {
      if(pacienteEdit){
        setValue('tipo_documento', {value: pacienteEdit.idtipodocumento, label: pacienteEdit.tipo_documento})
        setValue('cedula', pacienteEdit.cedula)
        setValue('nombres_completos', pacienteEdit.nombres_completos)
        setValue('genero', {value: pacienteEdit.genero, label: pacienteEdit.genero})
        setValue('fecha_nac', pacienteEdit.fecha_nac)
        setValue('edad', pacienteEdit.edad)
        setValue('estado_civil', pacienteEdit.estado_civil)
        setValue('hc_msp', pacienteEdit.hc_msp)
        setValue('tipo_seguro', pacienteEdit.tipo_seguro)
        setValue('direccion', pacienteEdit.direccion)
        setValue('lugar_nac', pacienteEdit.lugar_nac)
        setValue('email', pacienteEdit.email)
        setValue('celular', pacienteEdit.celular)
        setValue('whatsapp', pacienteEdit.whatsapp)
        setValue('telefono_casa', pacienteEdit.telefono_casa)
        setValue('telefono_oficina', pacienteEdit.telefono_oficina)
        setValue('ocupacion', pacienteEdit.ocupacion)
        setValue('lugar_trabajo', pacienteEdit.lugar_trabajo)
        setValue('nombre_familiar', pacienteEdit.nombre_familiar)
        setValue('telefono_familiar', pacienteEdit.telefono_familiar)
        setValue('proviene', pacienteEdit.proviene)
        setValue('observacion', pacienteEdit.observacion)
        setValue('multimedia', pacienteEdit.multimedia)
        setValue('idpaciente', pacienteEdit.idpaciente)

      }
    }, [pacienteEdit])

  return (
    <section>
        <Alert
          tipo_alert={alertType}
          type={alertType}
        />
      <div className="row">
      <div
        ref={refdiv}  
      />
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form id="form-clinica-create" onSubmit={onSubmit}>
              
              <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Tipo de Documento</label>
                  <div className="col-sm-9">
                      <select className="form-select" name="tipo_documento" id="tipo_documento"
                      {...register("tipo_documento", {
                        required: true
                      })}
                      onChange={(e)=>ChangeDocumento(e)}
                      >
                        <option value="">Seleccione</option>
                        {
                          tipoDocumentos.map((item, index) => (
                            <option value={item.idtipodocumento}>{item.tipo}</option>
                          ))
                        }
                      </select>
                      <Alert
                        tipo_alert="danger"
                        type={errors.tipo_documento?.type}
                      />
                  </div>
              </div>


              <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Identificacion*</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name='cedula' disabled={statusCedula}
                    {...register("cedula", {
                      required: true,
                    })} />
                    <Alert
                      tipo_alert="danger"
                      type={errors.cedula?.type}
                    />

                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Nombres Completos*</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" {...register("nombres_completos", {
                      required: true,
                      minLength: 5,
                      maxLength: 160
                    })}
                        placeholder='Primero apellido y luego nombres'
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.nombre_completos?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Genero*</label>
                  <div className="col-sm-9">
                    <select className="form-select" name="genero" id="genero"
                    {...register("genero", {
                      required: true
                    })}
                    >
                      <option value="">Seleccione</option>
                      {
                        tipogenero.map((item, index) => (
                          <option value={item.tipo}>{item.genero}</option>
                        ))
                      }
                    </select>
                    <Alert
                      tipo_alert="danger"
                      type={errors.genero?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Fecha de Nacimiento*</label>
                  <div className="col-sm-9">
                    <input type="date" className="form-control" {...register("fecha_nac", {
                      required: true
                    })}
                      onChange={(e)=>{
                        if(e.target.value){
                          console.log(e.target.value)
                          let fecha = moment(e.target.value)
                          let hoy = moment()
                          let edad = hoy.diff(fecha, 'years')
                          document.getElementById('edad').value = edad
                        }
                      }}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.fceha_nac?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Edad*</label>
                  <div className="col-sm-9">
                    <input type="number" className="form-control" id='edad' disabled
                    {...register("edad", {
                      required: false
                    })}  />
                    <Alert
                      tipo_alert="danger"
                      type={errors.edad?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Estado Civil</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" {...register("estado_civil", {
                      required: true
                    })}  />
                    <Alert
                        tipo_alert="danger"
                        type={errors.estado_civil?.type}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">N. Historia Clinica MSP</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" {...register("hc_msp", {
                        required: false
                        })}  />
                    <Alert
                      tipo_alert="danger"
                      type={errors.hc_msp?.type}
                    />
                  </div>
                </div>


                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Tipo de Seguro</label>
                  <div className="col-sm-9">
                      <select className="form-select" name="tipo_seguro" id="tipo_seguro"
                      {...register("tipo_seguro", {
                        required: false
                      })}
                      >
                        <option value="">Seleccione</option>
                        {
                          tipoSeguros.map((item, index) => (
                            <option value={item.idtiposeguro}>{item.tipo}</option>
                          ))
                        }
                      </select>
                      <Alert
                        tipo_alert="danger"
                        type={errors.tipo_seguro?.type}
                      />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Direccion*</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" 
                    {...register("direccion",{
                      required: true,
                      minLength: 3,
                      maxLength: 100,
                    })}/>
                    <Alert
                      tipo_alert="danger"
                      type={errors.direccion?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Lugar de Nacimiento*</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" {...register("lugar_nac",{
                      required: true,
                      minLength: 8,
                      maxLength: 160
                    })} />
                    <Alert
                      tipo_alert="danger"
                      type={errors.lugar_nac?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Email</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="telefono-clinica"
                      {...register("email",{
                        required: false,
                        minLength: 8,
                        maxLength: 160,
                        type: 'text'
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.email?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Celular*</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" {...register("celular",{
                      required: true,
                      minLength: 10,
                      maxLength: 12,
                      type: 'number'
                    })} id="celular" />
                    <Alert
                      tipo_alert="danger"
                      type={errors.celular?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Tiene Whatsapp</label>
                    <div className="col-sm-9 d-flex text-center">
                        <div className="form-check form-switch form-switch-md">
                            <input type="checkbox" className="form-check-input"
                                onClick={(e)=>ChangeDocumento(e)}
                                {...register('whatsapp', {required: false,
                                defaultValue: true,
                                })}
                            />
                        </div>  
                    </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Telefono de casa</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control"
                      {...register("telefono_casa",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.telefono_casa?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Telefono de oficina</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control"
                      {...register("telefono_oficina",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.telefono_oficina?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Ocupacion</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="web" id="web" 
                      {...register("ocupacion",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.ocupacion?.type}
                    />
                  </div>
                </div>


                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Lugar de trabajo</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" 
                      {...register("lugar_trabajo",{
                        required: false,
                        minLength: 5,
                        maxLength: 160,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.lugar_trabajo?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Nombre de familiar</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" 
                      {...register("nombre_familiar",{
                        required: false,
                        minLength: 5,
                        maxLength: 160,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.nombre_familiar?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Telefono familiar</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control"
                      {...register("telefono_familiar",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.telefono_familiar?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Paciente proviene de:</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="tipo" id="tipo" 
                      {...register("proviene",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.proviene?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Observacion:</label>
                  <div className="col-sm-9">
                    <textarea className="form-control"
                        {...register("observacion",{
                            required: false,
                            minLength: 5,
                            maxLength: 100,
                        })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.observacion?.type}
                    />
                  </div>
                </div>

                {/* crear una seccion de multimedia dinamico por directorios donde el clinete selecione el directorio y se adjunte la imagen  */}
                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Multimedia</label>
                  <div className="col-sm-9">
                  <SelectSearchble
                      listCompany={directorios} 
                      handleChange={handleChange} 
                      selectedOption={selectedOption} 
                      placeholder="Seleccione directorios"
                      isMulti={true}
                  />
                  </div>

                </div>
                {
                  selectedOption && selectedOption.map((item, index) => (
                    <div className="row mb-4">
                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">{item.label}</label>
                      <div className="col-sm-9">
                        <input type="file" className="form-control" name={item.ruta} accept='image/*'
                        {...register(item.ruta, {
                          required: false
                        })}
                        />
                      </div>
                    </div>
                  ))
                }

                <div className="text-center">
                  <button  onclick="openModalClinica()" className="btn btn-danger m-1">
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-primary" onClick={(e)=>console.log(e)}>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 btnSppinAjax" 
                    style={{
                      display: send ? 'inline-block' : 'none' 
                    }}></i>
                    Guardar
                  </button>
                </div>

              </form>


            </div>
          </div>
        </div>
      </div>


    </section>
  )
}
