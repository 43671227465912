import { BmHttp } from "../utils/Global";

export const guardarExamenLaboratorioService = async (examen) => {
  try {
    const { data } = await BmHttp().post("examen_registro_cita", examen);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const guardarExamenImagenesService = async (examen) => {
  try {
    const { data } = await BmHttp().post("examen_registro_cita_imagen_orden", examen);
    return data;
  } catch (error) {
    console.error(error);
  }
};
