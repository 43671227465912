export const getParams = () => {
  let url = window.location.search;

  if (url === "") {
    return {};
  }

  url = url.slice(1);

  const urlSplit = url.split("&");
  const params = {};

  urlSplit.forEach((segment) => {
    const [key, value] = segment.split("=");
    params[key] = value;
  });

  return params;
};
