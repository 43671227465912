import { BmHttp } from "../utils/Global";


export const ListarTipoDocumento = async () => {
    try {
        const { data } = await BmHttp().get('tipo-documento');
        return data;
    } catch (error) {
        console.error(error);
    }
}