import { BmHttp } from "../utils/Global"

export const ListarClinicas = async () => {
    try {
        const {data} = await BmHttp().get("listar_clinicas")
        if(data.success){
            return data.data
        }else{
            return null
        }
    } catch (error) {
        console.error(error)
        return null
    }
}

export const CrearClinica = async (clinica) => {
    try {
        const {data} = await BmHttp().post("crearte_nueva_clinica", clinica)
        if(data.success){
            return data.message
        }else{
            return null
        }
    } catch (error) {
        console.error(error)
        return null
    }
}

export const ObtenerClinica = async (id) => {
    try {
        const {data} = await BmHttp().get(`obtener_clinica/${id}`)
        if(data.success){
            return data
        }else{
            return null
        }
    } catch (error) {
        console.error(error)
        return null
    }
}

export const ActualizarClinica = async (clinica) => {
    try {
        const {data} = await BmHttp().put("/actualizar_clinica/"+clinica.idclinica, clinica)
        if(data.success){
            return data.data
        }else{
            return null
        }
    } catch (error) {
        console.error(error)
        return null
    }
}

export const ValidaCampoClinica = async (key, value) => {
    try {
        const {data} = await BmHttp().get(`/valida_campo_clinica/${key}/${value}`)
        if(data.success){
            return data.data
        }else{
            return null
        }
    } catch (error) {
        console.error(error)
        return null
    }
}

export const EliminarClinica = async (idclinica) =>{
    try {
        const { data }= await BmHttp().delete(`eliminar_clinica/${idclinica}`)
        if(data.success){
            return data.success
        }else{
            return null
        }
    } catch (error) {
        console.log(error)
        return null
    }
}