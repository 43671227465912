import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import PacienteCreacion from "../Components/Configuracion/ModalCreacion/PacienteCreacion";
import { EliminarPaciente, ListarPacientes } from "../Service/Service.paciente";
import { GetTokenDecoded } from "../utils/storeUsuario";

export default function Pacientes() {
  const [paciente, setPaciente] = useState([]);
  const [pacienteEdit, setPacienteEdit] = useState({});
  const [showPaciente, setShowPaciente] = useState(false);
  const handleClosePaciente = async () => {
    setShowPaciente(false);
    const pacint = await ListarPacientes(GetTokenDecoded().idclinica);
    if (pacint.success) {
      setPaciente(
        pacint.data.map((p) => ({
          value: p.idpaciente,
          label: p.nombres_completos,
          ...p,
        }))
      );
    }
  };
  const handleEditarPaciente = (paciente) => {
    setPacienteEdit(paciente);
    setShowPaciente(true);
  };

  const handleEliminarPaciente = async (idpaciente) => {
    const pacint = await EliminarPaciente(idpaciente);
    if (pacint.success) {
      const pacint = await ListarPacientes(GetTokenDecoded().idclinica);
      if (pacint.success) {
        setPaciente(
          pacint.data.map((p) => ({
            value: p.idpaciente,
            label: p.nombres_completos,
            ...p,
          }))
        );
      }
    }
  };

  const handleHistorialClinico = (paciente) => {
    // redireccionar a la pagina de paciente_historial?idpaciente=${paciente.idpaciente}
    window.location.href = `/paciente_historial?idpaciente=${paciente.idpaciente}`;
  };

  useEffect(() => {
    (async () => {
      const pacint = await ListarPacientes(GetTokenDecoded().idclinica);
      if (pacint.success) {
        setPaciente(
          pacint.data.map((p) => ({
            value: p.idpaciente,
            label: p.nombres_completos,
            ...p,
          }))
        );
      }
    })();
  }, []);
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Listado de Pacientes</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">Pacientes</li>
                    <li className="breadcrumb-item active">Listado</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-12">
              <div className="d-flex flex-wrap align-items-center justify-content-start gap-2 mb-3">
                <div>
                  <button
                    onClick={() => setShowPaciente(!showPaciente)}
                    className="btn btn-light"
                  >
                    <i className="bx bx-plus me-1"></i>
                    Crear Paciente
                  </button>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="mb-4">
                <table
                  id="tb_pacientes_lts"
                  className="table align-middle datatable dt-responsive table-check nowrap"
                  style={{
                    borderCollapse: "collapse",
                    borderSpacing: "0 2px",
                    width: "100%",
                    fontSize: "14px",
                  }}
                >
                  <thead>
                    <tr>
                      <th scope="col" width="20">
                        #
                      </th>
                      <th className="" scope="col">
                        Nombres
                      </th>
                      <th scope="col">Correo</th>
                      <th scope="col">Contacto</th>
                      <th scope="col">Genero</th>
                      <th style={{ width: "80px;", minWidth: "80px" }}>
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {paciente.map((p, i) => (
                      <tr key={p.idpaciente}>
                        <td>{i + 1}</td>
                        <td>
                          {p.nombres_completos}
                          <p className="text-muted mb-0">
                            <span className="block">Cedula: {p.cedula}</span>
                            <span className="block">
                              Estado Civil: {p.estado_civil}
                            </span>
                          </p>
                        </td>
                        <td className="text-muted mb-0">
                          <p>
                            <span className="block">Correo: {p.email}</span>
                            <span className="block">Telefono: {p.cedular}</span>
                            <span className="block">
                              Telefono familiar: {p.telefono_familiar}
                            </span>
                          </p>
                        </td>
                        <td>{p.direccion}</td>
                        <td>
                          <span
                            className="badge bg-info px-2"
                            title={
                              p.genero === "M"
                                ? "Masculino"
                                : p.genero === "F"
                                ? "Femenino"
                                : "Otro"
                            }
                          >
                            {p.genero}
                          </span>
                        </td>
                        <td>
                          {/* ver historial clinica */}
                          {/* cuando pase el cuerso en el boto que salga una leyenda */}
                          <button
                            className="btn btn-sm btn-soft-info waves-effect waves-light py1"
                            title="Ver Historial Clinico"
                            style={{ marginRight: "10px" }}
                            onClick={() => handleHistorialClinico(p)}
                          >
                            <i className="fas fa-eye font-size-16 align-middle"></i>
                          </button>

                          <button
                            className="btn btn-sm btn-soft-primary waves-effect waves-light ml-2"
                            style={{ marginRight: "10px" }}
                            title="Editar Paciente"
                            onClick={() => handleEditarPaciente(p)}
                          >
                            <i className="fas fa-user-edit font-size-16 align-middle"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-soft-danger waves-effect waves-light"
                            title="Eliminar Paciente"
                            onClick={() => handleEliminarPaciente(p.idpaciente)}
                          >
                            <i className="far fa-trash-alt font-size-16 align-middle"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          id="canvasPacientes"
          className="offcanvas offcanvas-end"
          tabindex="-1"
          aria-labelledby="offcanvasRightLabel"
          style={{ width: "50%" }}
        >
          <div className="offcanvas-header">
            <h5 id="offcanvasRightLabel">Agregar Nuevo Pacientes</h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body"></div>
        </div>
      </div>
      <Offcanvas
        style={{
          width: "50%",
          zIndex: 9999,
        }}
        placement="end"
        show={showPaciente}
        onHide={handleClosePaciente}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Crear Nuevo Paciente</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <PacienteCreacion
            handleClosePaciente={handleClosePaciente}
            showPaciente={showPaciente}
            idclinica={GetTokenDecoded().idclinica}
            pacienteEdit={pacienteEdit}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
