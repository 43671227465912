import React, { useEffect, useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Eliminaruser, Listarusers } from "../../Service/Service.users";
import AdministrativoCreate from './AdministrativoCreate';

export default function Administrativo({tabs}) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const [ping, setPing] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    setPing(true)
  };
  const [users, setUsers] = useState([])
  const [user, setUser] = useState({
    id: 0,
    full_name: "",
    user_name: "",
    user_email: "",
    user_level: "",
    pwd: "",
    banned: 0,
    foto_user: "",
    idclinica: 0,
    idcompany: 0,
    sidebar: false,
    tips: false,
    tour: false,
    users_clinicas: []
  })

  const EditarUsuario = (user) => {
    setUser(user)
    handleShow()
  }

  const EliminarUserClinica = async (id) => {
    setLoading(true)
    console.log(id)
    const el = await Eliminaruser(id)
    if(el.success){
      const res = await Listarusers()
      if(res.success){
        setLoading(false)
        setUsers(res.data)
      }else{
        setMensajeError(res.message)
        setLoading(false)
        setTimeout(() => {
          setMensajeError("")
        }, 3000);
      }
    }else{
      setMensajeError(el.message)
      setLoading(false)
      setTimeout(() => {
        setMensajeError("")
      }, 3000);
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true)
      const res = await Listarusers()
      if(res.success){
        setLoading(false)
        setUsers(res.data)
      }
    })();
  }, [ping, tabs.asesores]);
  
  return (
    <section>
      <div class="row">
        <div class="col-12">

          <div class="page-title-box d-sm-flex align-items-center justify-content-between ">
            <button onClick={handleShow} type="button" class="btn btn-light waves-effect waves-light">
              <i class="bx bx-plus me-1"></i> Crear Personal</button>

            {
              loading ? <div class="spinner-border spin_loading text-primary m-1" role="status" >
                <span class="sr-only">Loading...</span>
              </div> : ""
            }

            {
              mensajeError !== "" ? <div class="alert alert-danger" role="alert">
                {mensajeError}
              </div> : ""
            }
          </div>


          <table id="tb_asesores" class="table dt-responsive table-striped nowrap w-100" style={{ fontSize: '14px' }}>
            <thead>
              <tr>
                <th width="15">#</th>
                <th >Avatar</th>
                <th width="200">Nombr/e</th>
                <th>Email</th>
                <th>Clave</th>
                <th>Perfil</th>
                <th width="50">Opciones</th>
              </tr>
            </thead>


            <tbody>
              {
                users.map((user, index) => (
                  <tr>
                    <td align="center">{index + 1}</td>
                    <td>
                      {
                        user.foto_user === "" ? <img src={`https://ui-avatars.com/api/?name=${user.full_name}&color=7F9CF5&background=EBF4FF`} alt="avatar" class="avatar-xs rounded-circle me-1" />
                          : <img src={user.foto_user} alt="avatar" class="avatar-xs rounded-circle me-1" />
                      }
                    </td>
                    <td>{user.full_name}</td>
                    <td>{user.user_email}</td>
                    <td>{
                      user.clave_user === "" ? "No tiene" : "********"
                      }</td>
                      <td>{user.user_level}</td>
                    <td align="center">
                      <div class="btn-group"><button type="button"
                        class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                        <div class="dropdown-menu dropdownmenu-secondary">
                          <a class="dropdown-item" href="#;" onClick={(e)=>EditarUsuario(user)}>Editar</a>
                          <a class="dropdown-item" href="#;" onClick={(e)=>EliminarUserClinica(user.id)}>Eliminar</a>
                          <div class="dropdown-divider"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>

        </div>
      </div>



      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Personal Administrativo</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AdministrativoCreate 
            user={user}
            setUser={setUser}
            handleClose={handleClose}
            ping={ping}
            setPing={setPing}
          />
        </Offcanvas.Body>
      </Offcanvas>


    </section>
  );
}
