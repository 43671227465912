import React from "react";

export default function ProcOdontologiaTipoCreate() {
  return (
    <section>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <form id="form-create-tipo-procedimiento-odont">


                <div class="row mb-4">
                  <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Nombre Tipo Procedimiento</label>
                  <div class="col-sm-9">
                    <input type="text" class="form-control" id="tipo-procedimiento-nombre" name="tipo-procedimiento-nombre" required />
                  </div>
                </div>




                <div class="row mb-4">
                  <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Aplica </label>
                  <div class="col-sm-9">
                    <select class="form-control" id="tipo-procedimiento-aplica" name="tipo-procedimiento-aplica" required>
                      <option value="Boca">Boca</option>
                      <option value="Dientes">Dientes</option>
                    </select>
                  </div>
                </div>


                <div class="row mb-4">
                  <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Usa Caras </label>
                  <div class="col-sm-9">
                    <input type="checkbox" id="tipo-usa-caras" name="tipo-usa-caras" switch="none" value="1" checked />
                    <label for="usa-caras" data-on-label="On" data-off-label="Off"></label>

                  </div>
                </div>



                <div class="row mb-4">
                  <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Activo</label>
                  <div class="col-sm-9">
                    <input type="checkbox" id="tipo-estado-proced" name="tipo-estado-proced" switch="none" value="1" checked />
                    <label for="tipo-estado-proced" data-on-label="On" data-off-label="Off"></label>

                  </div>
                </div>


                <div class="text-center">
                  <a href="#" onclick="openModalTipoProcOdonto()" class="btn btn-danger btnCloseCanvas">Cancelar</a>
                  <button type="submit" class="btn btn-primary btnSaveInfo">Guardar</button>
                </div>

              </form>
            </div>
          </div>
        </div> 
      </div> 


      <table id="tb_tipo_procedimientos_odont" class="table dt-responsive table-striped nowrap w-100" style={{fontSize: '14px'}}>
        <thead>
          <tr>
            <th width="15">#</th>
            <th width="200">Tipo Procedimiento</th>
            <th>Aplica</th>
            <th>Usa Caras</th>
            <th>Estado</th>
            <th width="50">Opciones</th>
          </tr>
        </thead>

        <tbody>
        </tbody>
      </table>
    </section>
  );
}
