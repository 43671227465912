import { BmHttp } from "../utils/Global";

export const GetEspecialidades = async () => {
    try {
        const { data } = await BmHttp().get('especialidades_all');
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const EspecialidadClinica = async (idclinica) => {
    try {
        const { data } = await BmHttp().get(`especialidades_clinica/${idclinica}`);
        return data
    } catch (error) {
        console.error(error);
    }
}

export const ControllerEliminarEspecialidad = async (idespecialidad) => {
    try {
        const { data } = await BmHttp().delete(`eliminar_especialidad?idespecialidad=${idespecialidad}`);
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const ActivarDesactivarEspecialidad = async (idespecialidad, idclinica, intervalo_tiempo, estado, icono, precio) => {
    try {
        const { data } = await BmHttp().post(`especialidades_clinica`, { idespecialidad, intervalo_tiempo, idclinica, estado, icono, precio });
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const ListarEspecialidadClinicaActivas = async (idclinica) => {
    try {
        const { data } = await BmHttp().get(`especialidades_activas_clinica/${idclinica}`);
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const ListarDoctoresEspecialidadClinica = async (idespecialidad, idclinica) => {
    try {
        const { data } = await BmHttp().get(`especialidades_activas_doctores_clinica/${idespecialidad}/${idclinica}`);
        return data;
    } catch (error) {
        console.error(error);
    }
}