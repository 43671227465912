import { BmHttp } from "../utils/Global";


export const createCitaPaciente = async (cita) => {
    try {
        const { data } = await BmHttp().post('create_cita', cita)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ListarCitaClinica = async (idclinica) => {
    try {
        const { data } = await BmHttp().get(`get_citas?idclinica=${idclinica}`)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ActualizarCita = async (cita) => {
    try {
        const { data } = await BmHttp().post('up_cita', cita)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const EliminarCita = async (idcita) => {
    try {
        const { data } = await BmHttp().delete('get_cita?idcita='+idcita)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerListarCitasClinicas = async (fecha_inicio, fecha_fin) => {
    try {
        const { data } = await BmHttp().get(`get_citas_clinica?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}`)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerCitasDoctod = async (fecha_inicio, iddoctor) => {
    try {
        const { data } = await BmHttp().get(`get_citas_doctor?fecha_inicio=${fecha_inicio}&iddoctor=${iddoctor}`)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerCitaPaciente = async (idpaciente, idcita) => {
    try {
        const { data } = await BmHttp().get(`get_citas_paciente?idpaciente=${idpaciente}&idcita=${idcita}`)
        return data
    } catch (error) {
        console.log(error);
    }
}
