import { BmHttp } from "../utils/Global";

export const getMedicinaService = async (idClinica) => {
  try {
    const { data } = await BmHttp().get(`medicina_all?idclinica=${idClinica}`);
    if (data.success) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};