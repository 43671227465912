import React from 'react'
import Odontograma from './Odontograma'
import OrtInfo from './OrtInfo'
import Paquetes from './Paquetes'

export default function InfoOdo() {
  return (
    <>
<div className="row">
    <div className="col-12">
       
        
        <ul className="nav nav-tabs justify-content-star " role="tablist">
            <li className="nav-item">
                <a className="nav-link active" data-bs-toggle="tab" href="#tab-odontograma" role="tab" onclick="getProcePacienteOdon()">
                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                    <span className="d-none d-sm-block">ONDOTOGRAMA</span>    
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-odont-paquetes" role="tab" onclick="loadPaquetesOdont()">
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">PAQUETES</span>    
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-ortodoncia" role="tab" onclick="loadPlanesOrtodoncia()">
                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                    <span className="d-none d-sm-block">ORTODONCIA</span>    
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#t-odonto-presupuestos" role="tab" onclick="loadPresupuestosOdonto()">
                    <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                    <span className="d-none d-sm-block">PRESUPUESTOS</span>    
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#settings" role="tab">
                    <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                    <span className="d-none d-sm-block">RECETAS</span>    
                </a>
            </li>
        </ul>
       

        <div className="tab-content p-3 text-muted">

            <div className="tab-pane active" id="tab-odontograma" role="tabpanel">
                <Odontograma />
            </div>

            <div className="tab-pane" id="tab-odont-paquetes" role="tabpanel">
                <Paquetes />
            </div>

            <div className="tab-pane" id="tab-ortodoncia" role="tabpanel">
                <OrtInfo />
            </div>


            <div className="tab-pane" id="t-odonto-presupuestos" role="tabpanel" aria-labelledby="t-odonto-presupuestos-tab">

                @include('pacientes-profile.odontologia.odon-presupuestos')

            </div>

        </div>


       

        </div>
</div>



<div id="canvasIngresoProcedOdont" className="offcanvas offcanvas-end" tabindex="-1"  aria-labelledby="offcanvasRightLabel" style={{width: "50%"}}>
    <div className="offcanvas-header">
        <h5 id="offcanvasRightLabel">Editar Procedimiento</h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body">
        @include('pacientes-profile.odontologia.odon-edit-proc')
    </div>
</div>



<div id="canvasIngresoPlanOrtodoncia" className="offcanvas offcanvas-end" tabindex="-1"  aria-labelledby="offcanvasRightLabel" style={{width: "50%"}}>
    <div className="offcanvas-header">
        <h5 id="offcanvasRightLabel">Ingresar Plan Ortodoncia</h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body">
        @include('pacientes-profile.odontologia.ort-insert-plan')
    </div>
</div>


<div id="canvasIngresoPresupuestos" className="offcanvas offcanvas-end" tabindex="-1"  aria-labelledby="offcanvasRightLabel" style={{width: "70%"}}>
    <div className="offcanvas-header">
        <h5 id="offcanvasRightLabel"><span id="sp_title_presupuesto">Ingresar Presupuesto</span></h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body">
        @include('pacientes-profile.odontologia.odon-presupuesto-insert')
    </div>
</div>


<div id="canvasAtenderPresupuestos" className="offcanvas offcanvas-end" tabindex="-1"  aria-labelledby="offcanvasRightLabel" style={{width: "80%"}}>
    <div className="offcanvas-header">
        <h5 id="offcanvasRightLabel"><span id="sp_title_presupuesto_insert">Ingresar Presupuesto</span></h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body">
        @include('pacientes-profile.odontologia.odon-presupuesto-atender')
    </div>
</div>

<div id="canvasControlOrtodoncia" className="offcanvas offcanvas-end" tabindex="-1"  aria-labelledby="offcanvasRightLabel" style={{width: "80%"}}>
    <div className="offcanvas-header">
        <h5 id="offcanvasRightLabel">Ingresar Control Ortondoncia</h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body">
        @include('pacientes-profile.odontologia.odon-edit-proc-ort')
    </div>
</div>


<div id="canvasPaquetesInsertOdont" className="offcanvas offcanvas-end" tabindex="-1"  aria-labelledby="offcanvasRightLabel" style={{width: "50%"}}>
    <div className="offcanvas-header">
        <h5 id="offcanvasRightLabel">Ingresar Control Ortondoncia</h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div className="offcanvas-body">
        @include('pacientes-profile.odontologia.odont-paquete-insert')
    </div>
</div>


    </>
  )
}
