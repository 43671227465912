import Swal from "sweetalert2";

export const salaFailureToast = () => {
  Swal.fire({
    title: 'Error en el formulario',
    text: 'Debe tener un valor en el campo sala',
    icon: 'error',
    confirmButtonText: 'Aceptar'
  });
}

export const fechaFailureToast = () => {
  Swal.fire({
    title: 'Error en el formulario',
    text: 'Debe tener un valor en el campo fecha',
    icon: 'error',
    confirmButtonText: 'Aceptar'
  });
}

export const ListaExamenFailureToast = () => {
  Swal.fire({
    title: 'Error en el formulario',
    text: 'Debe seleccionar un examen del listado de examenes',
    icon: 'error',
    confirmButtonText: 'Aceptar'
  });
}

export const MoreThatOneExamenFailureToast = () => {
  Swal.fire({
    title: 'Adverencia en el formulario',
    text: 'Debe seleccionar solo un examen del listado de examenes',
    icon: 'warning',
    confirmButtonText: 'Aceptar'
  });
}

export const registerSuccessToast = () => {
  Swal.fire({
    title: 'Registro exitoso',
    text: 'Los datos han sido guardados exitosamente',
    icon: 'success',
    confirmButtonText: 'Aceptar'
  });
}

export const registerFailureToast = () => {
  Swal.fire({
    title: 'Registro fallido',
    text: 'Ha ocurrido un error al guardar los datos, intente más tarde',
    icon: 'error',
    confirmButtonText: 'Aceptar'
  });
}