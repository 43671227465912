import moment from 'moment/moment';
import React, { useState } from 'react';
import ContextMenu from './ContextMenu';

function DoctorScheduleTable({ doctoresEspecialidades, fecha, show, setShow, setDoctor, setHora, setCitaPaciente, citasDoctotes }) {

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    position: { x: 0, y: 0 },
    options: []
  });

  // sacar el dia actual en el siguiente formato lunes, martes, miercoles, jueves, viernes, sabado, domingo

  const dias = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
  const fechaActual = moment(fecha).toDate();
  const diaActual = dias[fechaActual.getDay()];


  function calcularIntervalosTiempo(horario_inicio, horario_fin, intervalo_tiempo) {
    const [horaInicio, minutoInicio] = horario_inicio.split(':').map(Number);
    const [horaFin, minutoFin] = horario_fin.split(':').map(Number);
    const intervalos = [];

    let horaActual = horaInicio;
    let minutoActual = minutoInicio;

    while (horaActual < horaFin || (horaActual === horaFin && minutoActual < minutoFin)) {
      intervalos.push(`${String(horaActual).padStart(2, '0')}:${String(minutoActual).padStart(2, '0')}`);
      minutoActual += intervalo_tiempo;
      if (minutoActual >= 60) {
        horaActual += Math.floor(minutoActual / 60);
        minutoActual %= 60;
      }
    }

    return intervalos;
  }

  function isDiaLaboral(doctor) {
    return doctor.doctors_dias_laborables[0][diaActual];
  }

  function isDoctorAvailable(doctor, hora) {
    const [horaInicio, minutoInicio] = doctor.horario_inicio.split(':').map(Number);
    const [horaFin, minutoFin] = doctor.horario_fin.split(':').map(Number);
    const [horaHora, minutoHora] = hora.split(':').map(Number);

    if (horaHora < horaInicio || horaHora > horaFin) return false;
    if (horaHora === horaInicio && minutoHora < minutoInicio) return false;
    if (horaHora === horaFin && minutoHora >= minutoFin) return false;
    
    const intervalo = doctor.especialidades.clinica_especialidades[0].intervalo_tiempo;
    const diferencia = (horaHora - horaInicio) * 60 + (minutoHora - minutoInicio);
    return diferencia % intervalo === 0;
  }

  function isDoctorBusy(doctor, hora) {
    // Filtra las citas del doctor para la hora indicada
    const citasDoctorHora = citasDoctotes.filter(cita => cita.iddoctor === doctor.iddoctor && String(cita.hora_inicio) === String(hora) && String(cita.fecha_inicio) === String(fecha));
    if (citasDoctorHora.length > 0) {
      return citasDoctorHora[0].pacientes.nombres_completos;
    } else {
      return null; // No hay paciente en esa hora
    }
  }
  if(doctoresEspecialidades.length === 0) return null;

  const handleCellClick = (doctor, hora, intervalo_tiempo) => {
    if(!isDiaLaboral(doctor)) return;
    if (isDoctorAvailable(doctor, hora)) {
      // Aquí puedes manejar la lógica para el clic en una celda disponible
      if(fecha){
        setHora({
          hora_inicio: hora,
          hora_fin: `${hora.split(':')[0]}:${parseInt(hora.split(':')[1]) + intervalo_tiempo}`,
        });
        setCitaPaciente({
          idclinica: doctor.idclinica,
          fecha_inicio: fecha,
          iddoctor: doctor.iddoctor,
          hora_inicio: hora,
          hora_fin: `${hora.split(':')[0]}:${parseInt(hora.split(':')[1]) + intervalo_tiempo}`,
        });
        setDoctor(doctor);
        setShow(!show);
      }else{
        setHora({
          hora_inicio: hora,
          hora_fin: `${hora.split(':')[0]}:${parseInt(hora.split(':')[1]) + intervalo_tiempo}`,
        });
        setDoctor(doctor);
        setShow(!show);
      }
    }
  };

  const horariosClinica = calcularIntervalosTiempo(
    doctoresEspecialidades[0].clinica.horario_inicio,
    doctoresEspecialidades[0].clinica.horario_fin,
    doctoresEspecialidades[0].especialidades.clinica_especialidades[0].intervalo_tiempo
  );

  // menu de opciones al hacer click derecho en la celda
  const handleContextMenu = (e) => {
    e.preventDefault();
    setContextMenu({
      ...contextMenu,
      visible: true,
      // position: { x: e.clientX, y: e.clientY },
      options: [
        { label: 'Opción 1', action: () => console.log('Opción 1 seleccionada') },
        { label: 'Opción 2', action: () => console.log('Opción 2 seleccionada') },
        // Agrega más opciones según sea necesario
      ]
    });
  };
  const handleOptionClick = (option) => {
    option.action();
    setContextMenu({ ...contextMenu, visible: false });
  };

  const handleMouseMove = (event) => {
    setContextMenu({
      ...contextMenu,
      position: { 
        x: event.clientX,
        y: event.clientY 
      }
    });
  };

  return (
    <div style={{ overflowY: 'auto', maxHeight: '650px' }} onMouseMove={handleMouseMove} >
      <table id="calendar" className="table table-bordered table-responsive calendar" style={{ fontSize: '14px' }}>
        <thead>
          <tr>
            <th style={{ position: 'sticky', top: '0', backgroundColor: 'white', zIndex: '1' }}>Horario</th>
            {
              doctoresEspecialidades.map((doctor, index) => (
                <th
                  key={index} style={{ position: 'sticky', top: '0', backgroundColor: 'white', zIndex: '1' , textAlign: 'center'}}
                >
                  {doctor.nombre}
                  <br />
                  <span className="badge bg-primary rounded-pill">{doctor.horario}</span>
                </th>
              ))
            }
            </tr>
        </thead>
        <tbody>
          {horariosClinica.map((hora, index) => (
            <tr key={index}>
              <td>{hora}</td>
              {doctoresEspecialidades.map((doctor, idx) => (
                <td key={idx} style={{ 
                  backgroundColor: isDiaLaboral(doctor) ? isDoctorAvailable(doctor, hora) ? '#F6F9AF' : 'white' : '#F8D7DA',
                  cursor: isDiaLaboral(doctor) ? isDoctorAvailable(doctor, hora) ? 'pointer' : 'not-allowed' : 'not-allowed',
                  // fontWeight: isDiaLaboral(doctor) ? 'bold' : 'normal',
                }}
                  className='text-center'
                    onClick={() => {
                      handleCellClick(doctor, hora, doctor.especialidades.clinica_especialidades[0].intervalo_tiempo);
                    }}
                    // al hacer doble click en la celda se abre el modala ver el historial del paciente
                    // onDoubleClick={() => {
                    //   handleCellClick(doctor, hora, doctor.especialidades.clinica_especialidades[0].intervalo_tiempo);
                    // }}
                    // al hacer click derecho en la celda se abre el modal para ver el historial del paciente
                    onContextMenu={handleContextMenu}
                    
                >
                  {
                    isDiaLaboral(doctor) && isDoctorAvailable(doctor, hora) ? 'Disponible' : '' 
                  }
                  <br />
                  {isDiaLaboral(doctor) && isDoctorAvailable(doctor, hora) ? hora : ''}
                  <br />
                  {isDoctorAvailable(doctor, hora) ? isDoctorBusy(doctor, hora) : ''}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <ContextMenu
        visible={contextMenu.visible}
        position={contextMenu.position}
        options={contextMenu.options}
        onOptionClick={handleOptionClick}
      />
    </div>
  );
}

export default DoctorScheduleTable;
