import { BmHttp } from "../utils/Global"


export const Listarusers = async () => {
    try {
        const {data} = await BmHttp().get('users_all')
        return data
    } catch (error) {
        console.log(error)
    }
}

export const Actualizaruser = async (user) => {
    try {
        const {data} = await BmHttp().put('users', user)
        return data
    } catch (error) {
        console.log(error)
    }
}

export const Crearuser = async (user) => {
    try {
        const {data} = await BmHttp().post('users', user)
        return data
    } catch (error) {
        console.log(error)
    }
}

export const Eliminaruser = async (id) => {
    try {
        const {data} = await BmHttp().delete('users/'+id)
        return data
    } catch (error) {
        console.log(error)
    }
}

export const ServiceLogin = async (user) => {
    try {
        const {data} = await BmHttp().post('login', user)
        return data
    } catch (error) {
        console.log(error)
    }
}