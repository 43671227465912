import axios from "axios";
import { GetToken } from "./storeUsuario";

export const usuario_local = "usuario:";
export const usuario_token = "token_usuario:";
export const dev = false;
export const host = dev
  ? "http://localhost:3003/"
  : "https://colon.smartmedic.site/backend/";
export const proxy = "";
export const colorPrimario = "#3F98F8";
// validar si es produccion o desarrollo

const bm = () => {
  return () => {
    let token = GetToken();
    return axios.create({
      baseURL: host,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      timeout: 60000,
    });
  };
};

export const BmHttp = bm();

export const mensajes = {
  success: "Operacion se realizo con exito",
  error: "Ocurrio un error al realizar la operacion",
  select: "Seleccione una opcion",
  required: "Este campo es requerido",
  email: "Correo invalido",
  password: "Contraseña invalida",
  minLength: "Minimo de caracteres no alcanzado",
  maxLength: "Maximo de caracteres excedido",
  number: "Solo se permiten numeros",
  pattern: "Formato invalido",
};

export const FORMULARIOS = [
  "LABORATORIO",
  "IMAGENES",
  "HISTOPATOLOGIA",
  "INTERCONSULTA",
  "DERIVACION",
];
