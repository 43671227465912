import { BmHttp } from "../utils/Global";


export const TipoGenero = async () => {
    try {
        const { data } = await BmHttp().get('tipo-genero');
        return data;
    } catch (error) {
        console.log(error)
    }
}