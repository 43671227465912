const initialState = {
    price: null,
    tableNumber: null,
}

const headerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PRICE':
            return {
                ...state,
                price: action.payload
            }
        case 'SET_TABLE_NUMBER':
            return {
                ...state,
                tableNumber: action.payload
            }
        default:
            return state
    }
}

export default headerReducer;