import React from 'react'

export default function Cuenta() {
  return (
    <>
        <div className="row">
            <div className="col-xl-8">
                <div id="dv_cuenta_odontologia">
                    <div className="card">
                
                        <div className="card-header">
                            <h5 className="card-title mb-0">Detalle de cuentas <strong>ODONTOLOGIA</strong></h5>
                        </div>
                        
                        <div className="card-body">
                            <table id="tb_estado_cuenta_odontologia" className="table mb-0" style={{fontSize: '14px'}}>

                                <thead className="table-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Fecha</th>
                                        <th>Detalle</th>
                                        <th>Factura</th>
                                        <th><div align='center'>Estado</div></th>
                                    
                                        <th><div align='center'>Precio</div></th>
                                        <th><div align='center'>Cancelado</div></th>
                                        <th><div align='center'>Saldo</div></th>
                            
                                    </tr>
                                </thead>
                                <tbody>                            
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div id="dv_cuenta_ortodoncia">
                    <div className="card">
                
                        <div className="card-header">
                            <h5 className="card-title mb-0">Detalle de cuentas <strong>ORTODONCIA</strong></h5>
                        </div>
                        
                        <div className="card-body">
                            <table id="tb_estado_cuenta_ortodoncia" className="table mb-0" style={{fontSize: '14px'}}>

                                <thead className="table-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Fecha</th>
                                        <th>Detalle</th>
                                        <th>Factura</th>
                                        <th><div align='center'>Estado</div></th>
                                    
                                        <th><div align='center'>Precio</div></th>
                                        <th><div align='center'>Cancelado</div></th>
                                        <th><div align='center'>Saldo</div></th>
                            
                                    </tr>
                                </thead>
                                <tbody>                            
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <div id="dv_cuenta_medicina">
                    <div className="card">
                
                        <div className="card-header">
                            <h5 className="card-title mb-0">Detalle de cuentas <strong>MEDICINA</strong></h5>
                        </div>
                        
                        <div className="card-body">
                            <table id="tb_estado_cuenta_medicina" className="table mb-0" style={{fontSize: '14px'}}>

                                <thead className="table-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Fecha</th>
                                        <th>Detalle</th>
                                        <th>Factura</th>
                                        <th><div align='center'>Estado</div></th>
                                    
                                        <th><div align='center'>Precio</div></th>
                                        <th><div align='center'>Cancelado</div></th>
                                        <th><div align='center'>Saldo</div></th>
                                                        
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>


            <div className="col-xl-4">
                <div className="card bg-danger border-danger text-white-50">
                    <div className="card-body">
                        <h5 className="mb-4 text-white"><i className="fas fa-money-check-alt"></i> TOTAL DEUDA</h5>
                        
                        <table id="tb_estado_cuenta_resumen" className="table mb-0 text-white" style={{fontSize: "16px", fontWeight: "bold"}}>                   
                            <tbody></tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
