import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ActivarDesactivarEspecialidad, ControllerEliminarEspecialidad, EspecialidadClinica } from '../../../Service/Service.especialidad';
import { GetTokenDecoded } from '../../../utils/storeUsuario';
import EspecialidadCreate from './EspecialidadCreate';


export default function Especialidad({tabs}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [ping, setPing] = useState(false);
    const [especialidadEditar, setespecialidadEditar] = useState(null);

    const [especialidadesClinica, setEspecialidadesClinica] = useState([]);


    const EditarEstado = async(doctor) => {
        const rest = await ActivarDesactivarEspecialidad(doctor.idespecialidad, doctor.intervalo_tiempo, 
          doctor.idclinica, !doctor.estado, doctor.icono)
        if(rest.success){
            const res = await EspecialidadClinica(GetTokenDecoded().idclinica)
            if(res.success){
                setEspecialidadesClinica(res.data)
            }
        }
    }

    const EliminarEspecialidad = async(doctor) => {
        const rest = await ControllerEliminarEspecialidad(doctor.idclinica_especialidad)
        if(rest.success){
            const res = await EspecialidadClinica(GetTokenDecoded().idclinica)
            if(res.success){
                setEspecialidadesClinica(res.data)
            }
        }
    
    }

    const EditarEspecialidad = (doctor) => {
      setespecialidadEditar(doctor)
      handleShow()
    }

    useEffect(() => {
        (async () => {
            const res = await EspecialidadClinica(GetTokenDecoded().idclinica)
            if(res.success){
                setEspecialidadesClinica(res.data)
            }
        })();
    }, [ping, tabs.especialidades]);

  return (
    <section>
      <div class="row">
        <div class="col-12">

          <div class="page-title-box d-sm-flex align-items-center justify-content-between ">
            <button type="button" class="btn btn-light waves-effect waves-light" onClick={handleShow}>
              <i class="bx bx-plus me-1"></i> Anadir nueva especialidad</button>
          </div>


          <table id="tb_doctores" class="table dt-responsive table-striped nowrap w-100" style={{ fontSize: '14px' }}>
            <thead>
              <tr>
                <th width="15">#</th>
                <th>Codigo</th>
                <th>Especialidad</th>
                <th>Clinica</th>
                <th>Icon/Imagen</th>
                <th>Estado</th>
                <th width="50">Opciones</th>
              </tr>
            </thead>
            <tbody>
              {
                especialidadesClinica.map((doctors, index) => (
                    <tr>
                      <td>{index+1}</td>
                      <td>{doctors.codigo}</td>
                      <td>{doctors.especialidad}</td>
                      <td>{doctors.clinica}</td>
                      <td>{
                        doctors.icono ? 
                          <img src={doctors.icono} width="50" height="50" alt="icono" /> :
                          <img src="https://via.placeholder.com/50" width="50" height="50" alt="icono" />
                        }</td>
                      <td>{
                      doctors.estado ? 
                        <span class="badge bg-success">Activo</span> :
                        <span class="badge bg-danger">Inactivo</span>
                      }</td>
                      <td align="center">
                        <div class="btn-group"><button type="button"
                          class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                          aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                          <div class="dropdown-menu dropdownmenu-secondary">
                            <a class="dropdown-item" href="#;" onClick={()=>EditarEstado(doctors)}>{
                                doctors.estado ? 'Desactivar' : 'Activar'
                            }</a>
                            <a class="dropdown-item" href="#;" onClick={()=>EditarEspecialidad(doctors)}>Editar</a>

                            <a class="dropdown-item" href="#;" onClick={()=>EliminarEspecialidad(doctors)}>Eliminar</a>
                            {/* <div class="dropdown-divider"></div> */}


                          </div>
                        </div>
                      </td>
                    </tr>
                ))
              }
            </tbody>
          </table>

        </div>
      </div>
      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Activacion de Especialidad
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <EspecialidadCreate 
            handleClose={handleClose}
            setPing={setPing}
            ping={ping}
            especialidadeditar={especialidadEditar}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  )
}
