import React from 'react';
import Select from 'react-select';

export default function SelectSearchble({listCompany, handleChange, selectedOption, placeholder, isMulti, styles}) {

  
    const options = listCompany.map(company => ({
      value: company.value,
      label: company.label,
      ...company
    }));
  
    return (
      <div
        style={styles}
      >
        <Select
          value={selectedOption}
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          isSearchable={true}
          // cantidad de select que se pueden seleccionar
          isMulti={isMulti ? true : false}
        />
      </div>
    );
}
