import React from "react";

export default function ProcMedicoCreate() {
  return (
    <section>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <form id="form-create-procedimiento-med">

                <div class="row mb-4">
                  <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Nombre Procedimiento</label>
                  <div class="col-sm-9">
                    <input type="hidden" id="hidprocedimiento" name="hidprocedimiento" />
                      <input type="text" class="form-control" id="procedimiento-nombre" name="procedimiento-nombre" required />
                      </div>
                  </div>




                  <div class="row mb-4">
                    <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Tipo Procedimiento </label>
                    <div class="col-sm-9">
                      <select class="form-control" id="tipo-procedimiento-med" name="tipo-procedimiento-med" required>
                      </select>
                    </div>
                  </div>


                  <div class="row mb-4">
                    <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Duración </label>

                    <div class="col-sm-9">
                      <input type="text" class="form-control" name="duracion-proced" id="duracion-proced" />
                    </div>
                  </div>


                  <div class="row mb-4">
                    <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Precio</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="precio-proced" name="precio-proced" required />
                    </div>
                  </div>


                  <div class="row mb-4">
                    <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Activo</label>
                    <div class="col-sm-9">
                      <input type="checkbox" id="estado_proced" name="estado_proced" switch="none" value="1" checked />
                      <label for="estado_proced" data-on-label="On" data-off-label="Off"></label>

                    </div>
                  </div>


                  <div class="text-center">
                    <a href="javascript:;" onclick="openModalProcOdonto()" class="btn btn-danger btnCloseCanvas">Cancelar</a>
                    <button type="submit" class="btn btn-primary btnSaveInfo">Guardar</button>
                  </div>

              </form>
            </div>
          </div>
        </div> 
      </div> 


    </section>
  );
}
