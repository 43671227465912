import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { guardarExamenLaboratorioService } from "../../../Service/Service.examen";
import TableImagenExamen from "../../Table/TableImagenExamen";
import { registerFailureToast, registerSuccessToast } from "../ModalExamenToast";
import { validationFormLaboratorio } from "./ModalLaboratorioValidation";

function ModalLaboratorio(props) {
  const { 
    show, 
    onHide, 
    tiposEstudio, 
    setTipoEstudios, 
    examenes, 
    cita
  } = props;

  const ITEMS_PER_PAGE = 21;

  const [form, setForm] = useState({
    idcita: 0,
    IdCategoriaExamen: 0,
    idpaciente: 0,
    fecha: new Date().toISOString().split("T")[0],
    numero_orden: "",
    sala: "",
    IdExamenTipo: [],
  });

  const [checkedState, setCheckedState] = useState({
    urgente: false,
    rutina: false,
    control: false,
  });

  const [disabledState, setDisabledState] = useState({
    urgente: false,
    rutina: false,
    control: false,
  });

  const [examenesFilter, setExamenesFilter] = useState([]);

  const [itemsExamenesFilter, setItemsExamenesFilter] = useState([]);

  const [itemsExamenesFilterClone, setItemsExamenesFilterClone] = useState([]);

  const [selectExamen, setSelectExamen] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);

  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {}, []);

  const handleChange = (type) => {
    setCheckedState((prevState) => {
      const newCheckedState = { ...prevState };

      if (newCheckedState[type]) {
        // If the clicked checkbox is being unchecked, reset all checkboxes and enable them
        newCheckedState.urgente = false;
        newCheckedState.rutina = false;
        newCheckedState.control = false;
        setDisabledState({
          urgente: false,
          rutina: false,
          control: false,
        });
      } else {
        // Otherwise, set only the clicked checkbox to checked and disable the others
        newCheckedState.urgente = type === "urgente";
        newCheckedState.rutina = type === "rutina";
        newCheckedState.control = type === "control";
        setDisabledState({
          urgente: type !== "urgente",
          rutina: type !== "rutina",
          control: type !== "control",
        });
      }

      return newCheckedState;
    });
  };

  const resetCheck = (array) => {
    let arrayMap = array.map((item) => {
      item.disabled = false;
      item.checked = false;
      return item;
    });

    return arrayMap;
  };

  const handlerCheckoutTipoEstudio = (e, tipoEstudio) => {
    return true;
  };

  const initArrayPagination = (tipoEstudio) => {
    const examenArrayFilter = examenes.filter(
      (examen) => examen.tipo === tipoEstudio.tipo
    );

    let examenPagination = [...examenArrayFilter];
    examenPagination = resetCheck(examenPagination);

    setExamenesFilter([...examenPagination]);
    setItemsExamenesFilterClone([...examenPagination]);

    paginationResults(examenPagination);
  };

  const paginationResults = (examenPagination) => {
    const totalPage = Math.ceil(examenPagination.length / ITEMS_PER_PAGE);
    const clonePagination = [...examenPagination];

    setCurrentPage(0);
    setTotalPage(totalPage);
    setItemsExamenesFilter([
      ...clonePagination.splice(
        0,
        clonePagination.length > ITEMS_PER_PAGE
          ? ITEMS_PER_PAGE
          : clonePagination.length
      ),
    ]);
  };

  const nextHandler = () => {
    const nextPage = currentPage + 1;
    const firstIndex = nextPage * ITEMS_PER_PAGE;

    if (nextPage === totalPage) {
      return;
    }

    let examenPagination = [...examenesFilter];
    examenPagination = examenPagination.splice(firstIndex, ITEMS_PER_PAGE);

    setItemsExamenesFilter([...examenPagination]);
    setCurrentPage(nextPage);
  };

  const prexHandler = () => {
    if (currentPage - 1 < 0) {
      return;
    }

    const prevPage = currentPage - 1;
    const firstIndex = prevPage * ITEMS_PER_PAGE;

    let examenPagination = [...examenesFilter];
    examenPagination = examenPagination.splice(firstIndex, ITEMS_PER_PAGE);

    setItemsExamenesFilter([...examenPagination]);
    setCurrentPage(prevPage);
  };

  const searchExamenHandler = (event) => {
    const search = event.target.value.toLowerCase();
    let itemsExamen = resetCheck(itemsExamenesFilterClone);
    setItemsExamenesFilter([...itemsExamen]);

    if (search === "") {
      paginationResults([...itemsExamen]);
      return;
    }

    const examenArray = [...itemsExamen];
    const examenFilter = examenArray.filter((examen) => {
      return examen.descripcion.toLowerCase().includes(search);
    });

    const totalPage = Math.ceil(examenFilter.length / ITEMS_PER_PAGE);
    setCurrentPage(0);
    setTotalPage(totalPage);
    setItemsExamenesFilter([
      ...examenFilter.splice(
        0,
        examenFilter.length > ITEMS_PER_PAGE
          ? ITEMS_PER_PAGE
          : examenFilter.length
      ),
    ]);
  };

  const initModal = () => {
    const { pacientes } = cita;
    initArrayPagination(tiposEstudio[0]);

    setForm({
      ...form,
      idcita: cita.idcita,
      idpaciente: pacientes.idpaciente,
      IdCategoriaExamen: tiposEstudio[0].IdExamenTipo,
    });
  };

  const sendFormHandle = () => {
    if(!validationFormLaboratorio(form)) {
      return;
    }

    const data = guardarExamenLaboratorioService(form);
    
    if (!data) {
      registerFailureToast();
      return;
    }

    registerSuccessToast();
    closeModal();
  };

  const clearForm = () => {
    setForm({
      ...form, 
      IdExamenTipo: [],
      sala: "",
      numero_orden: ""
    });
  }

  const closeModal = () => {
    clearForm();
    onHide();
  }
  return (
    <Modal show={show} onHide={closeModal} size="xl" onShow={initModal}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Orden Laboratorio
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={onHide}
        ></button>
        <hr />
      </div>

      <div className="modal-body">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label><b className="text-danger mt-1">*</b> Fecha de orden</label>
                <input
                  type="date"
                  className="form-control"
                  id="fecha_orden"
                  min={new Date().toISOString().split("T")[0]}
                  name="fecha_orden"
                  value={form.fecha}
                  onChange={(e) => setForm({ ...form, fecha: e.target.value })}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Numero orden</label>
                <input
                  type="text"
                  className="form-control"
                  id="numero_orden"
                  name="numero_orden"
                  value={form.numero_orden}
                  onChange={(e) =>
                    setForm({ ...form, numero_orden: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="w-100 bg-primary d-flex justify-content-center">
                <label className="text-white w-100 p-1 mb-0 rounded px-2">
                  1. Estudio Solicitado
                </label>
              </div>

              <div className="row py-2 mt-4">
                {tiposEstudio && tiposEstudio.length > 0
                  ? tiposEstudio.map((tipoEstudio, index) => (
                      <div
                        className="col-md-4 d-flex justify-content-start align-items-center pb-2"
                        key={index}
                      >
                        <div className="form-check form-switch form-switch-md">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={tipoEstudio.IdExamenTipo}
                            checked={true}
                            disabled={tipoEstudio.disabled}
                            onChange={(e) =>
                              handlerCheckoutTipoEstudio(e, tipoEstudio)
                            }
                          />
                        </div>
                        <label className="form-check-label ml-2 text-uppercase">
                          {tipoEstudio.tipo}
                        </label>
                      </div>
                    ))
                  : ""}
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="w-100 bg-primary d-flex justify-content-center">
                <label className="text-white w-100 p-1 mb-0 rounded px-2">
                  Datos general
                </label>
              </div>

              <div className="row pt-2">
                {/* <div className="col-6">
                  <div className="form-group">
                    <label>Servicio</label>
                    <input
                      type="text"
                      className="form-control"
                      id="servico"
                      name="servicio"
                    />
                  </div>
                </div> */}

                <div className="col-6 d-flex align-items-center justify-content-center">
                  <div className="row mt-3">
                    <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
                      <div className="form-check form-switch form-switch-md">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={checkedState.urgente}
                          onChange={() => handleChange("urgente")}
                          disabled={disabledState.urgente}
                        />
                      </div>
                      <label className="form-check-label ml-2 text-uppercase">
                        Urgente
                      </label>
                    </div>

                    <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
                      <div className="form-check form-switch form-switch-md">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={checkedState.rutina}
                          onChange={() => handleChange("rutina")}
                          disabled={disabledState.rutina}
                        />
                      </div>
                      <label className="form-check-label ml-2 text-uppercase">
                        Rutina
                      </label>
                    </div>

                    <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
                      <div className="form-check form-switch form-switch-md">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={checkedState.control}
                          onChange={() => handleChange("control")}
                          disabled={disabledState.control}
                        />
                      </div>
                      <label className="form-check-label ml-2 text-uppercase">
                        Control
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label><b className="text-danger mt-1">*</b> Sala</label>
                    <input
                      type="text"
                      className="form-control"
                      id="sala"
                      name="sala"
                      value={form.sala}
                      onChange={(e) =>
                        setForm({ ...form, sala: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row pt-2">
                {/* <div div className="col-6">
                  <div className="form-group">
                    <label>Cama</label>
                    <input
                      type="text"
                      className="form-control"
                      id="cama"
                      name="cama"
                    />
                  </div>
                </div> */}
              </div>

              <div className="row pt-2">
                <div className="col-md-6">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <div className="w-100 bg-primary d-flex justify-content-center">
                <label className="text-white w-100 p-1 mb-0 rounded px-2">
                  Listado de Examenes
                </label>
              </div>
            </div>

            <div className="col-4 pt-2">
              <div className="form-group">
                <label>Buscar exámenes</label>
                <input
                  type="text"
                  className="form-control"
                  id="servico"
                  name="servicio"
                  onChange={searchExamenHandler}
                />
              </div>
            </div>

            <div className="offset-8 "></div>

            <div className="col-12 mt-4">
              {itemsExamenesFilter.length > 0 ? (
                <TableImagenExamen
                  items={itemsExamenesFilter}
                  totalPage={totalPage}
                  setItemsExamenesFilter={setItemsExamenesFilter}
                  setSelectExamen={setSelectExamen}
                  setForm={setForm}
                  form={form}
                  currentPage={currentPage}
                  nextHandler={nextHandler}
                  prexHandler={prexHandler}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="w-100 text-center mt-4">
            <button
              className="btn btn-primary px-3 py-2 mx-auto"
              style={{ width: "250px" }}
              onClick={sendFormHandle}
            >
              <i class="bx bxs-save me-1 mt-1"></i>
              Agregar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalLaboratorio;
