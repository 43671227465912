import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ControllerReceta } from "../../../Service/Service.consulta";
import { getMedicinaService } from "../../../Service/Service.medicina";
import { getParams } from "../../../utils/Params";
import { GetTokenDecoded } from "../../../utils/storeUsuario";
import {
  registerRecetaFailureToast
} from "./ModalRecetaToast";
import { validateFormReceta } from "./ModalRecetaValidation";

function ModalReceta(props) {
  const { show, onHide, cita } = props;

  const userLocal = GetTokenDecoded();

  const [medicinas, setMedicinas] = useState([]);

  const [form, setForm] = useState({
    idreceta: 0,
    idpaciente: 0,
    idcita: 0,
    iddoctor: 0,
    medicamentos: "",
    posologia: "",
    cantidad: "",
    dosis: "",
    indicaciones: "",
    idclinica: 0,
  });

  const sendForm = async () => {
    if (!validateFormReceta(form)) {
      return;
    }

    const { success } = await ControllerReceta(form);
    if (!success) {
      registerRecetaFailureToast();
      return;
    }

    // registerRecetaFailureToast();
    closeModal();
  };

  const clearForm = () => {
    setForm({
      idreceta: 0,
      idpaciente: 0,
      idcita: 0,
      iddoctor: 0,
      medicamentos: "",
      posologia: "",
      cantidad: "",
      dosis: "",
      indicaciones: "",
      idclinica: 0,
    });
  };

  const showModal = async () => {
    const { data } = await getMedicinaService(userLocal.idclinica);

    if (data) {
      setMedicinas(data);
    }

    const params = getParams();
    setForm({
      ...form,
      idpaciente: Number.parseInt(params.idpaciente),
      idclinica: userLocal.idclinica,
      idcita: cita.idcita,
      iddoctor: cita.doctores.iddoctor,
    });
  };

  const closeModal = () => {
    clearForm();
    onHide();
  };

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <>
      <Modal
        show={show}
        onShow={showModal}
        onHide={closeModal}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Receta del paciente</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form className="row gap-15">
            <div className="col-12">
              <div className="form-group">
                <label for="medicamento" className="text-uppercase">
                  Medicamento
                </label>
                <select className="form-control" id="medicamento">
                  {medicinas.map((value, index) => (
                    <option value={value.descripcion} key={index}>
                      {value.descripcion}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label for="posología" className="text-uppercase">
                  <b className="text-danger mt-1">*</b> Posologia
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="posología"
                  value={form.posologia}
                  onChange={(e) =>
                    setForm({ ...form, posologia: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label for="cantidad" className="text-uppercase">
                      <b className="text-danger mt-1">*</b> Cantidad
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cantidad"
                      value={form.cantidad}
                      onChange={(e) =>
                        setForm({ ...form, cantidad: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label for="dosis" className="text-uppercase">
                      <b className="text-danger mt-1">*</b> Dosis
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="dosis"
                      value={form.dosis}
                      onChange={(e) =>
                        setForm({ ...form, dosis: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label for="indicaciones" className="text-uppercase">
                  Indicaciones
                </label>
                <textarea
                  className="form-control"
                  rows="2"
                  id="indicaciones"
                  value={form.indicaciones}
                  onChange={(e) =>
                    setForm({ ...form, indicaciones: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="mx-auto"
            type="button"
            onClick={() => sendForm()}
          >
            <i class="bx bxs-save me-1 mt-1"></i>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalReceta;
