import { combineReducers, createStore } from 'redux';
import headerReducer from './headerReducer';
import historialPacienteRedux from './historialPacienteRedux';

const rootReducer = combineReducers({
  // Aquí van los reducers combinados
  headerReducer,
  historialPacienteRedux,
});

const store = createStore(rootReducer);

export default store;
