import { BmHttp } from "../utils/Global";

export const ControllerListarDiagnosticos = async (init, fin) => {
  try {
    const { data } = await BmHttp().get(
      "diagnostico_paginado?init=" + init + "&fin=" + fin
    );
    if (data.success) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const GuardarDiagnosticoPaciente = async (diagnostico) => {
  try {
    const { data } = await BmHttp().post(
      "diagnostico_paciente_cita",
      diagnostico
    );
    if (data.success) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const ControllerEliminarDiagnosticoPaciente = async (id) => {
  try {
    const { data } = await BmHttp().delete("diagnostico?id=" + id);
    if (data.success) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
