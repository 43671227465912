// ModalProcedimiento.js
import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { BmHttp } from "../../../utils/Global";
import Swal from "sweetalert2";

function ModalProcedimiento(props) {
  const { show, onHide, onProcedimientoAdded } = props;
  const [form, setForm] = useState({
    procedimiento: "",
    precio: "",
    comentarios: "",
  });
  const [procedimientos, setProcedimientos] = useState([]);
  const [idcita, setIdcita] = useState(null);

  useEffect(() => {
    if (show) {
      fetchProcedimientos();
      getIdCitaFromUrl();
    }
  }, [show]);

  const getIdCitaFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const idcitaFromUrl = urlParams.get('idcita');
    setIdcita(idcitaFromUrl ? parseInt(idcitaFromUrl) : null);
  };

  const fetchProcedimientos = async () => {
    try {
      const response = await BmHttp().get("/procedimiento_precios");
      if (response.data.success) {
        setProcedimientos(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching procedimientos:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });

    if (name === "procedimiento") {
      const selectedProcedimiento = procedimientos.find(
        (p) => p.ExamenTipo.IdExamenTipo.toString() === value
      );
      if (selectedProcedimiento) {
        setForm((prevForm) => ({
          ...prevForm,
          precio: selectedProcedimiento.precio,
        }));
      }
    }
  };

  const clearForm = () => {
    setForm({
      procedimiento: "",
      precio: "",
      comentarios: "",
    });
  };

  const handleSubmit = async () => {
    if (!form.procedimiento || !form.precio || !form.comentarios) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Por favor, complete todos los campos',
      });
      return;
    }
  
    if (!idcita) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se pudo obtener el ID de la cita',
      });
      return;
    }
  
    try {
      const selectedProcedimiento = procedimientos.find(
        (p) => p.ExamenTipo.IdExamenTipo.toString() === form.procedimiento
      );
  
      const body = {
        idcita: idcita,
        idExamenTipo: selectedProcedimiento.ExamenTipo.IdExamenTipo,
        precio: parseFloat(form.precio),
        observaciones: form.comentarios
      };
  
      const response = await BmHttp().post("/cita_procedimiento", body);
      
      // Imprimir la respuesta completa para depuración
      // console.log("Respuesta completa del servidor:", response);
  
      if (response.data && response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Éxito',
          text: 'Procedimiento guardado correctamente',
        });
        onProcedimientoAdded();
        clearForm();
        onHide();
      } else {
        // Si la respuesta no tiene éxito, lanzamos un error con el mensaje del servidor
        throw new Error(response.data?.message || 'Error desconocido al guardar el procedimiento');
      }
    } catch (error) {
      console.error("Error completo:", error);
      
      let errorMessage = 'Hubo un problema al guardar el procedimiento';
      
      if (error.response) {
        // El servidor respondió con un estado fuera del rango 2xx
        console.error("Datos de la respuesta de error:", error.response.data);
        console.error("Estado de la respuesta de error:", error.response.status);
        errorMessage = error.response.data?.message || errorMessage;
      } else if (error.request) {
        // La solicitud se hizo pero no se recibió respuesta
        console.error("No se recibió respuesta del servidor");
        errorMessage = 'No se recibió respuesta del servidor';
      } else {
        // Algo sucedió en la configuración de la solicitud que provocó un error
        console.error("Error de configuración:", error.message);
        errorMessage = error.message || errorMessage;
      }
  
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Procedimiento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group col-md-12 mb-3">
            <label htmlFor="i_c_procedimientos" className="text-uppercase">
              Procedimiento
            </label>
            <select
                className="form-control"
                id="i_c_procedimientos"
                name="procedimiento"
                style={{ width: "100%" }}
                onChange={handleChange}
                value={form.procedimiento}
            >
               <option value="">Seleccione un procedimiento</option>
              {procedimientos.map((p) => (
                <option key={p.ExamenTipo.IdExamenTipo} value={p.ExamenTipo.IdExamenTipo}>
                  {p.ExamenTipo.codigo} - {p.ExamenTipo.descripcion}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group col-md-12 mb-3">
            <label htmlFor="i_c_proc_precio" className="text-uppercase">
              Precio
            </label>
            <input
               type="text"
               className="form-control"
               id="i_c_proc_precio"
               name="precio"
               value={form.precio}
               onChange={handleChange}
            />
          </div>

          <div className="form-group col-md-12 mb-3">
            <label htmlFor="i_c_coment_proce" className="text-uppercase">
              Comentarios
            </label>
            <textarea
              className="form-control"
              rows="2"
              id="i_c_coment_proce"
              name="comentarios"
              value={form.comentarios}
              onChange={handleChange}
            ></textarea>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Guardar Procedimiento
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalProcedimiento;