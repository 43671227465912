/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from '../Components/Alert/Alert';
import { ControllerListarCitasClinicas } from '../Service/Service.citas';
import { ControllerCreateSignos, ControllerDeleteSignos, ControllerListarSignos } from '../Service/Service.signos';
import { GetTokenDecoded } from '../utils/storeUsuario';

export default function SigVital() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = async (idcita) => {
        setListarSignos([])
        setShow(true)
        const sit = await ControllerListarSignos(idcita)
        if(sit.success){
            setListarSignos(sit.data)
        }
    };
    const [fechas, setFechas] = useState({
        desde: moment().format('YYYY-MM-DD'),
        hasta: moment().format('YYYY-MM-DD')
    })
    const [listarSignos, setListarSignos] = useState([])

    const [alertas, setAlertas] = useState({

        temperatura: false,
        pulso: false,
        presion: false,
        peso: false,
        talla: false,
        imc: false,
        saturacion_oxigeno: false,
        frecuencia_respiratoria: false,
        perimetro_cefalico: false,
    })

    const [citaslist, setCitaslist] = useState([])

    const [signos, setSignos] = useState({
        idsignosvitales:0,
        idcita:0,
        temperatura: '',
        pulso: '',
        presion: '',
        peso: '',
        talla: '',
        imc: '',
        saturacion_oxigeno: '',
        frecuencia_respiratoria: '',
        perimetro_cefalico: '',
        idclinica: GetTokenDecoded().idclinica,
    })

    const EditarSignos = (signo) => {
        setSignos(signo)
    }

    const handleCloseAlert = (e) => {
        const {name} = e.target
        setAlertas({...alertas, [name]: false})
    }

    useEffect(() => {
        (async () => {
            const sit = await ControllerListarCitasClinicas('','')
            if(sit.success){
                setCitaslist(sit.data)
            }
        })()
    }, [])

    const ConsultarCitas = async () => {
        const sit = await ControllerListarCitasClinicas(fechas.desde, fechas.hasta)
        if(sit.success){
            setCitaslist(sit.data)
        }
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setSignos({...signos, [name]: value})
    }

    const CalcularIMC = () => {
        if(signos.peso === '' || signos.talla === ''){
            return
        }
        let peso = parseFloat(signos.peso)
        let talla = parseFloat(signos.talla)
        let imc = parseFloat(peso / Math.pow(talla, 2))
        setSignos({...signos, imc: imc})
    }

    const GuardarSignos = async() => {
        // validar los campos
        if(signos.temperatura === ''){
            setAlertas({...alertas, temperatura: true})
            return
        }
        if(signos.pulso === ''){
            setAlertas({...alertas, pulso: true})
            return
        }
        if(signos.presion === ''){
            setAlertas({...alertas, presion: true})
            return
        }
        if(signos.peso === ''){
            setAlertas({...alertas, peso: true})
            return
        }
        if(signos.talla === ''){
            setAlertas({...alertas, talla: true})
            return
        }
        if(signos.saturacion_oxigeno === ''){
            setAlertas({...alertas, saturacion_oxigeno: true})
            return
        }
        if(signos.frecuencia_respiratoria === ''){
            setAlertas({...alertas, frecuencia_respiratoria: true})
            return
        }
        if(signos.perimetro_cefalico === ''){
            setAlertas({...alertas, perimetro_cefalico: true})
            return
        }

        // guardar los signos
        const gu = await ControllerCreateSignos(signos)
        if(gu.success){
            // limpiar los campos
            setSignos({
                idcita:0,
                temperatura: '',
                pulso: '',
                presion: '',
                peso: '',
                talla: '',
                imc: '',
                saturacion_oxigeno: '',
                frecuencia_respiratoria: '',
                perimetro_cefalico: '',
                idclinica: GetTokenDecoded().idclinica,
            })
            // recargar los signos
            const sit = await ControllerListarSignos(signos.idcita)
            if(sit.success){
                setListarSignos(sit.data)
            }
        }
    
    }

    const EliminarSignos = async (idsignos) => {
        console.log(idsignos);
        const gu = await ControllerDeleteSignos(idsignos)
        if(gu.success){
            // recargar los signos
            const sit = await ControllerListarSignos(signos.idcita)
            if(sit.success){
                setListarSignos(sit.data)
            }
        }
    }

    useEffect(() => {
        CalcularIMC();
    }, [signos.peso, signos.talla]); // Ejecuta CalcularIMC cuando peso o talla cambien


  return (
    <>
        <div className="page-content">
            <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18"><span id="titleConf"></span>
                            Signos Vitales
                        </h4>
        
                        <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#;">Signos</a></li>
                            <li className="breadcrumb-item active" id="liTitleConf">Vitales</li>
                        </ol>
                        </div>
        
                    </div>
                </div>
            </div>
            {/* consultar citas desde hasta */}
            <div className="row">
                <div className="col-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="mb-3">
                                        <label className="form-label">Desde</label>
                                        <input type="date" className="form-control" placeholder="Desde"
                                            value={fechas.desde}
                                            onChange={(e) => setFechas({ ...fechas, desde: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="mb-3">
                                        <label className="form-label">Hasta</label>
                                        <input type="date" className="form-control" placeholder="Hasta"
                                            value={fechas.hasta}
                                            onChange={(e) => setFechas({ ...fechas, hasta: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="my-4">
                                        <div className="d-grid ap-2 d-md-flex justify-content-md-end">
                                            <button className="btn btn-primary me-md-2 w-100" type="button"
                                                onClick={ConsultarCitas}
                                            >Consultar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* body tabla para listar las citas de los pacientes con opcion para tomar los signos vitales */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Listado de Citas</h4>
                            <p className="card-title-desc">
                                Aquí podrá ver las citas de los pacientes.
                            </p>
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Paciente</th>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Hora</th>
                                            <th scope="col">Estado</th>
                                            <th scope="col">Tipo Cita</th>
                                            <th scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            citaslist.map((cita, index) => (
                                                <tr key={index}>
                                                    <td>{index +1}</td>
                                                    <td>
                                                        <h5 className="font-size-14 mb-1">{cita.pacientes.nombres_completos}</h5>
                                                        {/* Doctor */}
                                                        <b className="text-muted mb-0">
                                                                <i class="fas fa-stethoscope"></i>
                                                        {
                                                        cita.doctores.prefijo + ' ' +
                                                        cita.doctores.nombre
                                                        }
                                                        </b>
                                                    </td>
                                                    <td>
                                                        {cita.fecha_inicio}
                                                    </td>
                                                    <td>
                                                        {cita.hora_inicio}
                                                    </td>
                                                    <td>
                                                        <span className="badge bg-soft-success text-success">{cita.estado_cita.estado}</span>
                                                    </td>
                                                    <td>
                                                        <span className="badge bg-soft-success text-success">{cita.tipo_cita.tipo}</span>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-primary mx-1" onClick={()=>{
                                                            setSignos({...signos, idcita: cita.idcita})
                                                            handleShow(cita.idcita)
                                                        }}>
                                                            <i class="fas fa-stethoscope"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            
            {/* Modal Para tomar los signos vitales */}
            <Modal show={show} onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Tomar Signos Vitales
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {/* <h4 className="card-title">Signos Vitales</h4> */}
                                <p className="card-title-desc">
                                    Aquí podrá registrar los signos vitales del paciente.
                                </p>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label className="form-label">Temperatura</label>
                                            <input type="text" className="form-control" placeholder="Temperatura" 
                                                autoComplete='off'
                                                pattern='[0-9]+([.][0-9]+)?'
                                                name='temperatura'
                                                value={signos.temperatura}
                                                onChange={handleChange}
                                                onClick={handleCloseAlert}
                                            />
                                            {
                                                alertas.temperatura && (
                                                    <Alert
                                                        tipo_alert='danger'
                                                        msj='El formato de la temperatura tiene que ser 36.5'
                                                    />
                                                )
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Pulso</label>
                                            <input type="text" className="form-control" placeholder="Pulso" 
                                                autoComplete='off'
                                                name='pulso'
                                                pattern='[0-9]+'
                                                value={signos.pulso}
                                                onChange={handleChange}
                                                onBlur={CalcularIMC}
                                                onClick={handleCloseAlert}
                                            />
                                            {
                                                alertas.pulso && (
                                                    <Alert
                                                        tipo_alert='danger'
                                                        msj='El formato del pulso tiene que ser 80'
                                                    />
                                                )
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Presión Arterial</label>
                                            <input type="text" className="form-control" placeholder="Presión Arterial 150/50"
                                                autoComplete='off'
                                                pattern='[0-9]+[/][0-9]+'
                                                name='presion'
                                                value={signos.presion}
                                                onChange={handleChange}
                                                onBlur={CalcularIMC}
                                                onClick={handleCloseAlert}
                                            />
                                            {
                                                alertas.presion && (
                                                    <Alert
                                                        tipo_alert='danger'
                                                        msj='El formato de la presión tiene que ser 150/50'
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label className="form-label">Peso</label>
                                            <input type="text" className="form-control" placeholder="Peso" 
                                                autoComplete='off'
                                                pattern='[0-9]+([.][0-9]+)?'
                                                name='peso'
                                                value={signos.peso}
                                                onChange={handleChange}
                                                onClick={handleCloseAlert}
                                            />
                                            {
                                                alertas.peso && (
                                                    <Alert
                                                        tipo_alert='danger'
                                                        msj='El formato del peso tiene que ser 70'
                                                    />
                                                )
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Altura</label>
                                            <input type="text" className="form-control" placeholder="Talla"
                                                autoComplete='off'
                                                pattern='[0-9]+([.][0-9]+)?'
                                                name='talla'
                                                value={signos.talla}
                                                onChange={handleChange}
                                                onClick={handleCloseAlert}
                                            />
                                            {
                                                alertas.talla && (
                                                    <Alert
                                                        tipo_alert='danger'
                                                        msj='El formato de la talla tiene que ser 1.70'
                                                    />
                                                )
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">IMC</label>
                                            <input type="text" className="form-control" placeholder="IMC" disabled
                                                name='imc'
                                                pattern='[0-9]+([.][0-9]+)?'
                                                value={signos.imc}
                                                onChange={handleChange}
                                                onClick={handleCloseAlert}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label className="form-label">Saturación de Oxígeno</label>
                                            <input type="text" className="form-control" placeholder="Saturación de Oxígeno"
                                                autoComplete='off'
                                                pattern='[0-9]+([.][0-9]+)?'
                                                name='saturacion_oxigeno'
                                                value={signos.saturacion_oxigeno}
                                                onChange={handleChange}
                                                onClick={handleCloseAlert}
                                            />
                                            {
                                                alertas.saturacion_oxigeno && (
                                                    <Alert
                                                        tipo_alert='danger'
                                                        msj='El formato de la saturación de oxígeno tiene que ser 98'
                                                    />
                                                )
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Frecuencia Respiratoria</label>
                                            <input type="text" className="form-control" placeholder="Frecuencia Respiratoria"
                                                autoComplete='off'
                                                pattern='[0-9]+([.][0-9]+)?'
                                                name='frecuencia_respiratoria'
                                                value={signos.frecuencia_respiratoria}
                                                onChange={handleChange}
                                                onClick={handleCloseAlert}
                                            />
                                            {
                                                alertas.frecuencia_respiratoria && (
                                                    <Alert
                                                        tipo_alert='danger'
                                                        msj='El formato de la frecuencia respiratoria tiene que ser 20'
                                                    />
                                                )
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Perímetro Cefálico</label>
                                            <input type="text" className="form-control" placeholder="Perímetro Cefálico"
                                                autoComplete='off'
                                                pattern='[0-9]+([.][0-9]+)?'
                                                name='perimetro_cefalico'
                                                value={signos.perimetro_cefalico}
                                                onChange={handleChange}
                                                onClick={handleCloseAlert}
                                            />
                                            {
                                                alertas.perimetro_cefalico && (
                                                    <Alert
                                                        tipo_alert='danger'
                                                        msj='El formato del perímetro cefálico tiene que ser 50'
                                                    />
                                                )
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button className="btn btn-primary me-md-2" 
                                    onClick={GuardarSignos}
                                    type="button">Guardar</button>
                                    <button className="btn btn-light" type="button"
                                    onClick={handleClose}
                                    >Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* tabla donde va a ir todos los signos vitales del paciente  */}
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Temperatura</th>
                                            <th scope="col">Pulso</th>
                                            <th scope="col">Presión</th>
                                            <th scope="col">Peso</th>
                                            <th scope="col">Talla</th>
                                            <th scope="col">IMC</th>
                                            <th scope="col">S. de Oxígeno</th>
                                            <th scope="col">F. Respiratoria</th>
                                            <th scope="col">P. Cefálico</th>
                                            <th scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       {
                                        listarSignos.map((signo, index) => (
                                            <tr key={index}>
                                                <td>{signo.temperatura}</td>
                                                <td>{signo.pulso}</td>
                                                <td>{signo.presion}</td>
                                                <td>{signo.peso}</td>
                                                <td>{signo.talla}</td>
                                                <td>{signo.imc}</td>
                                                <td>{signo.saturacion_oxigeno}</td>
                                                <td>{signo.frecuencia_respiratoria}</td>
                                                <td>{signo.perimetro_cefalico}</td>
                                                <td>
                                                    <button className="btn btn-primary mx-1"
                                                        onClick={() => EditarSignos(signo)}
                                                    >
                                                        <i class="fas fa-edit"></i>
                                                    </button>
                                                    <button className="btn btn-danger mx-1"
                                                        onClick={()=>EliminarSignos(signo.idsignosvitales)}
                                                    >
                                                        <i class="fas fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                       }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>             
            </div>
            </Modal.Body>
            </Modal>
            {/* Fin Modal Para tomar los signos vitales */}
            </div>
        </div>
    </>
  )
}
