

import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Swal from 'sweetalert2';
import { ControllerEliminarSeguro, ControllerListarSegurosClinica } from '../../../Service/Services.seguros';
import { GetTokenDecoded } from '../../../utils/storeUsuario';
import Alert from '../../Alert/Alert';
import Speener from '../../Speener/Speener';
import CreateSeguro from './CreateSeguro';


export default function Seguros({tabs}) {
    const [show, setShow] = useState(false);
    const [tipoAlert, setTipoAlert] = useState('')
    const [alertMensaje, setAlertMensaje] = useState('')
    const [editSeguro, setEditSeguro] = useState(null)
    const [listSeguro, setListSeguro] = useState([])
    const [loading, setLoading] = useState(false)
    const [ping, setPing] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        (async()=>{
          setLoading(true)
          const response = await ControllerListarSegurosClinica(GetTokenDecoded().idclinica)
          if(response){
            setListSeguro(response)
            setLoading(false)
          }
        })()
    }, [ping, tabs.clinicas])

    const editarSeguro= (segruo) => {
        setEditSeguro(segruo)
        handleShow()
      }
    
    const DeleteSeguro = async(idseguro) => {
        Swal.fire({
            title: 'Estas seguro de eliminar esta clinica?',
            text: "No podras revertir esta accion!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
          }).then(async(result) => {
            if (result.isConfirmed) {
              const response = await ControllerEliminarSeguro(idseguro)
              if(response){
                setPing(!ping)
                setTipoAlert('success')
                setAlertMensaje('Clinica eliminda con exito')
              }else{
                setTipoAlert('danger')
                setAlertMensaje('Ocurrio un error en la operacion por favor intente nuevamente')
              }
              setTimeout(()=>{
                setTipoAlert('')
                setAlertMensaje('')
              },1500)
            }
          })
    }

  return (
    <section>
      <Alert
        tipo_alert={tipoAlert}
        msj={alertMensaje}
      />
            <div className="row">
        <div className="col-12">

          <div className="page-title-box d-sm-flex align-items-center justify-content-between ">
            <button type="button" className="btn btn-light waves-effect waves-light" onClick={handleShow}>
                <i className="bx bx-plus me-1"></i> Crear Seguro</button>
            <Speener loading={loading} />
          </div>


          <table id="tb_clinicas" className="table dt-responsive table-striped nowrap w-100" style={{ fontSize: '14px' }}>
            <thead>
              <tr>
                <th width="15">#</th>
                <th>Nombre</th>
                <th>Ruc</th>
                <th>Razon Social</th>
                <th>Representante</th>
                <th>Direccion</th>
                <th>Telefono</th>
                <th>Correo</th>
                {/* <th>Estado</th> */}
                <th>Medico Auditor</th>
                <th>Fecha Corte</th>
                <th width="50">Opciones</th>
              </tr>
            </thead>
            <tbody>
              {
                listSeguro.map((seguro, index) => (
                  <tr key={seguro.idclinica}>
                    <td align="center">{index + 1}</td>
                    <td>{seguro.nombre}</td>
                    <td>{seguro.ruc}</td>
                    <td>{seguro.razon_social}</td>
                    <td>{seguro.responsable}</td>
                    <td>{seguro.direccion}</td>
                    <td>{seguro.telefono}</td>
                    <td>{seguro.correo_electronico}</td>
                    <td>{seguro.medico_auditor}</td>
                    <td>{seguro.fecha_corte}</td>
                    <td align="left">
                      <div className="btn-group"><button type="button"
                        className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false"><i className="bx bx-dots-horizontal-rounded"></i></button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#;" onClick={()=>editarSeguro(seguro)}>Editar</a>
                          <a className="dropdown-item" href="#;" onClick={()=>DeleteSeguro(seguro.idseguro)}>Eliminar</a>
                          <div className="dropdown-divider"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Seguro
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateSeguro
            setPing={setPing}
            ping={ping}
            handleClose={handleClose}
            show={show}
            editSeguro={editSeguro}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  )
}
