import React from "react";

export default function Footer() {
  return (
    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <div class="text-sm-end d-none d-sm-block">
              <script>document.write(new Date().getFullYear())</script> -SmartMedic.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
