import React from 'react';

const ContextMenu = ({ visible, position, options, onOptionClick }) => {
  if (!visible) return null;

  const style = {
    position: 'absolute',
    top: `${position.y}px`,
    left: `${position.x}px`,
    backgroundColor: 'white',
    border: '1px solid #ccc',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
    zIndex: 1000,
    padding: '8px',
  };

  return (
    <div style={style}>
      {options.map((option, index) => (
        <div
          key={index}
          onClick={() => onOptionClick(option)}
          style={{ padding: '8px', cursor: 'pointer' }}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default ContextMenu;
