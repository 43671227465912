import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { CrearClinica } from "../../Service/Serive.clinica";
import { ObtenerCompanies } from "../../Service/Service.compania";
import { SubirMedia } from "../../Service/ServiceExternal";
import Alert from "../Alert/Alert";
import SelectSearchble from "../SelectSeachble/SelectSearchble";

export default function ClinicaCreate({editClinica, handleClose, setPing, ping, show}) {


  const [alertcompany, setAlertCompany] = useState(false)
  const refDiv = useRef(null)

  const [send, setSend] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [msj, setMsj] = useState('')
  const [listCompany, setListCompany] = useState([])
  const [selectedOption, setSelectedOption] = useState(null);
  const [clinica, setClinica] = useState({
    idclinica: 0,
    idcompany: "",
    codigo: "",
    nombre: "",
    direccion: "",
    telefono: "",
    celular: "",
    provincia: "",
    ciudad: "",
    web: "",
    email: "",
    pais: "",
    tipo: "",
    logo: "",
    selloclinica: "",
    sello: "",
    ruc: "",
    representante: "",
    facebook: "",
    instagram: "",
    is_activo: false,
    encuestas: false,
    use_inventario: false,
    use_contifico: false,
    use_fact_electronica: false,
    establecimiento: "",
    porc_iva: 0.00,
    ptoemi: "",
    nivel_clinica: "",
    gmap: "",
  })


  const {register, setValue, handleSubmit, formState:{errors}} = useForm();
  console.log("errors: ", errors)

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    setAlertCompany(false)
  };

  useEffect(() => {
    if(editClinica){
      setValue('idclinica', editClinica.idclinica)
      setValue('codigo', editClinica.codigo)
      setValue('nombre', editClinica.nombre)
      setValue('direccion', editClinica.direccion)
      setValue('telefono', editClinica.telefono)
      setValue('celular', editClinica.celular)
      setValue('provincia', editClinica.provincia)
      setValue('ciudad', editClinica.ciudad)
      setValue('web', editClinica.web)
      setValue('email', editClinica.email)
      setValue('pais', editClinica.pais)
      setValue('tipo', editClinica.tipo)
      setValue('ruc', editClinica.ruc)
      setValue('horario_inicio', editClinica.horario_inicio)
      setValue('horario_fin', editClinica.horario_fin)
      setValue('representante', editClinica.representante)
      setValue('facebook', editClinica.facebook)
      setValue('instagram', editClinica.instagram)
      setValue('is_activo', editClinica.is_activo)
      setValue('encuestas', editClinica.encuestas)
      setValue('use_inventario', editClinica.use_inventario)
      setValue('use_contifico', editClinica.use_contifico)
      setValue('use_fact_electronica', editClinica.use_fact_electronica)
      setValue('establecimiento', editClinica.establecimiento)
      setValue('porc_iva', editClinica.porc_iva)
      setValue('ptoemi', editClinica.ptoemi)
      setValue('nivel_clinica', editClinica.nivel_clinica)
      setValue('gmap', editClinica.gmap)
      setSelectedOption({
        value: editClinica.idcompany,
        label: editClinica.company
      })
    }
  }, [show])


  useEffect(() => {
    (async()=>{
      const response = await ObtenerCompanies()
      if(response){
          response.map(company => {
            company.value = company.idcompany
            company.label = company.company
          })
          setListCompany(response)
      }
    })()
  }, [])

  const onSubmit = handleSubmit(async(data) => {
    console.log("handleSubmit: ", data)
    if(!selectedOption){
      setAlertCompany(true)
      return;
    }
    if(editClinica){
      data['idclinica'] = editClinica.idclinica
    }
    setSend(true)
    if(data.logo && data.logo.length > 0){
      const url = await SubirMedia(data.logo[0], null, null, 0, 'logo');
      data.logo = url;
    }else if(data.logo && data.logo.length === 0){
      data.logo = ""
    }
    if(data.selloclinica &&  data.selloclinica.length > 0){
      const url = await SubirMedia(data.selloclinica[0], null, null, 0, 'selloclinica');
      data.selloclinica = url;
    }else if(data.selloclinica && data.selloclinica.length === 0){
      data.selloclinica = ""
    }
    if(data.sello && data.sello.length > 0){
      const url = await SubirMedia(data.sello[0], null, null, 0, 'sello');
      data.sello = url;
    }else if (data.sello && data.sello.length === 0){
      data.sello = ""
    }
    data['idcompany'] = selectedOption.value
    const response = await CrearClinica(data)
    if(response){
      setAlertType('success')
    }else{
      setAlertType('error')
    }
    setSend(false)
    refDiv.current.scrollIntoView({behavior: 'smooth'})
    setTimeout(() => {
      setAlertType('')
      setPing(!ping)
      handleClose()
    }, 3000);
  })

  return (
    <section>
        <Alert
          tipo_alert={msj || alertType}
          type={msj || alertType}
        />
      <div className="row">
      <div ref={refDiv} />
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form id="form-clinica-create" onSubmit={onSubmit}>
                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Compania</label>
                  <div className="col-sm-9">
                    <SelectSearchble 
                      listCompany={listCompany} 
                      handleChange={handleChange} 
                      selectedOption={selectedOption} 
                      placeholder="Seleccione Compania"
                    />
                    <Alert
                      tipo_alert="danger"
                      type={alertcompany ? 'select' : ''}
                    />
                  </div>
                </div>


                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Codigo Clinica</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" {...register("codigo", {
                      required: false
                    })} id="codigo" />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Nombre Clinica *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" 
                    {...register("nombre",{
                      required: true,
                      minLength: 3,
                      maxLength: 100,
                      // validate: async (value) => {
                      //   if(editClinica){
                      //     return true
                      //   }
                      //   const existe = await ValidaCampoClinica('nombre', value)
                      //   if(existe){
                      //     return 'El nombre de la clinica ya existe'
                      //   }else{
                      //     return true
                      //   }
                      // },
                      value: clinica.nombre
                    })} id="nombre" />
                    <Alert
                      tipo_alert="danger"
                      msj={errors.nombre?.message}
                      type={errors.nombre?.message || errors.nombre?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Direccion *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" {...register("direccion",{
                      required: true,
                      minLength: 10,
                      maxLength: 100
                    })} id="direccion" />
                    <Alert
                      tipo_alert="danger"
                      type={errors.direccion?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Telefono</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="telefono-clinica" id="telefono-clinica" 
                      {...register("telefono",{
                        required: false,
                        minLength: 10,
                        maxLength: 10,
                        type: 'text'
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.telefono?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Celular *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" {...register("celular",{
                      required: true,
                      minLength: 10,
                      maxLength: 12,
                      type: 'number'
                    })} id="celular" />
                    <Alert
                      tipo_alert="danger"
                      type={errors.celular?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Provincia</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="provincia" id="provincia" 
                      {...register("provincia",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.provincia?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Ciudad</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="ciudad" id="ciudad" 
                      {...register("ciudad",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.ciudad?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Web</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="web" id="web" 
                      {...register("web",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.web?.type}
                    />                    
                  </div>
                </div>


                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Email</label>
                  <div className="col-sm-9">
                    <input type="email" className="form-control" name="email" id="email"
                      {...register("email",{
                        required: true,
                        minLength: 5,
                        maxLength: 100,
                        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.email?.type}
                    />                    
                  </div>
                </div>

                {/* horario de atencion */}
                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Horario de inicio</label>
                  <div className="col-sm-4">
                    <input type="time" className="form-control" name="horario_inicio" id="horario_inicio"
                      {...register("horario_inicio",{
                        required: true,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.horario_inicio?.type}
                    />                    
                  </div>
                  <label for="horizontal-email-input" className="col-sm-1 col-form-label">Fin</label>
                  <div className="col-sm-4">
                    <input type="time" className="form-control" name="horario_fin" id="horario_fin"
                      {...register("horario_fin",{
                        required: true,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.horario_fin?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Pais</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="pais" id="pais" 
                      {...register("pais",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.pais?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Tipo</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="tipo" id="tipo" 
                      {...register("tipo",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.tipo?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Logo</label>
                  <div className="col-sm-9">
                    <input type="file" className="form-control" name="logo" id="logo" 
                      {...register("logo",{
                        required: false,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.logo?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Sello Clinica</label>
                  <div className="col-sm-9">
                    <input type="file" className="form-control" name="selloclinica" id="selloclinica" 
                      {...register("selloclinica",{
                        required: false,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.selloclinica?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Sello</label>
                  <div className="col-sm-9">
                    <input type="file" className="form-control" name="sello" id="sello" 
                      {...register("sello",{
                        required: false,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.sello?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Ruc *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="ruc" id="ruc" 
                      {...register("ruc",{
                        required: true,
                        minLength: 10,
                        maxLength: 13,
                        type: 'text'
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.ruc?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-email-input" className="col-sm-3 col-form-label">Representante *</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="reprensentante" id="representante"
                      {...register("representante",{
                        required: true,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.representante?.type}
                    />                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Facebook</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="facebook" id="facebook" 
                      {...register("facebook",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                    
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Instagram</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="instagram" id="instagram" 
                      {...register("instagram",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Configuracion</label>
                  <div className="col-sm-9 form-check form-switch">
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexActivo" name="is_activo" 
                         {...register("is_activo",{
                          required: false,
                          defaultChecked: false
                        })
                         }
                      />
                      <label className="form-check-label" for="flexActivo">Estado activo</label>
                    </div>
                    
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexEncuastas" name="encuestas" 
                        {...register("encuestas",{
                          required: false,
                          defaultChecked: false
                        })
                        }
                      />
                      <label className="form-check-label" for="flexEncuastas">Encuestas</label>
                    </div>
                    
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexInventario" name="use_inventario" 
                      {...register("use_inventario",{
                        required: false,
                        defaultChecked: false
                      })
                      }
                      />
                      <label className="form-check-label" for="flexInventario">Inventario</label>
                    </div>
                    
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexContifico" name="use_contifico" 
                      {...register("use_contifico",{
                        required: false,
                        defaultChecked: false
                      })
                      }
                       />
                      <label className="form-check-label" for="flexContifico">Contifico</label>
                    </div>

                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexFacturacion" name="use_fact_electronica" 
                      {...register("use_fact_electronica",{
                        required: false,
                        defaultChecked: false
                      })
                      }
                      />
                      <label className="form-check-label" for="flexFacturacion">Facturacion electronica</label>
                    </div>
                  </div>

                </div>


                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Establecimiento</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="establecimiento" id="establecimiento" 
                      {...register("establecimiento",{
                        required: false,
                        minLength: 3,
                        maxLength: 100,
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.establecimiento?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Iva</label>
                  <div className="col-sm-9">
                    <select className="form-select" name="porc_iva" id="iva"
                      {...register("porc_iva",{
                        required: false,
                        defaultChecked: 0.00
                      })}
                    >
                      <option value="0.00">0%</option>
                      <option value="12.00">12%</option>
                      <option value="15.00">15%</option>
                    </select>
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Punto de emision</label>
                  <div className="col-sm-9">
                    <input type="text"
                      minLength={3}
                      maxLength={3}
                     className="form-control" name="ptoemi" id="ptoemi" 
                      {...register("ptoemi",{
                        required: false,
                        minLength: 3,
                        maxLength: 3,
                      })}
                     />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Nivel Clinica</label>
                  <div className="col-sm-9">
                    <select className="form-select" name="nivel_clinica" id="nivel_clinica"
                      {...register("nivel_clinica",{
                        required: false,
                        defaultChecked: 0
                      })}
                    >
                      <option value="0">Nivel 0</option>
                      <option value="1">Nivel 1</option>
                      <option value="2">Nivel 2</option>
                      <option value="3">Nivel 3</option>
                      <option value="4">Nivel 4</option>
                    </select>
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Ubicacion Google map</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" name="gmap" id="gmap" 
                      {...register("gmap",{
                        required: false,
                        minLength: 5,
                        maxLength: 100,
                      })}
                    />
                  </div>
                </div>

                <div className="text-center">
                  <button  onclick="openModalClinica()" className="btn btn-danger m-1">
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 btnSppinAjax" 
                      style={{
                        display: send ? 'inline-block' : 'none' 
                      }}></i>
                    Guardar
                  </button>
                </div>

              </form>


            </div>
          </div>
        </div>
      </div>


    </section>
  );
}
