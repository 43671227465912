import React, { useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import ProcOdontologiaCreate from "./ProcOdontologiaCreate";
import ProcOdontologiaTipoCreate from "./ProcOdontologiaTipoCreate";
const PrimerOffcanvas = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button type="button" className="btn btn-light waves-effect waves-light" onClick={handleShow}>
        <i className="bx bx-plus me-1"></i>Crear Procedimiento
      </button>
      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Procedimiento</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ProcOdontologiaCreate />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};


const SegundoOffcanvas = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button type="button" className="btn btn-light waves-effect waves-light" onClick={handleShow}>
        <i className="bx bx-plus me-1"></i>  Crear Tipo Procedimiento
      </button>
      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Tipo de Procedimiento
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ProcOdontologiaTipoCreate />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default function ProcOdontologia({tabs}) {
  return (
    <section>
      <div class="row">
        <div class="col-12">

          <div class="page-title-box d-sm-flex align-items-center justify-content-between ">
            <div>
              <PrimerOffcanvas />
              <SegundoOffcanvas />
            </div>
            {/* <div class="spinner-border spin_loading text-primary m-1" role="status" >
              <span class="sr-only">Loading...</span>
            </div> */}
          </div>


          <table id="tb_procedimientos_odont" class="table dt-responsive table-striped nowrap w-100" style={{ fontSize: '14px' }}>
            <thead>
              <tr>
                <th width="15">#</th>
                <th width="200">Procedimiento</th>
                <th>Tipo</th>
                <th>Valor</th>
                <th>Estado</th>
                <th width="50">Opciones</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td align="center">1</td>
                <td>Compleja</td>
                <td>Dientes - Restauración</td>
                <td>20.00</td>
                <td>Activo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1349)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1349)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">2</td>
                <td>Compuesta</td>
                <td>Dientes - Piezas</td>
                <td>50.00</td>
                <td>Activo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1353)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1353)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">3</td>
                <td>Compuesta(Una sola cara)</td>
                <td>Dientes - Restauración</td>
                <td>20.00</td>
                <td>Inactivo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1348)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1348)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">4</td>
                <td>Consulta</td>
                <td>Boca - General</td>
                <td>15.00</td>
                <td>Inactivo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1334)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1334)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">5</td>
                <td>Consulta Odontologica General</td>
                <td>Boca - General</td>
                <td>20.00</td>
                <td>Inactivo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1347)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1347)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">6</td>
                <td>Consulta Odontologica General</td>
                <td>Boca - General</td>
                <td>50.00</td>
                <td>Activo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1351)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1351)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">7</td>
                <td>ewrterter</td>
                <td>Boca - General</td>
                <td>20.00</td>
                <td>Activo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1343)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1343)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">8</td>
                <td>Extracción con Odontosección</td>
                <td>Dientes - Exodoncia</td>
                <td>100.00</td>
                <td>Activo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1352)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1352)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">9</td>
                <td>Limpieza (Boca)</td>
                <td>Boca - General</td>
                <td>50.00</td>
                <td>Activo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1346)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1346)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">10</td>
                <td>Prueba 2</td>
                <td>Boca - General</td>
                <td>30.00</td>
                <td>Activo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1335)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1335)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">11</td>
                <td>Prueba 4</td>
                <td>Boca - General</td>
                <td>20.00</td>
                <td>Activo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1340)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1340)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">12</td>
                <td>prueba reunon</td>
                <td>Boca - General</td>
                <td>5.00</td>
                <td>Activo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1354)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1354)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">13</td>
                <td>Simple (Una sola cara)</td>
                <td>Boca - Prueba ODO</td>
                <td>20.00</td>
                <td>Inactivo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1344)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1344)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td align="center">14</td>
                <td>Simple (Una sola cara)</td>
                <td>Dientes - Restauración</td>
                <td>20.00</td>
                <td>Activo</td>
                <td align="center">
                  <div class="btn-group"><button type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                    <div class="dropdown-menu dropdownmenu-secondary">
                      <a class="dropdown-item" href="javascript:;" onclick="loadInfoProcedimiento(1350)">Editar</a>
                      <a class="dropdown-item" href="javascript:;" onclick="eliminarProcedimiento(1350)">Eliminar</a>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>


      {/* <!-- right offcanvas --> */}
      <div id="canvasProcedimientosOdon" class="offcanvas offcanvas-end" tabindex="-1" aria-labelledby="offcanvasRightLabel" style={{ width: '50%' }}>
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">Creación Procedimiento</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          @include('configuracion.proced-create')
        </div>
      </div>


      {/* <!-- right offcanvas --> */}
      <div id="canvasTipoProcedimientosOdon" class="offcanvas offcanvas-end" tabindex="-1" aria-labelledby="offcanvasRightLabel" style={{ width: '50%' }}>
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">Creación Tipo de Procedimiento</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          @include('configuracion.proced-tipo-create')
        </div>
      </div>
    </section>
  );
}
