import React from 'react'

export default function OrtInfo() {
  return (
    <div>
<div class="mt-3">

<div class="card">
    
    <div class="card-body">
        <div class="row mb-4">
            <div class="col-xl-10">
                <h6 class="card-title">PLANES ORTODONCIA</h6>            
            </div>
            <div class="col-xl-2">
                <button class="btn btn-light waves-effect waves-light"
                    onclick="openCanvasInsertOrtodoncia()"> <i class="bx bx-plus me-1"></i> PLAN ORTODONCIA </button>    
            </div>
        </div>

        <table id="tb_planes_ortodoncia" style={{fontSize:"14px"}} class="table table-hover-border  border-in-bottom br-mn table-bordered-content dotted">

            <thead class="upper br-sm table-light">
                <tr class="f12 upper bg-grey-l">                                                                       
                    <th scope="col">Fecha Inicia</th>
                    <th scope="col">Fecha Fin</th>
                    <th scope="col">Tipo Ortodoncia</th>
                    <th scope="col">Tipo Pago</th>
                    <th scope="col">Valor Tratamiento</th>
                    <th scope="col">Valor Entrada</th>
                    <th scope="col">Cuotas</th>
                    <th scope="col">Valor Cuotas</th>
                    <th scope="col">Total</th>
                    <th scope="col">Estado</th>
                    <th scope="col" ></th>                                                
                </tr>
            </thead>

            <tbody class="bg-white" id="bodyanamnesis">

            </tbody>
        </table>
    </div>
</div>
</div>

<hr/>


<div class="card">
<div class="card-header">
    <h4 class="card-title">Cuotas Plan de Tratamiento</h4>        
</div>

<div class="card-body">            
   
        <input type="hidden" id="hidortodoncia" />       
        <input type="hidden" id="hort-idclinica" />                 
        <table id="tb_cuotas_ortodoncia" class="table" style={{fontSize: "14px"}} >
            <thead>
                <tr class="f12 upper bg-grey-l">
                    <th>#</th>
                    <th>Fecha Pago</th>
                    <th>Detalle</th>
                    <th>Doctor</th>
                    <th class="text-center">Fecha Cita</th>
                    <th class="text-center">Hora</th>
                    <th>Valor</th>
                    <th class="text-center">Estado Pago</th>
                    <th class="text-center">Estado Atencion</th>

                    <th></th>
                </tr>
            </thead>
            <tbody></tbody>

        </table>

 
</div>
</div>




<div class="modal fade" id="event-modal-ortodoncia" tabindex="-1">
<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header py-3 px-4 border-bottom-0">
            <h5 class="modal-title" id="modal-title">Ingrar Cita Control Ortodoncia</h5>

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>

        </div>
        <div class="modal-body p-3">
            <form class="needs-validation" name="event-form" id="form-event" novalidate>
                <div class="row">
                                            
                    <div class="col-12">
                        <div class="row mb-3">
                            <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Doctor</label>
                            <div class="col-sm-9">
                                <input type="hidden" id="hidpcuenta_ortondoncia" />
                                <input type="hidden" id="hidcita_ortondoncia" />
                                <select class="form-control" id="ag-doctor">
                                    <option value="" selected disabled>Seleccione un Medico</option>
                                    {/* @foreach ($doctores as $d)
                                        <option value="{{$d->iddoctor}}">{{$d->nombre}}</option>                                            
                                    @endforeach */}
                                </select>

                            </div>
                        </div>
                    </div>


                    <div class="col-12">
                        <div class="row mb-3">
                            <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Fecha</label>
                            <div class="col-sm-9">
                                <input class="form-control" type="date" value="<?= date('Y-m-d')?>" id="ag-fecha-cita"/>
                            </div>
                        </div>
                    </div>


                    <div class="col-12">
                        <div class="row mb-3">
                            <label for="example-time-input" class="col-sm-3 col-form-label">Hora</label>
                            <div class="col-sm-9">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <input class="form-control" type="time" value="13:45" id="ag-hora-inicio"/>
                                    </div>
                                    <div class="col-sm-6">
                                        <input class="form-control" type="time" value="14:00" id="ag-hora-fin"/>
                                    </div>                                                                                                
                                </div>
                                
                            </div>
                        </div>
                    </div>


                    <div class="col-12">
                        <div class="row mb-3">
                            <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Tipo Cita</label>
                            <div class="col-sm-9">
                                <select class="form-control" id="ag-tipo-cosulta">                                        
                                    <option value="Control Ortodoncia">Control Ortodoncia</option>
                                </select>
                            </div>
                        </div>
                    </div>
            
                    
                    <div class="col-12">
                        <div class="row mb-3">
                            <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Motivo</label>
                            <div class="col-sm-9">
                                <textarea class="form-control" rows="2" id="ag-motivo-consulta"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="row mb-3">
                            <label for="horizontal-firstname-input" class="col-sm-3 col-form-label">Estado</label>
                            <div class="col-sm-9">
                                <select class="form-control form-select" name="category" id="ag-estado-cita">                                        
                                    <option value="Sin Confirmar">Sin Confirmar</option>
                                    <option value="Confirmada">Confirmada</option>                                                
                                </select>
                                <div class="invalid-feedback">Please select a valid event category</div>
                            </div>
                        </div>
                    </div>
                    

                </div>
                <div class="row mt-2">
                    <div class="col-4">
                        <button type="button" class="btn btn-danger" id="btn-delete-event" onclick="deleteCita()" style={{display: "none"}}>Eliminar</button>
                    </div>
                    <div class="col-8 text-end">
                        <button type="button" class="btn btn-light me-1" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" class="btn btn-success btnSaveInfo" id="btn-save-event" onclick="saveCitaOrtodoncia()">Guardar</button>                                    
                        <button type="button" class="btn btn-info btnSaveInfo" id="btn-update-event" style={{display: "none"}} onclick="updateCita()">Actualizar</button>
                    </div>
                </div>
            </form>
        </div>
    </div> 
</div> 
</div>

    </div>
  )
}
